import React from 'react';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import AddressComponent from '../../AddressComponent/AddressComponent';

export default function CheckoutAddressComponent(props) {

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
  return (
    <Grid container spacing={3}>
        <Grid item xs={1} lg={1} md={1}>
            <FormControlLabel value={props.element.addressSha} control={<Radio />} />
        </Grid>
        <Grid item xs={11} lg={11} md={11}>
            <AddressComponent editAddress={true} element={props.element} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
        </Grid>
    </Grid>
  );
}