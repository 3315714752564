import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function PhotographersAvatar(props) {
    const classes = useStyles();
    const [profilePicture, setProfilePicture] = React.useState("")
    useEffect(() => {
        getUsersProfilePicture()
      }, []);
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const getUsersProfilePicture = () => {
    let uID = props.el.usersID
    if (!uID)
    {
        uID = props.usersID
    }
    let profilePictures = props.componentMethods.getManagerState().profilePictures
    if (Object.keys(profilePictures).indexOf(uID) === -1)
    {
      profilePictures[uID] = ""
      props.componentMethods.setManagerState({profilePictures: profilePictures}, () => {
        props.componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_SETTINGS + '/' + uID + '/profilePicture', {},'GET',null, (data) => {
          if (data.items.length > 0)
          {
            profilePictures[uID] = data.items[0].usersProfileSettingsValue
            props.componentMethods.setManagerState({profilePictures: profilePictures})
          }
        })
  
      })
    }

  }
  let uID = props.el.usersID
  if (!uID)
  {
      uID = props.usersID
  }
  return (
    <Chip clickable avatar={<Avatar src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.componentMethods.getManagerState().profilePictures[uID]+ '?thumbsWidth=375'} alt={props.el.usersFirstName} />} label={props.el.usersFirstName + ' ' + props.el.usersLastName} color="secondary" style={{margin: 8}} />
  );
}