import React from 'react';
import Button from '@material-ui/core/Button';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import {
  Link
} from "react-router-dom";
export default class UserOrderComponent extends CoreComponent {

  constructor(props) {
      super(props);
      this.loadComponentsProps()
      this.useTheme(Theme)
      this.basicComponentConstants = {
          language: props.getManagerState().language,
          history: props.history,
          user: props.getManagerState().user,
      }
  }

  componentDidMount() {
      super.componentDidMount()
  }
   getRender() {

        let classes = {
              root: {
                position: "fixed",
                left: "8px",
                bottom: "70px",
                maxWidth: 600,
                '& > * + *': {
                  marginTop: "16px",
                },
              },
              link: {
                color:"white"
              }
            }
        this.loadComponentsProps()
        if(this.getLocalStorage('cookiesPolicy')) {
          return null
        } else {
          return <div style={classes.root}>
                  <SnackbarContent
                    message={<React.Fragment>{this.props.getManagerState().language.LANG_COOKIE_SNACKBAR_TEXT}&nbsp;
                    <a href="/politica-cookie" style={classes.link}>
                    {this.props.getManagerState().language.LANG_COOKIE_POLICY}
                </a>
                </React.Fragment>}
                    action={
                      <Button onClick={() => {this.saveLocalStorage(true,"cookiesPolicy"); this.props.setManagerState({cookiePolicy: true})}} color="secondary" variant="contained">
                    {this.props.getManagerState().language.LANG_ACCEPT_COOKIE_POLICY}<br />
                      </Button>}
                  />
                </div>          
        }
    }
}