export default function Style(theme) {
    const style = {
        shoppingCartItemImg: {
            width: '100%',
            height: 'auto'
        },
        rootShoppingCartItem: {
            width: '100%',
            display: 'block'
        },
        divider: {
            margin: theme.spacing(2)
        }
    }
    return style
}