import React from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import IconButton from '@material-ui/core/IconButton';
import MediaQuery from '../../../../../CommonComponents/UseMediaQuery/MediaQueryComponent';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartPaper from './ShoppingCartPaper';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {
      margin: '0 5px'
    }
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -1,
    top: 13,
    border: `1px solid ${theme.palette.secondary.dark}`,
    padding: '0 4px',
  },
}))(Badge);


export default function CreateAccountPopover(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const componentConstants = {
      ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods,
      handleClosePopover: () => {
        handleClose()
      }
  }

  const componentProps = {
      ...props.componentProps
  }
  
  return (<div ref={componentConstants.wrapperPopoverDiv}>
        <StyledBadge badgeContent={componentMethods.getManagerState().shoppingCartTotal} color="primary">
          <MediaQuery 
                    lg={<Button variant="outlined"  onClick={handleClick} className={classes.button} color="secondary" component="span" startIcon={<ShoppingCartIcon />}>
                    {componentConstants.language.LANG_SHOPPING_CART}
                </Button>} 
                    sm={<IconButton aria-label="delete" className={classes.button}  onClick={handleClick}>
                    <ShoppingCartIcon color="secondary" />
                  </IconButton>} 
                    xs={<IconButton aria-label="delete" className={classes.button} onClick={handleClick}>
                    <ShoppingCartIcon color="secondary" />
                  </IconButton>} 
                    md={<IconButton aria-label="delete" className={classes.button}  onClick={handleClick}>
                    <ShoppingCartIcon color="secondary" />
                  </IconButton>} 
                    xl={<Button variant="outlined"   onClick={handleClick} className={classes.button} color="secondary" component="span" startIcon={<ShoppingCartIcon />}>
                    {componentConstants.language.LANG_SHOPPING_CART}
                </Button>}  />
          </StyledBadge>
         <Popover
         id={id}
         open={open}
         anchorEl={anchorEl}
         onClose={handleClose}
         anchorOrigin={{
           vertical: 'bottom',
           horizontal: 'center',
         }}
         transformOrigin={{
           vertical: 'top',
           horizontal: 'center',
         }}
       >
         <ShoppingCartPaper componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} />
       </Popover>
        </div>
  );
}