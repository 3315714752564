import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { blue } from '@material-ui/core/colors';
import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  root: {
  },
  button: {
    margin: 8,
    width: "auto"
  },
  buttonLink: {
    width: "100%"
  },
  link: {
    margin: 8,
    display: "block",
    textDecoration: 'none'
  }
});

function SimpleDialog(props) {

  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const classes = useStyles();
  const { onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="addedToCartDialogTitle" open={props.showAddedToCartDialog}>
      <DialogTitle id="addedToCartDialogTitle">{componentConstants.language.LANG_ITEM_ADDED_TO_CART}</DialogTitle>
      <Link to="/checkout#shopping-cart" className={classes.link}>
        <Button className={classes.buttonLink} color="secondary" variant="contained">
          {componentConstants.language.LANG_SHOPPING_CART}
        </Button>
      </Link>
      <Button className={classes.button} variant="outlined" color="secondary" onClick={handleClose}>
        {componentConstants.language.LANG_CONTINUE_SHOPPING}
      </Button>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default function SimpleDialogDemo(props) {
    const classes = useStyles();
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }
  const handleClose = (value) => {
    componentMethods.setManagerState({showAddedToCartDialog: false})
  };
  
  return (
    <div className={classes.root}>
      <SimpleDialog componentConstants={componentConstants} showAddedToCartDialog={componentMethods.getManagerState().showAddedToCartDialog} componentMethods={componentMethods} onClose={handleClose} />
    </div>
  );
}