export default function Style(theme) {
  const style = {
    root: {
        maxWidth: 350,
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            margin: theme.spacing(1),
            width: theme.spacing(16),
            height: theme.spacing(16),
        },
    },
    formFullWidth: {
        width: '100%',
        height: 'auto'
    },
    title: {
        width: "100%",
        height: "auto",
        textAlign: "center"
    },
    formControl: {
        width: '30%',
    },
    formControlRadioGroup: {
        width: 'auto',
        margin: 0,
        display:'inline-block'
    },
    formRadioGroup: {
        flexDirection: 'row'
    },
    formControlSelect: {
        width: '25%',
        margin: '0 8px',
        height: "auto"
    },
    radioGroupFormControlLabel: {
        width: '30%'
    },
    closeButton: {
        width: "100%",
        height:"auto"
    },
    uploadImagePreview: {
      width: 'auto',
      maxHeight:350,
      margin: "0 auto"
    },
    profilePicture: {
        width: 128,
        height: 128,
        border: '2px solid white'
      },
  }
    return style
}