import React, { useEffect } from 'react';
import Style from '../../ImageComponent/Styles/ImageContainerStyle'
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function AlbumCoverUserImage(props) {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = React.useState(false);

  useEffect(() => {
    getCoverAndProfilePictures()
  }, [])
  
 const getCoverAndProfilePictures = (force = false) => {
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_SETTINGS + '/' + props.albumInfo.albumsUsersID  + '/profilePicture', {},'GET',null, (data) => {
        if (data.items.length > 0)
        {
            setProfilePicture(data.items[0].usersProfileSettingsValue)
        }
      })
  }
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }

  return <div className={classes.coverPhoto} style={{backgroundImage:'url(' + Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.albumInfo.albumsImagesPath+ ')'}}>
        <div className={classes.profilePictureContainer}>
            <div className={classes.avatarContainer}>
                <Avatar src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + profilePicture + '?thumbsWidth=800'} className={classes.profilePicture} />
            </div>
        </div>
        <div className={classes.namePlaceholder}>
            <Typography variant="h3" component="h3">
            &quot;{props.albumInfo.albumsName}&quot;  {props.componentMethods.getManagerState().language.LANG_BY} <a href={'/user/pictures/' + props.albumInfo.albumsUsersID} style={{color:"#009688"}} >{props.albumInfo.usersFirstName} {props.albumInfo.usersLastName}</a>
            <IconButton onClick={() => {
                                        navigator.clipboard.writeText(Settings.LINKS_SITE_URL + 'albums/' + props.componentMethods.getManagerState().albumInfo.albumsID)
                                        props.componentMethods.addError(props.componentMethods.getManagerState().language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                                    }}><ShareIcon /></IconButton>
            </Typography>
        </div>
  </div>
}