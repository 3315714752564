import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import AlbumsContainer from './MaterialComponents/AlbumContainer'
import Grid from '@material-ui/core/Grid';
import SearchYourAlbums from './MaterialComponents/SearchYourAlbums'
import Button from '@material-ui/core/Button';
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import AddAlbum from './MaterialComponents/AddAlbum';

export default class UserAlbums extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        if(this.getLocalStorage('user')) {
            let endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?'
    
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?q=' + this.props.urlParams.query
            }

            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }
            this.getSearchYourAlbums(endpoint)    
        } else {
            this.props.setManagerState({notLoadedAlbums: true})
        }
    }
    loadAlbums() {
        let endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?'
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?q=' + this.props.urlParams.query
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        this.getSearchYourAlbums(endpoint)
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER  + '?'
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?q=' + this.props.urlParams.query
        }
        endpoint += '&page=' + page
        this.getSearchYourAlbums(endpoint)
    }
    getSearchYourAlbums(endpoint) {
        this.props.setManagerState({albumsResultset: [], currentPagination: null, loadingAlbums: true}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({albumsResultset: data.items,notLoadedAlbums: false, currentPagination: data.pagination, loadingAlbums: false})
            })
        })
    }
    refreshSearchYourAlbums() {
        let endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?'
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_CURRENT_USER + '?q=' + this.props.urlParams.query
        }
        
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }

        this.getSearchYourAlbums(endpoint)  
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                hasExactAccess: (accessLevel) => {
                    return that.hasExactAccess(accessLevel)
                },
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            updateAlbum: (requestObject, albumsID) => {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS + '/' +  albumsID,requestObject,'PUT',null, (data) => {
                    that.addErrorMain(that.props.getManagerState().language.LANG_ALBUM_UPDATED_SUCCESSFULLY)
                    that.refreshSearchYourAlbums()
                }, {}, false, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_ALBUM_COULD_NOT_UPDATE)})
            },
            deleteImageAction: (albumsID) => {
                that.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS + '/' + albumsID,{},'DELETE',null, (data) => {
                    that.addErrorMain(that.props.getManagerState().language.LANG_ALBUM_DELETED); 
                    this.refreshSearchYourAlbums()
                }, {}, false, (data) => {that.addErrorMain(that.props.getManagerState().language.LANG_ALBUM_NOT_DELETED)})
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getSearchYourAlbums: (endpoint) => {
                this.getSearchYourAlbums(endpoint)
            },
            refreshSearchYourAlbums: () => {
                this.refreshSearchYourAlbums()
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsConstants('SearchYourAlbums', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchYourAlbums', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },

            getSearchYourAlbums: (endpoint) => {
                this.getSearchYourAlbums(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getRender() {
        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            notLoadedMyPicturesButton: {
                margin: '0 auto'
            }
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('albums')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('albums')
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                        <SearchYourAlbums {...this.buildComponentProps('SearchYourAlbums')} />
                        {
                            this.props.getManagerState().loadingAlbums ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {this.props.getManagerState().notLoadedAlbums ?
                        <Button onClick={() => {this.loadAlbums()}} variant="contained" color="primary" style={classes.notLoadedMyPicturesButton}>
                            {this.props.getManagerState().language.LOAD_IMAGES}
                        </Button>
                            :
                            <React.Fragment>
                                <AddAlbum {...this.buildComponentProps('ImageContainer')} />
                                {
                                    this.props.getManagerState().albumsResultset.map((el, idx) => {
                                        return <AlbumsContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} canEdit={true} />
                                    })
                                }
                            </React.Fragment>
                        }
                        
                    </Grid>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}