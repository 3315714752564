import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchAlbumsContainer(props) {
    const classes = useStyles();
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  
  return (
    <React.Fragment>
            {componentMethods.getManagerState().albumsSearchResultset.length > 0 ?
                <Paper style={{padding: 16, marginTop: 8}} id="albums-section">
                    {componentConstants.language.LANG_ALBUMS}:&nbsp;

                    {
                        componentMethods.getManagerState().albumsSearchResultset.map((el, idx) => {
                            return <a href={'/albums/'+el.albumsID} ><Chip clickable avatar={<Avatar alt={el.albumsName} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.albumsImagesPath+ '?thumbsWidth=375'} />} label={el.albumsName} color="secondary" style={{margin: 8}} /></a>
                        })  
                    }
                    <React.Fragment>
                      {componentMethods.getManagerState().loadingAlbumsSearch ?
                        <LoadingComponent />
                      :
                        null
                      }
                    {
                      !componentMethods.getManagerState().loadingAlbumsSearch  && componentMethods.getManagerState().currentAlbumsSearchPagination.last > componentMethods.getManagerState().currentAlbumsSearchPagination.current ?
                        <Chip clickable onClick={() => {componentMethods.changeAlbumsSearchPage(componentMethods.getManagerState().currentAlbumsSearchPagination.next)}} label={componentConstants.language.LANG_MORE_ALBUMS} color="primary" style={{margin: 8}} />
                      :
                      null
                    }
                    </React.Fragment>
                </Paper>
                :
                null
            }

    </React.Fragment>
  );
}