import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/ShoppingCartPaperStyle';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ShoppingCartItem from '../../ShoppingCartItem/ShoppingCartItem'
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import settings from '../../../Settings/Settings';
import {
    Link
  } from "react-router-dom";
const useStyles = makeStyles((theme) => (Style(theme)));

export default function ShoppingCartPaper(props) {
  const classes = useStyles();
  useEffect(() => {
    props.componentMethods.getShoppingCartItemsAction()
        getShoppingCartVoucher()
    }, []);
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }


    const getShoppingCartVoucher = () => {
        componentMethods.ajax(settings.API_URL  + settings.API_ENDPOINT_SHOPPING_CART_VOUCHER + '/' + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, 
            {}, 
            "GET", null, 
            (data) => {
                if (data.items.length > 0)
                {
                    componentMethods.setManagerState({voucherShoppingCart: data.items[0], loadedVoucherShoppingCart: true})
                }
            })       
    }
    let shoppingCartTotal = 0;
    const shoppingCartItems = componentMethods.getManagerState().shoppingCartItems
    for(let i in shoppingCartItems) {
        shoppingCartTotal += shoppingCartItems[i].shoppingCartItemsQuantity * shoppingCartItems[i].variantsDimensionsPrice
    }

    if (componentMethods.getManagerState().voucherShoppingCart && parseInt(componentMethods.getManagerState().voucherShoppingCart.shoppingCartVouchersDiscount))
    {
        shoppingCartTotal += -parseInt(componentMethods.getManagerState().voucherShoppingCart.shoppingCartVouchersDiscount)
    }
    shoppingCartTotal = (shoppingCartTotal / 100).toFixed(2)
  return (<div className={classes.root}>
      <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_SHOPPING_CART}
        </Typography>
        {
        props.componentMethods.getManagerState().loadingShoppingCartItems ?
            <LoadingComponent />
            : 
            null
        }
    {
        componentMethods.getManagerState().shoppingCartItems.map((el, idx) => {
            return <ShoppingCartItem element={el} componentMethods={componentMethods}  componentConstants={componentConstants} componentProps={componentProps} />
        })
    }
     {
        props.componentMethods.getManagerState().loadingShoppingCartItems ?
            null
            : 
            <Typography component="div" variant="h6" gutterBottom>Total: {shoppingCartTotal} {componentConstants.language.LANG_CURRENCY}</Typography>
        }
        <Link to="/checkout#shopping-cart" aria-label={componentConstants.language.LANG_PROCEED_TO_CHECKOUT} className={classes.proceedToCheckout}>
            <Button variant="contained" color="secondary" fullWidth={true} className={classes.closeButton}>
                {componentConstants.language.LANG_PROCEED_TO_CHECKOUT }
            </Button>
        </Link>
        <Button onClick={componentMethods.handleClosePopover} variant="outlined" color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CLOSE}
        </Button>
  </div>);
}