import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import OrdersInvoiceAddress from './MaterialComponents/OrdersInvoiceAddress'
import OrdersDeliveryAddressComponent from './MaterialComponents/OrdersDeliveryAddress';
import OrdersAddDeliveryAddress from './MaterialComponents/OrdersAddDeliveryAddress'
import OrdersAddInvoiceAddress from './MaterialComponents/OrdersAddInvoiceAddress'
import OrdersStatusesSelect from './MaterialComponents/OrdersStatusesSelect'
import OrderItemsDialog from './MaterialComponents/OrderItemsDialog';
import Button from '@material-ui/core/Button';
import SearchOrders from './MaterialComponents/SearchOrders'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
export default class OrdersComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getOrdersAction()
        this.getOrdersStatusesAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsConstants('Address', {
            ...this.basicComponentConstants
        }).setComponentsMethods('Address', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
               that.saveLocalStorage(storageObject, storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
        }).setComponentsConstants('SelectStatus', {
            ...this.basicComponentConstants
        }).setComponentsMethods('SelectStatus', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
               that.saveLocalStorage(storageObject, storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getOrdersAction: () => {
                that.getOrdersAction()
            }
        }).setComponentsConstants('OrderItemsDialog', {
            ...this.basicComponentConstants
        }).setComponentsMethods('OrderItemsDialog', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
               that.saveLocalStorage(storageObject, storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsConstants('SearchOrders', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchOrders', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchOrders: (endpoint) => {
                this.getSearchOrders(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getOrdersAction: () => {
                that.getOrdersAction()
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getOrdersAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_ORDERS + '?'
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        that.props.setManagerState({orders: [], loadingOrders: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchOrders(endpoint)
            }    
        })
    }

    getSearchOrders(endpoint) {
        this.props.setManagerState({orders: [], currentPagination: null, loadingOrders: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({orders: data.items,currentPagination: data.pagination, loadingOrders: false})
            })
        })
    }

    getOrdersStatusesAction() {
        const that = this;
        that.props.setManagerState({ordersStatuses: [], loadingOrdersStatuses: true}, () => {
            that.ajax(settings.API_URL + settings.API_ENDPOINT_ORDERS_STATUSES,{},'GET','ordersStatuses', (data) => {
                that.props.setManagerState({ordersStatuses: data.items})
            })
        })
    }
    changePage(page) {
        let endpoint = settings.API_ENDPOINT_ORDERS
        endpoint += '?page=' + page
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query
        }
        this.getSearchOrders(endpoint)
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '80px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('orders')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('orders')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    {
                        this.props.getManagerState().viewOrderItem ?
                                <OrderItemsDialog openOrderItemsDialog={this.props.getManagerState().openOrderItemsDialog} {...this.buildComponentProps('OrderItemsDialog')} />
                        :
                        null
                    }
                    <SearchOrders {...this.buildComponentProps('SearchOrders')} />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_EMAIL}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_DELIVERY_ADDRESS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_BILLING_ADDRESS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_STATUS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_TOTAL}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_DELIVERY_METHOD}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_TOTAL_ORDER}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_VOUCHERS_DISCOUNT}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_PAYMENT_METHOD}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_OBSERVATIONS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDERS_ITEMS}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                    {this.props.getManagerState().orders.map((el, idx) => {
                        let vouchersDiscount = 0

                        if (el.ordersVouchersDiscount)
                        {
                            vouchersDiscount = el.ordersVouchersDiscount
                        }
                        return <TableRow key={idx}>
                            <TableCell padding="checkbox">
                                {el.ordersID}
                            </TableCell>
                            <TableCell>
                                {el.ordersEmail}
                            </TableCell>
                            <TableCell>
                                <OrdersAddDeliveryAddress ordersID={el.ordersID} {...this.buildComponentProps('Address')} />
                                <OrdersDeliveryAddressComponent ordersID={el.ordersID} {...this.buildComponentProps('Address')} />
                            </TableCell>
                            <TableCell>
                                <OrdersAddInvoiceAddress ordersID={el.ordersID} {...this.buildComponentProps('Address')} />
                                <OrdersInvoiceAddress ordersID={el.ordersID} {...this.buildComponentProps('Address')} />
                            </TableCell>
                            <TableCell>
                                <OrdersStatusesSelect ordersID={el.ordersID} ordersOrdersStatusesID={el.ordersOrdersStatusesID} {...this.buildComponentProps('SelectStatus')} />
                            </TableCell>
                            <TableCell>{(parseInt(el.ordersTotal) / 100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</TableCell>
                            <TableCell>{this.props.getManagerState().language[el.deliveryMethodsName]} +{(el.ordersDeliveryMethodsPrice/100).toFixed(2)}{this.props.getManagerState().language.LANG_CURRENCY}</TableCell>
                            <TableCell><b>{((parseInt(el.ordersDeliveryMethodsPrice) + parseInt(el.ordersTotal) - parseInt(vouchersDiscount))/100).toFixed(2)}{this.props.getManagerState().language.LANG_CURRENCY}</b></TableCell>
                            <TableCell>{(parseInt(vouchersDiscount) / 100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</TableCell>
                            <TableCell>{el.ordersPaymentMethod}</TableCell>
                            <TableCell>{el.ordersObservations}</TableCell>
                            <TableCell>
                                <Button className={classes.button} color="primary" onClick={(e) => {this.props.setManagerState({openOrderItemsDialog: true, viewOrderItem: el})}} fullWidth component="div" variant="contained">
                                    {this.props.getManagerState().language.LANG_ORDERS_SHOW_ITEMS}
                                </Button>
                            </TableCell>
                        </TableRow>
                    })}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                     {
                        this.props.getManagerState().loadingOrders ?
                        <LoadingComponent />
                        :
                        null
                    }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
