import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SearchAllImages from './MaterialComponents/SearchAllImages'
import Typography from '@material-ui/core/Typography';
import SearchAlbumsContainer from './MaterialComponents/SearchAlbumsContainer'
import FullSearchAlbumsContainer from './MaterialComponents/FullSearchAlbumsContainer'
import Photographers from './MaterialComponents/Photographers'
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { subscribe, unsubscribe } from '../../Events/Events';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoopIcon from '@material-ui/icons/Loop';
import ImagesPromoted from '../ImagesPromoted/ImagesPromoted';
export default class HomeComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()

        let endpoint = Settings.API_ENDPOINT_IMAGES_WITH_VARIANTS
        let endpointAlbumsSearch = Settings.API_ENDPOINT_ALBUMS_SEARCH
        let endpoinPhotographersSearch = Settings.API_ENDPOINT_PHOTOGRAPHERS
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_WITH_VARIANTS + '?limit=24&q=' + this.props.urlParams.query
            endpointAlbumsSearch = Settings.API_ENDPOINT_ALBUMS_SEARCH + '?q=' + this.props.urlParams.query
            endpoinPhotographersSearch = Settings.API_ENDPOINT_PHOTOGRAPHERS + '?q=' + this.props.urlParams.query
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }

        } else {
            if(this.props.urlParams && this.props.urlParams.page)
                endpoint += '?page=' + this.props.urlParams.page

            endpointAlbumsSearch = Settings.API_ENDPOINT_ALBUMS_SEARCH + '?q='
            endpoinPhotographersSearch = Settings.API_ENDPOINT_PHOTOGRAPHERS + '?q='

        }
        this.getSearchImages(endpoint)
        this.getRandomImages()

        this.getMyFavoriteImages()
        this.getSearchAlbumsAction(endpointAlbumsSearch, true)
        this.getPhotographersAction(endpoinPhotographersSearch, true)

        // subscribe("scroll", () => this.automaticallyLoadOlder())
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_UPLOAD_IMAGES + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getSearchImages(endpoint)
    }
    loadFirstPage()
    {
        let endpoint = Settings.API_ENDPOINT_IMAGES_WITH_VARIANTS
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES + '?q=' + this.props.urlParams.query
        }
        this.getSearchImages(endpoint)
    }
    changeAlbumsSearchPage(page) {
        let endpoint = Settings.API_ENDPOINT_ALBUMS_SEARCH + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_SEARCH + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getSearchAlbumsAction(endpoint)
    }

    changePhotographersPage(page) {
        let endpoint = Settings.API_ENDPOINT_PHOTOGRAPHERS + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_PHOTOGRAPHERS + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getPhotographersAction(endpoint)
    }

    getProductAtHalfList()
    {
        if (this.props.getManagerState().imagesResultset.length )
        {
            let halfLength = parseInt(this.props.getManagerState().imagesResultset.length / 2)
            return this.props.getManagerState().imagesResultset[halfLength].imagesID
        } else {
            return 0
        }

    }
    getSearchImages(endpoint) {
        this.setState({ifeelLucky: false})
        this.props.setManagerState({imagesResultset: [], currentPagination: null, loadingImages: true, noMoreImages: false}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({imagesResultset: data.items, currentPagination: data.pagination, loadingImages: false}, () => {
                    var hash = window.location.hash;
                    window.location.hash = '';
                    window.location.hash = hash;

                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: window.location.href
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }

    getMyFavoriteOlderImages() {
        this.props.setManagerState({loadingFavoriteImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_MY_FAVORITE_IMAGES_OLDER + this.getLastMyFavoriteImagesID()

            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let myFavoriteImages = this.props.getManagerState().myFavoriteImages
                myFavoriteImages = myFavoriteImages.concat(data.items)
                this.props.setManagerState({myFavoriteImages: myFavoriteImages, loadingFavoriteImages: false}, () => {})
            })
        })
    }

    getMyFavoriteImages() {
        this.props.setManagerState({loadingFavoriteImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_MY_FAVORITE_IMAGES

            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let myFavoriteImages = this.props.getManagerState().myFavoriteImages
                myFavoriteImages = myFavoriteImages.concat(data.items)
                this.props.setManagerState({myFavoriteImages: myFavoriteImages, loadingFavoriteImages: false}, () => {})
            })
        })
    }

    getLastImagesID()
    {
        let imagesID = 0
        if (this.props.getManagerState().imagesResultset.length)
        {
            imagesID = this.props.getManagerState().imagesResultset[0].imagesID;
        }
        for (let i in this.props.getManagerState().imagesResultset)
        {
            if (parseInt(this.props.getManagerState().imagesResultset[i].imagesID) < parseInt(imagesID))
            imagesID = parseInt(this.props.getManagerState().imagesResultset[i].imagesID)
        }

        return imagesID;
    }
    getLastMyFavoriteImagesID() 
    {
        let imagesID = 0
        if (this.props.getManagerState().myFavoriteImages.length)
        {
            imagesID = this.props.getManagerState().myFavoriteImages[0].imagesID;
        }
        for (let i in this.props.getManagerState().myFavoriteImages)
        {
            if (parseInt(this.props.getManagerState().myFavoriteImages[i].imagesID) < parseInt(imagesID))
            imagesID = parseInt(this.props.getManagerState().myFavoriteImages[i].imagesID)
        }

        return imagesID;
    }

    automaticallyLoadOlder() 
    {
        if (!this.props.getManagerState().loadingImages && !this.props.getManagerState().noMoreImages)
        {
            if (document.getElementById('product-' + this.getProductAtHalfList()) !== null)
            {
                if (document.getElementById('product-' + this.getProductAtHalfList()).scrollTop < window.innerHeight)
                {
                    this.getOlderImages()
                }
    
            }
        }
    }
    getOlderImages() {
        this.setState({ifeelLucky: false})
        this.props.setManagerState({loadingImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER_WITH_VARIANTS + this.getLastImagesID()
            if(this.props.search === true) {
                endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES_OLDER_WITH_VARIANTS  + this.getLastImagesID() + '?limit=24&q=' + this.props.urlParams.query
            }
            let loadedPage = this.props.getManagerState().loadedPage + 1
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().imagesResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)

                let noMoreImages = false
                if (data.items.length === 0)
                {
                    noMoreImages = true
                }

                this.props.setManagerState({imagesResultset: olderImagesResultset, loadingImages: false, loadedPage: loadedPage, noMoreImages:noMoreImages}, () => {
                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: window.location.href + ' Page ' + loadedPage
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }

    getRandomImages(setHash = false) {
        this.setState({ifeelLucky: true})
        let endpoint = Settings.API_ENDPOINT_RANDOM_IMAGES_WITH_VARIANTS
        this.props.setManagerState({randomImagesResultset: [], currentPagination: null, loadingRandomImages: true, oldPagination: this.props.getManagerState().currentPagination}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({randomImagesResultset: data.items, currentPagination: data.pagination, loadingRandomImages: false}, () => {
                    if (setHash)
                    {
                        var hash = 'random-images-header';
                        window.location.hash = '';
                        window.location.hash = hash;    
                    }
                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: 'Random Images'
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }
    getSearchAlbumsAction(endpoint, clear = false) {
        let managerStateObject = {loadingAlbumsSearch: true}
        if (clear === true)
        {
            managerStateObject = {loadingAlbumsSearch: true, albumsSearchResultset: []}
        }
        this.props.setManagerState(managerStateObject, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let existingAlbums = this.props.getManagerState().albumsSearchResultset
                existingAlbums = existingAlbums.concat(data.items)
                this.props.setManagerState({albumsSearchResultset: existingAlbums, currentAlbumsSearchPagination: data.pagination, loadingAlbumsSearch: false})
            })
        })
    }

    getPhotographersAction(endpoint, clear = false) {
        let managerStateObject = {loadingPhotographers: true}
        if (clear === true)
        {
            managerStateObject = {loadingPhotographers: true, photographersResults: []}
        }
        this.props.setManagerState(managerStateObject, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let existingPhotographers = this.props.getManagerState().photographersResults
                existingPhotographers = existingPhotographers.concat(data.items)
                this.props.setManagerState({photographersResults: existingPhotographers, currentPhotographersPagination: data.pagination, loadingPhotographers: false})
            })
        })
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            page: "home",
            urlParams: that.props.urlParams
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                addError: (errorMessage, stateObject) => {
                    that.addErrorMain(errorMessage, stateObject)
                },
                hasExactAccess: (accessLevel) => {
                    return that.hasExactAccess(accessLevel)
                },
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
            urlParams: this.props.urlParams
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
           changeAlbumsSearchPage: (page) => {
            that.changeAlbumsSearchPage(page)
           },
           changePhotographersPage: (page) => {
            that.changePhotographersPage(page)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              }
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            button: {
                marginTop: 128,
                marginBottom: 64
            },
            nextButton: {
                width: '100%',
                minWidth: 250,
                height: 252,
                padding: 8
            },
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('/')
        paginationPattern[0] = '/page/{{page}}'+ paginationPattern[0]
        paginationPattern = paginationPattern.join('/')
        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                        {
                           !this.props.urlParams.page && !this.props.urlParams.query ?
                           <Paper style={{padding: "8px", marginBottom: 8}}>

                           <Grid item xs={12} lg={12} md={12}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} lg={4} md={4}>
                                        <img style={{maxWidth: '100%', margin: "0 auto", display: "block"}} src="https://static.lexie.xyz/6.png" alt={this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_HEADER} />
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={4}>
                                        <Typography component="h3" variant="h3">{this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_HEADER}</Typography>
                                        <Typography component="p" variant="body1">{this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_TEXT_1}</Typography>
                                        <Typography component="p" variant="body1">{this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_TEXT_2}</Typography>
                                        <Typography component="p" variant="body1">{this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_TEXT_3}</Typography>
                                        <Typography component="p" variant="body1">{this.props.getManagerState().language.LANG_LEXIE_HOME_PAGE_TEXT_4}</Typography>
                                    </Grid>
                                    <Grid item xs={12} lg={4} md={4} style={{textAlign: "center"}}>
                                    <Typography component="h3" variant="h3">{this.props.getManagerState().language.LANG_LEXIE_HOW_ITS_MADE}</Typography>
                                        <video controls width="250" poster="https://static.lexie.xyz/how-its-made-poster.png">
                                            <source src="https://static.lexie.xyz/Lexie-Art-How-it-s-made-720-final.mp4" type="video/mp4" />
                                            <source src="https://static.lexie.xyz/Lexie-Art-How-it-s-made-720-final.webm" type="video/webm" />
                                        </video>
                                    </Grid>
                                </Grid>
                           </Grid>
                           </Paper>
                           :
                           null
                        }
                        <ImagesPromoted  {...this.buildComponentProps('AppbarComponent')} />
                        <SearchAllImages {...this.buildComponentProps('SearchAllImages')} />
                        {this.props.urlParams.query ?
                        null
                        :
                        <React.Fragment>
                            <Grid item xs={12} lg={12} md={12} id="random-images-header">
                                <Paper style={{padding: 8, marginTop: 8, marginBottom: 8}}>
                                    <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_RANDOM_IMAGES}</Typography>
                                </Paper>
                            </Grid>
                            {this.props.getManagerState().loadingRandomImages?
                                null
                            :
                                <React.Fragment>
                                    <Grid item xs={1} lg={4} md={1}></Grid>
                                    <Grid item xs={10} lg={4} md={10}>
                                        <Button fullWidth style={classes.sendCommentButton} onClick={() => { this.getRandomImages(true) }} variant="contained" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                    </Grid>
                                    <Grid item xs={1} lg={4} md={1}></Grid>
                                </React.Fragment>
                            }
                        
                            {
                                this.props.getManagerState().loadingRandomImages ?
                                    <Grid item xs={12} lg={12} md={12}>
                                        <LoadingComponent />
                                    </Grid>
                                :
                                null
                            }
                            {
                                this.props.getManagerState().randomImagesResultset.map((el, idx) => {
                                    return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                                })
                            }
                            {this.props.getManagerState().loadingRandomImages?
                                null
                            :
                                <React.Fragment>
                                    <Grid item xs={1} lg={4} md={1}></Grid>
                                    <Grid item xs={10} lg={4} md={10}>
                                        <Button fullWidth style={classes.sendCommentButton} onClick={() => { document.querySelector('#random-images-header').scrollIntoView(); this.getRandomImages(true) }} variant="contained" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                    </Grid>
                                    <Grid item xs={1} lg={4} md={1}></Grid>
                                </React.Fragment>
                            }
                        </React.Fragment>
                        }
                        <Grid item xs={12} lg={12} md={12}>
                            <FullSearchAlbumsContainer {...this.buildComponentProps('ImageContainer')} />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Photographers {...this.buildComponentProps('ImageContainer')} />
                        </Grid>
                        <Grid item xs={12} lg={12} md={12}>
                            <Paper style={{padding: 8, marginTop: 8}}>
                                <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_LATEST_IMAGES}</Typography>
                            </Paper>
                        </Grid>
                        {
                            !this.props.getManagerState().loadingImages && this.props.getManagerState().imagesResultset.length === 0?
                            <Grid item xs={12} lg={12} md={12}>
                                <Typography component="p" variant="subtitle1" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_NO_RESULTS}</Typography>
                            </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().imagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                        {
                            this.props.getManagerState().loadingImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            <React.Fragment>
                                {this.props.getManagerState().noMoreImages ?
                                null
                                :
                                <React.Fragment>

                                    <Grid item xs={12} lg={12} md={12}>
                                        {/* <Button style={classes.button} color="secondary" onClick={() => {this.getOlderImages()}} fullWidth component="div" variant="contained" startIcon={<ExpandMoreIcon />}>
                                            {this.props.getManagerState().language.LANG_LOAD_MORE_IMAGES}
                                        </Button> */}
                                        <Button color="secondary" variant="contained" style={classes.nextButton} onClick={() => {this.getOlderImages()}}>
                                          <Typography variant="subtitle2" component="p">{this.props.getManagerState().language.LANG_LOAD_MORE_IMAGES}</Typography>
                                            <ExpandMoreIcon />
                                        </Button>  
                                    </Grid>
                                </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Grid>
                    {this.props.urlParams.query ?
                    <React.Fragment>
                        <Grid item xs={12} lg={12} md={12} id="random-images-header">
                            <Paper style={{padding: 8, marginTop: 8, marginBottom: 8}}>
                                <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_RANDOM_IMAGES}</Typography>
                            </Paper>
                        </Grid>
                        {this.props.getManagerState().loadingRandomImages?
                            null
                        :
                            <React.Fragment>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                    <Button fullWidth style={classes.sendCommentButton} onClick={() => { this.getRandomImages(true) }} variant="outlined" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                        }
                    
                        {
                            this.props.getManagerState().loadingRandomImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().randomImagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                         {this.props.getManagerState().loadingRandomImages?
                            null
                        :
                            <React.Fragment>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                    <Button fullWidth style={classes.sendCommentButton} onClick={() => { document.querySelector('#random-images-header').scrollIntoView(); this.getRandomImages(true) }} variant="outlined" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                        }
                    </React.Fragment>
                    :
                    null}
                    
                        {this.props.getManagerState().myFavoriteImages.length > 0?
                            <React.Fragment>
                                <Grid item xs={12} lg={12} md={12} id="random-images-header">
                                <Paper style={{padding: 8, marginTop: 8}}>
                                    <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_YOU_FAVORITE_IMAGES}</Typography>
                                </Paper>
                                </Grid>
                                {
                                    this.props.getManagerState().myFavoriteImages.map((el, idx) => {
                                        return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                                    })
                                }
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                {
                                    this.props.getManagerState().loadingFavoriteImages ?
                                        <Grid item xs={12} lg={12} md={12}>
                                            <LoadingComponent />
                                        </Grid>
                                    :
                                    // <Button style={classes.button} color="secondary" onClick={() => {this.getMyFavoriteOlderImages()}} fullWidth component="div" variant="contained" startIcon={<ExpandMoreIcon />}>
                                    //     {this.props.getManagerState().language.LANG_LOAD_MORE_FAVORITE_IMAGES}
                                    // </Button>
                                    <Grid item xs={12} lg={12} md={12}>
                                        <Button color="secondary" variant="contained" style={classes.nextButton} onClick={() => {this.getMyFavoriteOlderImages()}}>
                                            <Typography variant="subtitle2" component="p">{this.props.getManagerState().language.LANG_LOAD_MORE_FAVORITE_IMAGES}</Typography>
                                            <ExpandMoreIcon />
                                        </Button>  
                                    </Grid>
                                }
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                            :
                            null
                        }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}