import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import EmailsItemComponent from './MaterialComponents/EmailsItemComponent';

export default class EmailsComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
        this.state = {
            showAddVoucher: false
        }
    }

    componentDidMount() {
        this.getEmailsAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            refreshSearch: () => {
                that.refreshSearch()
            },
            getVouchersAction: () => {
                that.getVouchersAction()
            },
        }).setComponentsConstants('SearchVouchers', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchVouchers', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchOrders: (endpoint) => {
                this.getSearchOrders(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            searchVouchers: (query) => {
                that.searchVouchers(query)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }

    getEmailsAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_EMAILS
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '?page=' + this.props.urlParams.page
        }
        that.props.setManagerState({emails: [], loadingEmails: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchEmails(endpoint)
            }    
        })
    }

    getSearchEmails(endpoint) {
        this.props.setManagerState({emails: [], currentPagination: null, loadingEmails: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({emails: data.items,currentPagination: data.pagination, loadingEmails: false})
            })
        })
    }

    changePage(page) {
        let endpoint = settings.API_ENDPOINT_EMAILS 

        endpoint += '?page=' + page
        this.getSearchEmails(endpoint)
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '80px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('emails')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('emails')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_TYPE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_ADDED_DATE_TIME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_SENT_DATE_TIME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_TEMPLATE_FILE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_STATUS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_TITLE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_PARAMS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAILS_TO}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {this.props.getManagerState().emails.map((el, idx) => [
                                <EmailsItemComponent element={el} key={idx} {...this.buildComponentProps('AppbarComponent')} />
                            ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <Pagination {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                     {
                        this.props.getManagerState().loadingEmails ?
                        <LoadingComponent />
                        :
                        null
                    }
                {this.props.getManagerState().errors.map((element, index) => { 
                    return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                    })}
                </div>
    }
}
