import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Button from '@material-ui/core/Button';
import SearchVouchers from './MaterialComponents/SearchVouchers'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import VoucherItemComponent from './MaterialComponents/VoucherItemComponent';
import AddVoucherForm from './MaterialComponents/AddVouchersForm'
import AddIcon from '@material-ui/icons/Add';

export default class StocksComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
        this.state = {
            showAddVoucher: false
        }
    }

    componentDidMount() {
        this.getVouchersAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            refreshSearch: () => {
                that.refreshSearch()
            },
            getVouchersAction: () => {
                that.getVouchersAction()
            },
            setShowAddVoucher: (value) => {
                that.setShowAddVoucher(value)
            }
        }).setComponentsConstants('SearchVouchers', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchVouchers', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchOrders: (endpoint) => {
                this.getSearchOrders(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            searchVouchers: (query) => {
                that.searchVouchers(query)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    setShowAddVoucher(value)
    {
        this.setState({showAddVoucher: value})
    }
    getVouchersAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_VOUCHERS + '?'
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        that.props.setManagerState({vouchers: [], loadingVouchers: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchVouchers(endpoint)
            }    
        })
    }

    getSearchVouchers(endpoint) {
        this.props.setManagerState({vouchers: [], currentPagination: null, loadingVouchers: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({vouchers: data.items,currentPagination: data.pagination, loadingVouchers: false})
            })
        })
    }

    searchVouchers(query) {
        const that = this;
        let endpoint = settings.API_ENDPOINT_VOUCHERS
            endpoint += '?q=' + query

        this.getSearchVouchers(endpoint)
    }
    refreshSearch () {
        this.getVouchersAction()
    }
    changePage(page) {
        let endpoint = settings.API_ENDPOINT_VOUCHERS + '?'
        if(this.props.search === true) {
            endpoint = settings.API_ENDPOINT_VOUCHERS + '?q=' + this.props.urlParams.query
        }
        endpoint += '&page=' + page
        this.getSearchVouchers(endpoint)
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '80px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('vouchers')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('vouchers')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <SearchVouchers {...this.buildComponentProps('SearchVouchers')} />
                    {
                        this.state.showAddVoucher ?
                            <AddVoucherForm {...this.buildComponentProps("AppbarComponent")} />
                        :
                            <Button fullWidth={true} variant="contained" color="primary" onClick={(e)=>{this.setShowAddVoucher(true)}} startIcon={<AddIcon />}>
                                {this.props.getManagerState().language.LANG_ADD_VOUCHER}
                            </Button>
                    }
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_CODE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_VALUE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_TYPE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_START_DATE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_END_DATE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_QUANTITY}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_VOUCHERS_USAGES}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ACTIONS}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {this.props.getManagerState().vouchers.map((el, idx) => [
                                <VoucherItemComponent element={el} key={idx} {...this.buildComponentProps('AppbarComponent')} />
                            ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                     {
                        this.props.getManagerState().loadingOrders ?
                        <LoadingComponent />
                        :
                        null
                    }
                {this.props.getManagerState().errors.map((element, index) => { 
                    return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                    })}
                </div>
    }
}
