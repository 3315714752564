import React from 'react';
import { Typography } from '@material-ui/core';

export default function HowToPhotographers(props) {
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Încărcare rapida de imagine
            </Typography>

            <Typography variant="body1">
              Pentru a încărca rapid o imagine accesați optiunea &quot;Încarcă imagine&quot; din meniul superior.
              Dupa accesarea optiunii de încarcă imagine veți vedea un pop-up unde veți avea butonul &quot;Alege imagine&quot;. 
              Apasand butonul alege imagine veți putea selecta imaginea pe care doriți sa o încărcați.
              Dupa alegerea imaginii completați câmpurile &quot;Locația imaginii&quot; cu locația unde a fost facută imaginea si câmpul &quot;Descrierea imaginii&quot; cu titlul imaginii sau o scurtă descriere a imaginii pentru a putea fi găsită când caută utilizatorii imagini pe platformă.
              Pentru a încărca imaginea apăsați butonul &quot;Încarcă&quot;
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/1.jpeg" />
              <Typography variant="h4">
              Încărcare imagini multiple
              </Typography>
                Pentru a încărca mai multe imagini deodată accesați opțiunea &quot;Imaginile tale&quot; din meniul superior.
                În pagina imaginile tale veți vedea o iconiță cu un aparat foto și un semn de plus. 
                Apăsați această iconită și selectați una sau mai multe imagini deodată.
                După ce veți selecta una sau mai multe imagini în pagină vor apărea imaginile iar pentru fiecare imagine veți avea câmpurile de &quot;Locație imagine&quot; și &quot;Descriere imagine&quot;.
                Completați câmpurile aferente fiecărei imagini iar sub setul nou de imagini veți vedea un buton denumit &quot;Încarcă imaginnile&quot;.
                Apăsați butonul încarcă imaginile și așteptați ca imaginile să se încarce, veți vedea un contor care vă spune în timp real câte imagini s-au încărcat.
                Nu reîncărcați sau închideți pagina pana cand nu dispare contorul de imagini încărcate.
                <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/2.jpeg" />
                <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/3.jpeg" />
                <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/4.jpeg" />
            </Typography>
            <Typography variant="h4">
              Creare și administrare albume
            </Typography>
            <Typography variant="body1">
              Pentru a crea albume apăsați butonul de meniu cu 3 linii orizontale din partea inferioară a paginii și din meniul care apare in partea stângă accesați opțiunea &quot;Albumele tale&quot;.
              Pentru a adăuga un album nou aveți un câmp intitulat &quot;Denumire album&quot;.
              Completați câmpul cu denumirea albumului nou iar în partea dreaptă a câmpului denumire album apăsați butonul cu semnul plus.
              După reîncărcarea paginii și adăugarea albumului veți vedea lista de albume.
              Pentru a modifica denumirea unui album pentru fiecare album in partea dreaptă inferioară a albumului aveți o iconiță cu un creion, apăsați această iconită pentru a deschide meniul de editare a albumului.
              Modificați denumirea albumului și apăsați butonul salvează.
              Pentru a șterge un album din același meniu apăsați butonul șterge și confirmați ștergerea albumui.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/5.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/6.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/7.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/22.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/21.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/23.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/24.jpeg" />
            </Typography>
            <Typography variant="h4">
              Monetizare
            </Typography>
            <Typography variant="body1">
              Pentru a vedea partea de monetizare și vânzările care s-au efectuat pe platformă apăsați butonul de meniu cu 3 linii orizontale din partea inferioară a paginii.
              Acest buton va deschide o fereastră în partea stângă, de unde accesați opțiunea &quot;Monetizare&quot;.
              În pagina nou încărcată de monetizare veți vedea vânzările care s-au făcut în luna curentă și plățile istorice (neincluzând luna curentă) care trebuiesc efectuate.
              Plățile reprezintă suma pe care trebuie să o primiți după vânzarea tablourilor.
              Tineți cont de faptul că după vânzarea unui tablou trebuie să treacă termenul de 30 de zile de retur pentru ca vânzarea să fie calculată iar din acest motiv, în cazul în care se face un retur al unui tablou, exista posibilitatea ca suma pe care o vedeți în luna curentă să nu corespundă la finalul lunii când se calculează suma totală de plată.
              În consecință un tablou va fi considerat vândut după ce dreptul de 30 de zile de retur a expirat și tabloul nu a fost trimis în retur.
              Secțiunea de &quot;Total luna aceasta&quot; este strict informativă în timp real iar plățile sunt calculate la fiecare început de lună.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/5.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/9.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/10.jpeg" />
            </Typography>
            <Typography variant="h4">
              Modificare și actualizare imagini uploadate
            </Typography>
            <Typography variant="body1">
              Pentru a modifica detaliile unei imagini accesați opțiunea &quot;Imaginile tale&quot; din meniul superior și apăsând butonul cu iconița creion veți vedea meniul de editare al unei imagini.
              Modificați denumirea și/sau locația imaginii și apăsați butonul &quot;Salvează&quot; pentru imaginea repsectivă. 
              Deasemenea puteți șterge o imagine apăsând butonul &quot;Șterge&quot; și confirmând ștergerea imaginii.
              Pentru fiecare imagine in parte aveți o bifă denumită &quot;Imagine publicată&quot; care vă permite să ascundeți imaginea din paginile platformei în caz că debifați această opțiune iar utilizatorii nu vor putea să vadă în rezultatele căutărilor această imagine decât după ce o veți publica bifând această opțiune și apăsând butonul &quot;Salvează&quot;
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/11.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/12.jpeg" />
            </Typography>
            <Typography variant="h4">
              Adăugare, ștergere și selectare imagini implicite în albume
            </Typography>
            <Typography variant="body1">
            Pentru a adăuga o imagine într-un album accesați opțiunea &quot;Imaginile tale&quot; din meniul superior și apăsând butonul cu iconița creion veți vedea meniul de editare al unei imagini.
            Apăsând butonul &quot;Adaugă la album&quot; veți deschide meniul de adăugare la album.
            Din opțiunea &quot;Denumire album&quot; selectați albumul în care doriți să adăugați imaginea iar după ce ați selectat albumul apăsați din nou butonul &quot;Adaugă la album +&quot;.
            <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/13.jpeg" />
            <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/14.jpeg" />
            După ce veți adăuga imaginea la un album veți vedea că în partea de deasupra meniului de selectare album va apărea denumirea albumului în care ați adăugat imaginea.
            Pentru a selecta ca și imagine implicită a unui album veți vedea că în meniul de editare a fotografiei pentru fiecare album aveți o iconiță cu o bifă, apăsați această iconiță și imaginea va deveni imaginea implicită și imaginea de copertă a albumului.
            <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/15.jpeg" />
            Pentru a șterge imaginea dintr-un album din același meniu de editare a imaginii veți vedea că în dreptul fiecărei denumiri de album aveți o iconiță cu un &quot;x&quot;, apăsați această iconiță și imaginea va fi automat ștearsă din album.
            </Typography>
            <Typography variant="h4">
              Fotografie de profil și fotografie de copertă profil
            </Typography>
            <Typography variant="body1">
              Pentru a selecta o fotografie de profil aveți 2 opțiuni.
              Din opțiunea de &quot;Imaginile tale&quot; veți vedea pentru fiecare fotografie un buton denumit &quot;Fotografie de profil&quot;.
              Apăsați butonul fotografie de profil și automat fotografia selectată va fi selectată ca și fotografia dumneavoastră de profil.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/16.jpeg" />
              Dacă nu doriți o imagine pentru fotografia de profil din cele pe care le-ați uploadat ca și tablouri puteți accesa opțiunea &quot;Contul tău&quot; iar în dreptul iconiței sau imaginii de profil veți vedea un buton cu o iconiță de creion.
              Apăsând acest buton veți deschide meniul de adăugare imagine de profil. Apăsați butonul &quot;Adaugă imagine&quot;, selectați imaginea dorită iar după selectarea imaginii apăsați butonul &quot;Încarcă&quot;.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/18.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/19.jpeg" />
              Pentru a selecta o fotografie de copertă profil din opțiunea de &quot;Imaginile tale &quot; veți vedea pentru fiecare fotografie un buton denumit &quot;Fotografie de copertă&quot;.
              Pentru imaginea dorită ca și fotografie de copertă apăsați butonul imagine de copertă și automat imaginea va fi selectată ca și imagine de copertă pentru profilul dumneavoastră.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/17.jpeg" />
            </Typography>
            <Typography variant="h4">
              Promovare imagini
            </Typography>
            <Typography variant="body1">
              Pentru a promova o imagine favorită în secțiunea &quot;Imagini promovate&quot; mergeți în opțiunea &quot;Imaginile tale&quot; din meniul superior iar pentru fiecare imagine veți regăsi butonul &quot;Promovează imagine&quot;.
              Apăsând butonul promovează imagine această imagine va fi automat adăugată în lista de imagini promovate.
              Pentru a șterge o imagine din lista de imagini promovate mergeți în pagina principală a platformei noastre iar din lista de imagini promovate, pentru fiecare imagine care vă aparține veți vedea o iconiță cu un coș de gunoi și un  &quot;x&quot;, apăsați această iconiță și imaginea va și ștearsă din lista de imagini promovate.
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/20.jpeg" />
              <img style={{width:'100%'}}src="https://static.lexie.xyz/how-to/25.jpeg" />
            </Typography>
      </React.Fragment>);
}