import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import ShoppingCartItem from '../ShoppingCartItem/ShoppingCartItem';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import Typography from '@material-ui/core/Typography';
import AddressComponent from '../AddressComponent/AddressComponent';
import RadioGroup from '@material-ui/core/RadioGroup';
import CheckoutAddressComponent from './MaterialComponents/CheckoutAddressComponent';
import PaymentMetodsComponent from './MaterialComponents/PaymentMethodsComponent'
import DeliveryMethodsComponent from './MaterialComponents/DeliveryMethodsComponent'
import CheckoutObservationsComponent from './MaterialComponents/CheckoutObservationsComponent';
import OrdersEmailComponent from './MaterialComponents/OrdersEmailComponent';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Paper from '@material-ui/core/Paper';
import VoucherComponent from './MaterialComponents/VoucherComponent'

import {
    Link
  } from "react-router-dom";
export default class CheckoutComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
       this.refreshCheckout()
       this.getDeliveryMethods()
       this.props.setManagerState({showAddedToCartDialog: false})
        super.componentDidMount()
        var hash = window.location.hash;
        window.location.hash = '';
        window.location.hash = hash;
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('OrderObservations', {
            ...this.basicComponentConstants
         }).setComponentsMethods('OrderObservations', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
        }).setComponentsConstants('OrdersEmail', {
            ...this.basicComponentConstants
         }).setComponentsMethods('OrdersEmail', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
        }).setComponentsConstants('DeliveryMethods', {
            ...this.basicComponentConstants
         }).setComponentsMethods('DeliveryMethods', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
        }).setComponentsConstants('PaymentMethods', {
            ...this.basicComponentConstants
         }).setComponentsMethods('PaymentMethods', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
        }).setComponentsConstants('Address', {
            ...this.basicComponentConstants
         }).setComponentsMethods('Address', {
             setManagerState: (object, callback) => {
                 that.props.setManagerState(object, callback)
             },
             addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
             getSearchImages: (endpoint) => {
                 this.getSearchImages(endpoint)
             },
             refreshMainComponent: () => {
                 that.refresh()
             },
             getManagerState: () => {
                 return that.props.getManagerState()
             },
             changeRoute: (route) => {
                 that.props.changeRoute(route)
             },
             getLocalStorage: (storageName) => {
                 return that.getLocalStorage(storageName)
             },
             getUserAddresses: () => {
                that.getUserAddresses()
             },
             getShoppingCartItemsAction() {
                 that.getShoppingCartItemsAction()
             },
             saveLocalStorage: (storageObject, storageName) => {
                that.saveLocalStorage(storageObject, storageName)
             },
             getAnonymousAddresses: () => {
                that.getAnonymousAddresses()
             },
             ajax: (url, 
                 dataObject = {}, 
                 method, stateValue = '', 
                 callback = () => {}, 
                 headers = {}, 
                 setState = true, 
                 catchCallback = () => {}) => {
                     that.ajax (url, 
                         dataObject, 
                         method, stateValue, 
                         callback, 
                         headers, 
                         setState, 
                         catchCallback);
                 },
         }).setComponentsConstants('ShoppingCartItem', {
           ...this.basicComponentConstants
        }).setComponentsMethods('ShoppingCartItem', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getShoppingCartItemsAction() {
                that.getShoppingCartItemsAction()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
        }).setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getShoppingCartItemsAction: () => {
                that.getShoppingCartItemsAction()
            },
            refreshCheckout: () => {
                that.refreshCheckout()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
        })
    }
    refreshCheckout() {
         if(this.getLocalStorage('user')) {
            this.props.setManagerState({ordersEmail: this.getLocalStorage('user').usersEmail})
            this.getUserAddresses()
        } else {
            this.getAnonymousAddresses()
        }
        this.getShoppingCartItemsAction()
        this.getShoppingCartTotal()
        this.props.setManagerState({deliveryAddress: '', billingAddress: '',
            paymentMethod: '',
            deliveryMethod: '',
            orderObservations: ''
        })
    }
    getDeliveryMethods() {
        const that = this
        this.ajax(settings.API_URL + settings.API_ENDPOINT_DELIVERY_METHODS, {},'GET','deliveryMethods', (data) => {
            that.props.setManagerState({deliveryMethods: data.items})
        })
    }

    getShoppingCartTotal() {
        const that = this

        this.ajax(settings.API_URL + settings.API_ENDPOINT_SHOPPINGCART_TOTAL + this.getLocalStorage('shoppingCart').shoppingCartSha, {},'GET','addresses', (data) => {
            that.props.setManagerState({shoppingCartTotal: data.items.total})
        })
    }
    handleChangeDeliveryAddress(e) {
        this.props.setManagerState({deliveryAddress: e.target.value})
    }

    handleChangeBillingAddress(e) {
        this.props.setManagerState({billingAddress: e.target.value})
    }
    getUserAddresses() {
        const that = this;
        that.props.setManagerState({addresses: {
            items: []
        }, loadingAddresses: true}, () => {
            if(that.getLocalStorage('user')) {
                that.ajax(settings.API_URL + settings.API_ENDPOINT_ADDRESS,{},'GET','addresses', (data) => {that.setUserAddresses(data)})
            }    
        })
    }
    appendToAnonymousAddresses(item) {
        let addresses = this.props.getManagerState().addresses;
        addresses.items.push(item)
        this.props.setManagerState({addresses: addresses})
        if(this.props.getManagerState().addresses.items.length === this.getLocalStorage('addresses').length) {
            this.props.setManagerState({loadingAddresses: false})
        }
    }
    getAnonymousAddresses() {
        const that = this;
        if(!this.getLocalStorage('addresses')) return;
        that.props.setManagerState({
            addresses: {
                items: []
            },
            loadingAddresses: true
        }, 
        () => {
            if(that.getLocalStorage('addresses')) {
                const addresses = that.getLocalStorage('addresses')
                for(let i in addresses) {
                    that.ajax(settings.API_URL + settings.API_ENDPOINT_ADDRESS + '/' + addresses[i],{},'GET','addresses', (data) => {
                        that.appendToAnonymousAddresses(data.items[0])
                    })
                }
            }
        })
    }
    getShoppingCartItemsAction() {
        const that = this
        let url = settings.API_URL + settings.API_ENDPOINT_SHOPPINGCARTITEMS + that.getLocalStorage('shoppingCart').shoppingCartSha

        that.props.setManagerState({loadingShoppingCartItems: true, shoppingCartItems: []}, () => {
            that.ajax(url, 
                {}, 
                "GET", 'shoppingCartItems', 
                (data) => {
                    that.props.setManagerState({shoppingCartItems: data.items, loadingShoppingCartItems: false})
                    var hash = window.location.hash;
                    window.location.hash = '';
                    window.location.hash = hash;
                })
        })
    }
    setAddressCounties (data) {
        this.props.setManagerState({counties: data})
    }
    setUserAddresses (data) {
        this.props.setManagerState({addresses: data, loadingAddresses: false})
    }

    postShoppingCartAction() {
        const that = this

        this.ajax(settings.API_URL + settings.API_ENDPOINT_SHOPPINGCART,{},'POST','shoppingCart', (data) => {
            this.saveLocalStorage(data.items[0], 'shoppingCart')
            that.refreshCheckout()
        })
    }
    saveOrderAction () {
        const that = this
        let errors = []
        if(!this.props.getManagerState().shoppingCartItems.length) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_SHOPPINGCART_ITEMS)
        }
        if(!this.props.getManagerState().deliveryAddress) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_DELIVERY_ADDRESS)
        }

        if(!this.props.getManagerState().billingAddress) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_BILLING_ADDRESS)
        }

        if(!this.props.getManagerState().paymentMethod) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_PAYMENT_METHOD)
        }


        if(!this.props.getManagerState().deliveryMethod) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_DELIVERY_METHOD)
        }

        if(!this.props.getManagerState().ordersEmail) {
            errors.push(this.props.getManagerState().language.LANG_ERROR_EMPTY_ORDERS_EMAIL)
        }
        if ( errors.length) {
            this.addErrorMain(errors.join(", "));
            return
        }
        let method = "POST"
        let url = settings.API_URL + settings.API_ENDPOINT_ORDER + this.getLocalStorage('shoppingCart').shoppingCartSha + '/' + this.props.getManagerState().deliveryAddress + '/' + this.props.getManagerState().billingAddress

        that.props.setManagerState({loadingOrder: true}, () => {
            
            let requestObject =  {
                ordersPaymentMethod: that.props.getManagerState().paymentMethod,
                ordersDeliveryMethodsID: that.props.getManagerState().deliveryMethod,
                ordersEmail: that.props.getManagerState().ordersEmail
            }
            if (this.getLocalStorage('affiliate'))
            {
                requestObject.affiliatesString = this.getLocalStorage('affiliate').affiliate
            }
            if (that.props.getManagerState().orderObservations) {
                requestObject.ordersObservations = that.props.getManagerState().orderObservations
            }

            that.ajax(url, 
               requestObject, 
               method, 'orders', 
                (data) => {
                    that.postShoppingCartAction()
                    that.addErrorMain(that.props.getManagerState().language.LANG_SUCCESSFULY_SENT_ORDER);
                    that.setState({orderCompleted: true, order: data.items[0]})
                    that.props.setManagerState({voucherShoppingCart:null})
               }, {}, false, (data) => {
                    that.addErrorMain(that.props.getManagerState().language.LANG_FAILED_SEND_ORDER);
                    if (data.exception)
                        that.addErrorMain(that.props.getManagerState().language[data.exception]);
               })
        })
      }
        getDeliveryMethodPrice() {
            let price = 0
            const deliveryMethods = this.props.getManagerState().deliveryMethods 
            if (this.props.getManagerState().deliveryMethod) {
                for (let i in deliveryMethods) {
                    if(this.props.getManagerState().deliveryMethod === deliveryMethods[i].deliveryMethodsID) {
                        return parseInt(deliveryMethods[i].deliveryMethodsPrice)
                    }
                }
            }
            return price
        }

    getRender() {

        let classes = {
            root: {
              display: 'block',
              maxWidth: '750px',
              margin: '70px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            formControl: {
              width: '100%',
              display: "block"
            },
            formContainer: {
              margin: '0 auto'
            },
            notLoadedMyPicturesButton: {
                margin: '0 auto'
            },
            link: {
                textDecoration: 'none'
            }
          };
          let shoppingCartTotal = 0;
          const shoppingCartItems = this.props.getManagerState().shoppingCartItems
          for(let i in shoppingCartItems) {
              shoppingCartTotal += shoppingCartItems[i].shoppingCartItemsQuantity * shoppingCartItems[i].variantsDimensionsPrice
          }
          let allTotal = shoppingCartTotal + this.getDeliveryMethodPrice()
          if (this.props.getManagerState().voucherShoppingCart && parseInt(this.props.getManagerState().voucherShoppingCart.shoppingCartVouchersDiscount))
          {
            allTotal += -parseInt(this.props.getManagerState().voucherShoppingCart.shoppingCartVouchersDiscount)
          }
          if (allTotal < 0)
          {
            allTotal = 0
          }
        this.loadComponentsProps()
        if(this.state.orderCompleted) {
            return <div style={classes.root} id="rootComponent">
                        <Paper style={classes.root}>
                            <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                            <Typography component="div" variant="h4">{this.props.getManagerState().language.LANG_ORDER_COMPLETED_TITLE}</Typography>
                            <Typography component="div" variant="subtitle1">{this.props.getManagerState().language.LANG_ORDERS_ID}: <Link style={classes.link} to={"/user/order/"+ this.state.order.ordersID + "/"  +this.state.order.ordersSha}>{this.state.order.ordersID}</Link></Typography>
                            <Typography component="div" variant="subtitle1">{this.props.getManagerState().language.LANG_ORDER_COMPLETED_TEXT}{this.state.order.ordersEmail}{this.props.getManagerState().language.LANG_ORDER_COMPLETED_TEXT_1}</Typography>
                            <Link style={classes.link} to={"/user/order/"+ this.state.order.ordersID + "/"  + this.state.order.ordersSha}><Button color="secondary" variant="contained">{this.props.getManagerState().language.LANG_ORDER_DETAILS}</Button></Link>
                        </Paper>
                    </div>
        }
        return <div style={classes.root} id="shopping-cart">
                    <Paper style={classes.root}>
                    <AppbarComponent websiteVisitors={true} {...this.buildComponentProps('AppbarComponent')} />
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_SHOPPING_CART}
                    </Typography>
                    {
                        this.props.getManagerState().loadingShoppingCartItems ?
                            <LoadingComponent />
                            : 
                            null
                    }
                    {
                        this.props.getManagerState().shoppingCartItems.map((el, idx) => {
                            return <ShoppingCartItem key={idx} checkout={true} element={el} {...this.buildComponentProps('ShoppingCartItem')} />
                        })
                    }
                    {
                    this.props.getManagerState().loadingShoppingCartItems ?
                        null
                        : 
                        <Typography component="div" variant="h6" gutterBottom>Total: {(shoppingCartTotal/100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</Typography>
                    }
                    <Typography variant="h4" component="h4" className={classes.title} id="voucher">
                        {this.props.getManagerState().language.LANG_VOUCHER}
                    </Typography>
                    <VoucherComponent vouchersID={this.props.getManagerState()} {...this.buildComponentProps("AppbarComponent")} />
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_EMAIL}
                    </Typography>
                    <OrdersEmailComponent {...this.buildComponentProps('OrdersEmail')} />
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_ADDRESES}
                    </Typography>
                    <AddressComponent addAddress={true} element={{addressCountiesID: ''}} {...this.buildComponentProps('Address')} />
                    <Typography variant="h5" component="h5" className={classes.title}>
                        {this.props.getManagerState().language.LANG_DELIVERY_ADDRESS}
                    </Typography>
                     {
                        this.props.getManagerState().loadingAddresses ?
                            <LoadingComponent />
                            : 
                            null
                    }
                    {
                        this.props.getManagerState().addresses.items.length ?
                                <FormControl component="fieldset" style={classes.formControl}>
                                    <RadioGroup aria-label="deliveryAddress" name="deliveryAddress" value={this.props.getManagerState().deliveryAddress} onChange={(e) => {this.handleChangeDeliveryAddress(e) }}>
                                        {
                                            this.props.getManagerState().addresses.items.map((el, idx) => {
                                                return <CheckoutAddressComponent key={idx} element={el} {...this.buildComponentProps('Address')} />
                                            })
                                        }
                                    </RadioGroup>
                                </FormControl>
                        :
                        null
                        }
                    <Typography variant="h5" component="h5" className={classes.title}>
                        {this.props.getManagerState().language.LANG_BILLING_ADDRESS}
                    </Typography>
                    {
                        this.props.getManagerState().loadingAddresses ?
                            <LoadingComponent />
                            : 
                            null
                    }
                    {
                        this.props.getManagerState().addresses.items.length ?
                            <FormControl component="fieldset" style={classes.formControl}>
                                <RadioGroup aria-label="billingAddress" name="billingAddress" value={this.props.getManagerState().billingAddress} onChange={(e) => {this.handleChangeBillingAddress(e) }}>
                                    {
                                        this.props.getManagerState().addresses.items.map((el, idx) => {
                                            return <CheckoutAddressComponent key={idx} element={el} {...this.buildComponentProps('Address')} />
                                        })
                                    }
                                </RadioGroup>
                            </FormControl>
                    :
                    null
                    }
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_PAYMENT_METHODS}
                    </Typography>
                    <PaymentMetodsComponent {...this.buildComponentProps('PaymentMethods')} />
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_DELIVERY_METHOD}
                    </Typography>
                    <DeliveryMethodsComponent {...this.buildComponentProps('DeliveryMethods')} />
                    <Typography variant="h4" component="h4" className={classes.title}>
                        {this.props.getManagerState().language.LANG_ORDER_OBSERVATIONS}
                    </Typography>
                    <CheckoutObservationsComponent {...this.buildComponentProps('OrderObservations')} />
                    <Typography variant="h5" component="h5" className={classes.title}>
                        {this.props.getManagerState().language.LANG_TOTAL_ORDER}: {(allTotal/100).toFixed(2)}
                    </Typography>
                    <Button color="secondary" onClick={() => {this.saveOrderAction()}} fullWidth component="div" variant="contained">
                        {this.props.getManagerState().language.LANG_SEND_ORDER}
                    </Button>
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                     </Paper>
                </div>
    }
}
