import React from 'react';
import Settings from '../../../Settings/Settings'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    quantityInput: {
        margin: theme.spacing(1)
    },
    rootTotal: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        display:"inline-block"
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '',
      },
      rootAddress: {
        minWidth: 350
      }
  }));

export default function ContactSubmissionForm(props) {
    const classes = useStyles();
    const [contactMessagesEmail, setContactMessagesEmail] = React.useState("")
    const [errorContactMessagesEmail, setErrorContactMessagesEmail] = React.useState(false)
    const [contactMessagesPhone, setContactMessagesPhone] = React.useState("")
    const [errorContactMessagesPhone, setErrorContactMessagesPhone] = React.useState(false)
    const [contactMessagesMessage, setContactMessagesMessage] = React.useState("")
    const [errorContactMessagesMessage, setErrorContactMessagesMessage] = React.useState(false)
    const [contactMessagesFirstName, setContactMessagesFirstName] = React.useState("")
    const [errorContactMessagesFirstName, setErrorContactMessagesFirstName] = React.useState(false)
    const [contactMessagesLastName, setContactMessagesLastName] = React.useState("")
    const [errorContactMessagesLastName, setErrorContactMessagesLastName] = React.useState(false)

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
    
      const componentProps = {
          ...props.componentProps
      }

      const postContactMessagesAction = () => {
        setErrorContactMessagesEmail(false)
        setErrorContactMessagesPhone(false)
        setErrorContactMessagesMessage(false)
        setErrorContactMessagesFirstName(false)
        setErrorContactMessagesLastName(false)
        let method = "POST"
        let url = Settings.API_URL + Settings.API_ENDPOINT_CONTACT_MESSAGES

        componentMethods.setManagerState({loadingGdprRequest: true}, () => {
            let errors = []
            if(!contactMessagesEmail && !contactMessagesPhone) {
              setErrorContactMessagesEmail(true)
              setErrorContactMessagesPhone(true)
              errors.push(componentConstants.language.LANG_ERROR_EMPTY_EMAIL_AND_PHONE)
            }
            if(!contactMessagesMessage) {
              setErrorContactMessagesMessage(true)
              errors.push(componentConstants.language.LANG_ERROR_EMPTY_MESSAGE)
            }

            if(!contactMessagesFirstName) {
              setErrorContactMessagesFirstName(true)
              errors.push(componentConstants.language.LANG_ERROR_EMPTY_FIRST_NAME)
            }

            if(!contactMessagesLastName) {
              setErrorContactMessagesLastName(true)
              errors.push(componentConstants.language.LANG_ERROR_EMPTY_LAST_NAME)
            }

            if ( errors.length) {
                componentMethods.addError(errors.join(", "));
                return
            }
            let requestObject =  {
              contactMessagesEmail: contactMessagesEmail,
              contactMessagesPhone: contactMessagesPhone,
              contactMessagesMessage: contactMessagesMessage,
              contactMessagesLastName: contactMessagesLastName,
              contactMessagesFirstName: contactMessagesFirstName
            }

            componentMethods.ajax(url, 
               requestObject, 
               method, 'gdprrequests', 
                (data) => {
                   componentMethods.addError(componentConstants.language.LANG_SUCCESSFULY_SAVED_CONTACT_MESSAGE);
                   clearForm()
               })
        })
      }
      const clearForm = () => {
        setErrorContactMessagesEmail(false)
        setErrorContactMessagesPhone(false)
        setErrorContactMessagesMessage(false)
        setErrorContactMessagesFirstName(false)
        setErrorContactMessagesLastName(false)
        setContactMessagesEmail("")
        setContactMessagesPhone("")
        setContactMessagesMessage("")
        setContactMessagesFirstName("")
        setContactMessagesLastName("")
      }

  return (<div className={classes.rootAddress}><React.Fragment>
            <br/>
            <br/>
            <br/>
            <Typography variant="subtitle2">

            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={contactMessagesFirstName} error={errorContactMessagesFirstName} onChange={(e)=> {setContactMessagesFirstName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_FIRST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={contactMessagesLastName} error={errorContactMessagesLastName} onChange={(e)=> {setContactMessagesLastName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_LAST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={contactMessagesEmail} error={errorContactMessagesEmail} onChange={(e)=> {setContactMessagesEmail(e.target.value)}}  label={componentConstants.language.LANG_EMAIL} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={contactMessagesPhone} error={errorContactMessagesPhone} onChange={(e)=> {setContactMessagesPhone(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_PHONE_NUMBER} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField multiline rows={4} fullWidth={true} value={contactMessagesMessage} onChange={(e)=> {setContactMessagesMessage(e.target.value)}} label={componentConstants.language.LANG_MESSAGE} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button color="secondary" onClick={postContactMessagesAction} fullWidth component="div" variant="contained">
                        {componentConstants.language.LANG_SEND_MESSAGE}
                    </Button>
                </Grid> 
        </Grid>
    </React.Fragment>
    </div>
  );
}