import React from 'react';
import Settings from '../../../Settings/Settings'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import ClearIcon from '@material-ui/icons/Clear';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    quantityInput: {
        margin: theme.spacing(1)
    },
    rootTotal: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        display:"inline-block"
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '',
      },
      rootAddress: {
        minWidth: 350,
        padding: 8,
        marginBottom: 8
      }
  }));

export default function AddressComponent(props) {
    const classes = useStyles();
    const [vouchersCode, setVouchersCode] = React.useState("")
    const [vouchersValue, setVouchersValue] = React.useState("")
    const [vouchersType, setVouchersType] = React.useState("")
    const [vouchersStartDateTime, setVouchersStartDateTime] = React.useState("")
    const [vouchersEndDateTime, setVouchersEndDateTime] = React.useState("")
    const [vouchersQuantity, setVouchersQuantity] = React.useState("")
    const [vouchersUsages, setVouchersUsages] = React.useState(0)
    const [errorVouchersCode, setErrorVouchersCode]  = React.useState(false)
    const [errorVouchersValue, setErrorVouchersValue]  = React.useState(false)
    const [errorVouchersType, setErrorVouchersType]  = React.useState(false)
    const [errorVouchersStartDateTime, setErrorVouchersStartDateTime]  = React.useState(false)
    const [errorVouchersEndDateTime, setErrorVouchersEndDateTime]  = React.useState(false)
    const [errorVouchersQuantity, setErrorVouchersQuantity]  = React.useState(false)
    const [errorVouchersUsages, setErrorVouchersUsages]  = React.useState(false)
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
    
      const componentProps = {
          ...props.componentProps
      }

      const saveVouchersAction = () => {
        setErrorVouchersCode(false)
        setErrorVouchersValue(false)
        setErrorVouchersType(false)
        setErrorVouchersStartDateTime(false)
        setErrorVouchersEndDateTime(false)
        setErrorVouchersQuantity(false)
        setErrorVouchersUsages(false)

        let method = "POST"
        let url = Settings.API_URL + Settings.API_ENDPOINT_VOUCHERS

        componentMethods.setManagerState({loadingVouchers: true, vouchers: []}, () => {
            let errors = []
            if(!vouchersCode) {
                setErrorVouchersCode(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_CODE)
            }
            if(!vouchersValue) {
                setErrorVouchersValue(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_VALUE)
            }

            if(!vouchersType) {
                setErrorVouchersType(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_TYPE)
            }
            let startDateTime = ""
            let endDateTime = ""
            if(!vouchersStartDateTime) {
                setErrorVouchersStartDateTime(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_START_DATE_TIME)
            } else {
                startDateTime = vouchersStartDateTime.format("YYYY-MM-DD HH:mm:ss")
            }
            if(!vouchersEndDateTime) {
                setErrorVouchersEndDateTime(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_END_DATE_TIME)
            } else {
                endDateTime = vouchersEndDateTime.format("YYYY-MM-DD HH:mm:ss")
            }

            if(!vouchersQuantity) {
                setErrorVouchersQuantity(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_QUANTITY)
            }
            if ( errors.length) {
                componentMethods.addError(errors.join(", "));
                return
            }
            let requestObject =  {
                vouchersCode: vouchersCode,
                vouchersValue: vouchersValue,
                vouchersType: vouchersType,
                vouchersStartDateTime: startDateTime,
                vouchersEndDateTime: endDateTime,
                vouchersQuantity: vouchersQuantity,
                vouchersUsages: vouchersUsages
            }

            componentMethods.ajax(url, 
               requestObject, 
               method, null, 
                (data) => {
                   componentMethods.addError(componentConstants.language.LANG_SUCCESSFULY_SAVED_VOUCHER);
                   componentMethods.getVouchersAction()
                   clearAddVoucher()
               },{}, false, (data) => {
                componentMethods.addError(componentConstants.language.LANG_NOT_SAVED_VOUCHER);
               })
        })
      }
      const clearAddVoucher = () => {
        setVouchersCode("")
        setVouchersValue("")
        setVouchersType("")
        setVouchersStartDateTime("")
        setVouchersEndDateTime("")
        setVouchersQuantity("")
        setVouchersUsages("")
        setErrorVouchersCode(false)
        setErrorVouchersValue(false)
        setErrorVouchersType(false)
        setErrorVouchersStartDateTime(false)
        setErrorVouchersEndDateTime(false)
        setErrorVouchersQuantity(false)
        setErrorVouchersUsages(false)
      }


  return (<Paper className={classes.rootAddress}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={vouchersCode} error={errorVouchersCode} onChange={(e)=> {setVouchersCode(e.target.value)}}  label={componentConstants.language.LANG_VOUCHERS_CODE} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={vouchersValue} error={errorVouchersValue} onChange={(e)=> {setVouchersValue(e.target.value)}}  label={componentConstants.language.LANG_VOUCHERS_VALUE} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                <FormControl className={classes.formControl} fullWidth={true}>
                        <InputLabel>{componentConstants.language.LANG_VOUCHERS_TYPE}</InputLabel>
                        <Select
                        error={errorVouchersType}
                        value={vouchersType}
                        onChange={(e)=> {setVouchersType(e.target.value)}}
                        >
                        {Object.keys(Settings.SETTINGS_VOUCHERS_TYPES).map((el, index) => {
                            return <MenuItem key={index} value={el}>{componentConstants.language[Settings.SETTINGS_VOUCHERS_TYPES[el]]}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <DateTimePicker inputFormat="YYYY-MM-DD HH:mm:ss" renderInput={(props) => <TextField fullWidth={true} {...props} />} value={vouchersStartDateTime} error={errorVouchersStartDateTime} onChange={(newValue) => setVouchersStartDateTime(newValue)} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <DateTimePicker inputFormat="YYYY-MM-DD HH:mm:ss" renderInput={(props) => <TextField fullWidth={true} {...props} />} value={vouchersEndDateTime} error={errorVouchersEndDateTime} onChange={(newValue) => setVouchersEndDateTime(newValue)} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={vouchersQuantity} error={errorVouchersQuantity} onChange={(e)=> {setVouchersQuantity(e.target.value)}} label={componentConstants.language.LANG_VOUCHERS_QUANTITY} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={vouchersUsages} error={errorVouchersUsages} onChange={(e)=> {setVouchersUsages(e.target.value)}} label={componentConstants.language.LANG_VOUCHERS_USAGES} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button fullWidth={true} variant="contained" color="secondary" className={classes.buttonDelete} onClick={(e)=>{saveVouchersAction()}} startIcon={<SaveIcon />}>
                        {props.componentConstants.language.LANG_SAVE_VOUCHER}
                    </Button>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button fullWidth={true} variant="outlined" color="secondary" className={classes.buttonDelete} onClick={(e)=>{componentMethods.setShowAddVoucher(false)}} startIcon={<ClearIcon />}>
                        {props.componentConstants.language.LANG_CANCEL}
                    </Button>
                </Grid>
        </Grid>
    </Paper>
  );
}