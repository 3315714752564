import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import AddressComponent from '../../AddressComponent/AddressComponent';
export default function OrdersAddDeliveryAddressComponent(props) {
    useEffect(() => {
        }, []);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
          deleteAddressAction : () => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADDRESS +'/'+ props.element.addressSha,{},'DELETE',null, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_DELETED); componentMethods.getUserAddresses()}, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_NOT_DELETED)})
        },

        getUserAddresses: () => {
        },
        postSaveAddress: (data) => {

          const requestObject = {
            ordersAddressID: data.items[0].addressID
          }
          componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ORDER + props.ordersID,requestObject,'PUT',null, (data) => {componentMethods.addError(componentConstants.language.LANG_ORDER_UPDATED);}, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_ORDER_NOT_UPDATED)})
        }
      }
      const componentProps = {
          ...props.componentProps
      }
 
  return (<React.Fragment>
            <AddressComponent addAddress={true} element={{addressCountiesID: ''}} componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} />
        </React.Fragment>
  );
}