import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings'
import Avatar from '@material-ui/core/Avatar';
import Paper from '@material-ui/core/Paper';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Chip from '@material-ui/core/Chip';
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import PhotographersAvatar from './PhotographersAvatar';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchAlbumsContainer(props) {
    const classes = useStyles();
    const [usersProfilePictures, setUsersProfilePictures] = React.useState({})
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  return (
    <React.Fragment>
            {componentMethods.getManagerState().photographersResults.length > 0 ?
                <Paper style={{padding: 16, marginTop: 8}} id="albums-section">
                    {componentConstants.language.LANG_PHOTOGRAPHERS}:&nbsp;

                    {
                        componentMethods.getManagerState().photographersResults.map((el, idx) => {
                            return <Link to={'/user/pictures/'+el.usersID} ><PhotographersAvatar componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} el={el} /></Link>
                        })  
                    }
                    <React.Fragment>
                      {componentMethods.getManagerState().loadingPhotographers ?
                        <LoadingComponent />
                      :
                        null
                      }
                    {
                      !componentMethods.getManagerState().loadingPhotographers  && componentMethods.getManagerState().currentPhotographersPagination.last > componentMethods.getManagerState().currentPhotographersPagination.current ?
                        <Chip clickable onClick={() => {componentMethods.changePhotographersPage(componentMethods.getManagerState().currentPhotographersPagination.next)}} label={componentConstants.language.LANG_MORE_PHOTOGRAPHERS} color="primary" style={{margin: 8}} />
                      :
                      null
                    }
                    </React.Fragment>
                </Paper>
                :
                null
            }

    </React.Fragment>
  );
}