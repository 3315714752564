import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Button from '@material-ui/core/Button';
import SearchUsers from './MaterialComponents/SearchUsers'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import UserItemComponent from './MaterialComponents/UserItemComponent';
import AddIcon from '@material-ui/icons/Add';

export default class StocksComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getUsersAction()
        this.getAccessLevelsAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            refreshSearch: () => {
                that.refreshSearch()
            },
            getVouchersAction: () => {
                that.getVouchersAction()
            },
        }).setComponentsConstants('SearchVouchers', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchVouchers', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchOrders: (endpoint) => {
                this.getSearchOrders(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            searchUsers: (query) => {
                that.searchUsers(query)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getUsersAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_USERS + '?'
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        that.props.setManagerState({users: [], loadingUsers: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchUsers(endpoint)
            }    
        })
    }

    getSearchUsers(endpoint) {
        this.props.setManagerState({users: [], currentPagination: null, loadingUsers: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({users: data.items,currentPagination: data.pagination, loadingUsers: false})
            })
        })
    }

    searchUsers(query) {
        const that = this;
        let endpoint = settings.API_ENDPOINT_USERS
            endpoint += '?q=' + query

        this.getSearchUsers(endpoint)
    }
    refreshSearch () {
        this.getUsersAction()
    }
    changePage(page) {
        let endpoint = settings.API_ENDPOINT_USERS + '?'
        if(this.props.search === true) {
            endpoint = settings.API_ENDPOINT_USERS + '?q=' + this.props.urlParams.query 
        }
        endpoint += '&page=' + page
        this.getSearchUsers(endpoint)
    }

    getAccessLevelsAction() {
        let endpoint = settings.API_ENDPOINT_ADMIN_ACCESS_LEVELS
        this.props.setManagerState({adminAccessLevels: [], loadingAdminAccessLevels: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({adminAccessLevels: data.items, loadingAdminAccessLevels: false})
            })
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '80px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('users')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('users')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <SearchUsers {...this.buildComponentProps('SearchVouchers')} />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_EMAIL}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_FIRST_NAME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_LAST_NAME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_DATE_OF_BIRTH}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_LAST_LOGIN}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_USERS_SEX}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_AFFILIATE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PHOTOGRAPHER}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ACCESS_LEVELS}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {this.props.getManagerState().users.map((el, idx) => [
                                <UserItemComponent element={el} key={idx} {...this.buildComponentProps('AppbarComponent')} />
                            ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                     {
                        this.props.getManagerState().loadingOrders ?
                        <LoadingComponent />
                        :
                        null
                    }
                {this.props.getManagerState().errors.map((element, index) => { 
                    return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                    })}
                </div>
    }
}
