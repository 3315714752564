import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Style from '../Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import DeleteAlbumDialog from './DeleteAlbumDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import CardActionArea from '@material-ui/core/CardActionArea';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function ImageContainer(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [albumsName, setAlbumsName] = React.useState(props.element.albumsName)
  const [initialAlbumsName, setInitialAlbumsName] = React.useState(props.element.albumsName)
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  const setInitialValues = () => {
    setAlbumsName(initialAlbumsName)
  }
  const updateInitialValuesWithMyAlbums = () => {
    setInitialAlbumsName(albumsName)
  }

  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const saveAlbum = () => {
    const data = {
        albumsName: albumsName,
        albumsIsDeleted: props.element.albumsIsDeleted
    }
    updateInitialValuesWithMyAlbums();
    componentMethods.updateAlbum(data, props.element.albumsID)
  }
  
  const dateToFormat = props.element.imagesUploadedDateTime;
  return (
    <Grid item xs={12} lg={3} md={4}>
      <DeleteAlbumDialog albumsID={props.element.albumsID} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} />
    <Card className={classes.root}>
      <CardHeader
        title={<Link className={classes.userNameLink} to={"/albums/" + props.element.albumsID}>{props.element.albumsName}</Link>}
      />
        {props.element.albumsImagesPath ?
          <CardActionArea>
            <CardMedia
              className={classes.media}
              image={Settings.API_URL + Settings.API_ENDPOINT_FILE + props.element.albumsImagesPath+ '?thumbsWidth=800'}
              title={props.componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + props.element.usersFirstName + " " + props.element.usersLastName}
            />
          </CardActionArea>      
        :
          null
        }
      <CardActions disableSpacing>
        { props.canEdit ?
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <CreateIcon />
        </IconButton>
        :
        null
        }
      </CardActions>
      
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>
            <TextField fullWidth={true} id="standard-basic" label={componentConstants.language.LANG_ALBUM_NAME} value={albumsName} onChange={(e) => {setAlbumsName(e.target.value)}} />
          </Typography>
          <Button fullWidth={true} variant="contained" color="secondary" className={classes.buttonSave} onClick={saveAlbum}>
              {props.componentConstants.language.LANG_SAVE}
          </Button>
          <Button fullWidth={true} variant="outlined" color="secondary" className={classes.buttonCancel} onClick={setInitialValues}>
              {props.componentConstants.language.LANG_CANCEL}
          </Button>
          <Button fullWidth={true} variant="contained" color="primary" className={classes.buttonDelete} onClick={(e)=>{setOpenDeleteDialog(true)}} startIcon={<DeleteIcon />}>
              {props.componentConstants.language.LANG_DELETE}
          </Button>
        </CardContent>
      </Collapse>
    </Card>
    </Grid>
  );
}