import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/UserOrderRetourStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function UserOrderRetour(props) {
    const [showRetourDialog, setShowRetourDialog] = React.useState(false)
    const [retourMessage, setRetourMessage] = React.useState("")
    const [errorQuantity, setErrorQuantity] = React.useState(false)
    const [quantity, setQuantity] = React.useState(parseInt(props.element.shoppingCartItemsQuantity));

    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const handleChangeQuantity = (event) => {
        setQuantity(parseInt(event.target.value))
    }


    const sendRetourRequestAction = () => {
        let method = "POST"
        let url = Settings.API_URL + Settings.API_ENDPOINT_RETOUR

        componentMethods.setManagerState({loadingSendRetour: true}, () => {
            let errors = []
            if(!quantity) {
                setErrorQuantity(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_ADDRESS_STREET)
            }
            if ( errors.length) {
                componentMethods.addError(errors.join(", "));
                return
            }
            let requestObject =  {
                ordersRetourOrdersItemsID: props.element.ordersItemsID,
                ordersRetourMessage: retourMessage,
                ordersRetourQuantity: quantity
            }

            componentMethods.ajax(url, 
               requestObject, 
               method, 'addresses', 
                (data) => {
                   componentMethods.addError(componentConstants.language.LANG_SUCCESSFULY_POSTED_RETOUR_REQUEST);

               })
        })
      }

    return   <React.Fragment>
                <Button onClick={() => {setShowRetourDialog(!showRetourDialog)}} variant="contained" color="primary" fullWidth={true} className={classes.closeButton}>
                    {componentConstants.language.LANG_RETOUR_ITEM }
                </Button>
                {
                showRetourDialog ?
                    <React.Fragment>
                        <TextField fullWidth={true} value={retourMessage} onChange={(e)=> {setRetourMessage(e.target.value)}} label={componentConstants.language.LANG_RETOUR_MESSAGE} />
                        <FormControl className={classes.formControl} fullWidth={true}>                        
                        <InputLabel>{componentConstants.language.LANG_QUANTITY}</InputLabel>
                            <Select
                            error={errorQuantity}
                            value={quantity}
                            onChange={handleChangeQuantity}
                            >
                            {Array.from({length: props.element.ordersItemsQuantity}, (_, i) => i + 1).map((el, index) => {
                                return <MenuItem key={index} value={el}>{el}</MenuItem>
                            })}
                            </Select>
                        </FormControl>
                        <Button onClick={() => {sendRetourRequestAction()}} variant="contained" color="primary" fullWidth={true} className={classes.closeButton}>
                            {componentConstants.language.LANG_SEND_RETOUR_REQUEST }
                        </Button>
                    </React.Fragment>
                    :
                    null
                }

            </React.Fragment>
}
