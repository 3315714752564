import React from 'react';
import { Typography } from '@material-ui/core';
export default function ImageContainerDescription(props) {
        
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
  return (<React.Fragment>
                <Typography variant="subtitle2" component="p">Bine ai venit la magazinul nostru online de tablouri, unde arta prinde viata pe hartie fotografica A3 lucioasa! Descopera colectia noastra exceptionala de tablouri, create cu pasiune si atentie la detalii, pentru a aduce frumusetea artei direct in casa ta.</Typography>
                <Typography variant="h6" component="p">Tablouri Unice, Calitate Superioara:</Typography>
                <Typography variant="subtitle2" component="p">Fie ca iti doresti sa infrumusetezi livingul, biroul sau dormitorul, tablourile noastre printate pe hartie fotografica A3 te vor impresiona prin calitatea lor exceptionala. Fiecare lucrare este atent selectionata pentru a asigura o experienta vizuala de neegalat.</Typography>
                <Typography variant="h6" component="p">Imprimare pe Hartie Fotografica A3 Lucioasa:</Typography>
                <Typography variant="subtitle2" component="p">Cu o tehnologie de imprimare avansata, tablourile noastre sunt create pe hartie fotografica A3 lucioasa pentru a evidentia fiecare detaliu si nuanta. Textura lucioasa adauga profunzime imaginilor, oferindu-le un aspect elegant si profesional.</Typography>
                <Typography variant="h6" component="p">Colectii Diversificate:</Typography>
                <Typography variant="subtitle2" component="p">Exploreaza colectiile noastre variate, de la peisaje uimitoare si abstractii moderne pana la portrete expresive si arta urbana. Fiecare categorie contine opere de arta selectate cu grija pentru a satisface gusturile diverse ale clientilor nostri.</Typography>
                <Typography variant="h6" component="p">Personalizeaza-ti Spatiul:</Typography>
                <Typography variant="subtitle2" component="p">Oferim posibilitatea de a personaliza tablourile in functie de preferintele tale. Alege dimensiunea potrivita si decide daca doresti rama pentru a integra perfect arta in decorul casei tale.</Typography>
                <Typography variant="h6" component="p">Proces de Comanda Simplu si Sigur:</Typography>
                <Typography variant="subtitle2" component="p">Comanda tablourile preferate cu usurinta prin intermediul site-ului nostru intuitiv. Asiguram o tranzactie sigura si livrare prompta, astfel incat sa te bucuri rapid de noua ta achizitie artistica.</Typography>
                <Typography variant="subtitle2" component="p">Transforma-ti casa intr-un spatiu plin de stil si emotie cu tablourile noastre pe hartie fotografica A3 lucioasa. Alege calitatea si frumusetea, alege arta pentru a-ti completa interiorul cu atmosfera unica. Cumpara acum si descopera magia artei la tine acasa!</Typography>

      </React.Fragment>);
}