import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/SearchAllImagesStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchAllImages(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    let yourUrlParamsSearchQuery = ''
    if(componentConstants.urlParams && componentConstants.urlParams.query) {
      yourUrlParamsSearchQuery = componentConstants.urlParams.query
    }
    const [yourImagesQuery, setYourImagesQuery] = React.useState(yourUrlParamsSearchQuery)
    const [clearSearch, setClearSearch] = React.useState(false)
      useEffect(() => {
        if(clearSearch)
          searchImages()
      }, [clearSearch]);

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          searchImages(event)
        }
    }
    const handleClearSearch = () => {
      setClearSearch(true)
    }
    const searchImages = (e) => {
        let endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES
          if(yourImagesQuery && !clearSearch) {
            componentMethods.changeRoute('/search/' + yourImagesQuery)
              endpoint = Settings.API_ENDPOINT_SEARCH_IMAGES + '?q=' + yourImagesQuery
              componentMethods.getSearchImages(endpoint)
          } else {
            setClearSearch(false)
            componentMethods.changeRoute('/')
          }
      }

    return   <React.Fragment>
      <Grid item xs={1} lg={4} md={1}></Grid>
    <Grid item xs={10} lg={4} md={10}>
      <Paper component="form" className={classes.root}>
    <InputBase
      className={classes.input}
      placeholder={componentConstants.language.LANG_SEARCH_ALL_IMAGES + "..."}
      inputProps={{ 'aria-label': componentConstants.language.LANG_SEARCH_ALL_IMAGES + "..." }}
      value={yourImagesQuery} 
      onChange={(event)=>{ setYourImagesQuery(event.target.value)}}
      onKeyPress={handleKeyPress}
    />
    <IconButton type="button" onClick={searchImages} className={classes.iconButton} aria-label="search">
      <SearchIcon />
    </IconButton>
    {
      yourImagesQuery ?
        <IconButton type="button" onClick={() => {handleClearSearch()}} className={classes.iconButton} aria-label="search">
          <CancelIcon />
        </IconButton>
      :
      null
    }
    </Paper> </Grid><Grid item xs={1} lg={4} md={1}></Grid>
    </React.Fragment>
}
