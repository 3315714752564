import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Button from '@material-ui/core/Button';
import SearchStocks from './MaterialComponents/SearchStocks'
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import StocksItemComponent from './MaterialComponents/StockItemComponent';
import StocksImageItemComponent from './MaterialComponents/StockImageItemComponent';
export default class StocksComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getStocksAction()
        if (this.props.urlParams.query)
            this.getImagesAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            refreshSearch: () => {
                that.refreshSearch()
            }
        }).setComponentsConstants('SearchStocks', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchStocks', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchOrders: (endpoint) => {
                this.getSearchOrders(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getStocks: (searchQuery) => {
                that.getStocks(searchQuery)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }

    getStocksAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_IMAGES_STOCKS + '?'
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query
        }
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '&page=' + this.props.urlParams.page
        }
        that.props.setManagerState({stocks: [], loadingStocks: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchStocks(endpoint)
            }    
        })
    }

    getSearchStocks(endpoint) {
        this.props.setManagerState({stocks: [], currentPagination: null, loadingStocks: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({stocks: data.items,currentPagination: data.pagination, loadingStocks: false})
            })
        })
    }

    getImagesAction() {
        const that = this;
        let endpoint = settings.API_ENDPOINT_SEARCH_IMAGES_STOCKS
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '?q=' + this.props.urlParams.query
        }
        that.props.setManagerState({stocksImages: [], loadingStocksImages: true}, () => {
            if(that.getLocalStorage('user')) {
                that.getSearchImages(endpoint)
            }    
        })
    }

    getSearchImages(endpoint) {
        this.props.setManagerState({stocksImages: [], currentPagination: null, loadingStocksImages: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({stocksImages: data.items, loadingStocksImages: false})
            })
        })
    }

    getStocks(searchQuery)
    {
        let endpoint = settings.API_ENDPOINT_SEARCH_IMAGES_STOCKS 

            endpoint += '?q=' + searchQuery
        this.getSearchImages(endpoint)

        endpoint = settings.API_ENDPOINT_IMAGES_STOCKS 
            endpoint += '?q=' + this.props.urlParams.query

        this.getSearchStocks(endpoint)
    }
    refreshSearch () {
        this.getStocksAction()
        if (this.props.urlParams.query)
            this.getImagesAction()
    }
    changePage(page) {
        let endpoint = settings.API_ENDPOINT_IMAGES_STOCKS
        endpoint += '?page=' + page
        if(this.props.urlParams && this.props.urlParams.query) {
            endpoint += '&q=' + this.props.urlParams.query 
        }
        this.getSearchStocks(endpoint)
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '80px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('stocks')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('stocks')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <SearchStocks {...this.buildComponentProps('SearchStocks')} />
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDER_ITEMS_VARIANT_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDER_ITEMS_IMAGE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_DIMENSIONS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_QUANTITY}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_ACTIONS}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell variant="variant">{this.props.getManagerState().language.LANG_IMAGES}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                                    {this.props.getManagerState().stocksImages.map((el, idx) => [
                                        <StocksImageItemComponent element={el} key={idx} {...this.buildComponentProps('AppbarComponent')} />
                                    ])}
                            <TableRow>
                                <TableCell variant="variant">{this.props.getManagerState().language.LANG_STOCKS}</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                            {this.props.getManagerState().stocks.map((el, idx) => [
                                <StocksItemComponent element={el} key={idx} {...this.buildComponentProps('AppbarComponent')} />
                            ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                     {
                        this.props.getManagerState().loadingOrders ?
                        <LoadingComponent />
                        :
                        null
                    }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
