import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Style from '../Styles/LeftMenuDrawerStyle';
import MenuIcon from '@material-ui/icons/Menu';
import ViewListIcon from '@material-ui/icons/ViewList';
import Divider from '@material-ui/core/Divider';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Settings from '../../../Settings/Settings';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Fab from '@material-ui/core/Fab';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';

import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles((theme) => (Style(theme)));

export default function LeftMenuDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/stocks" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ShowChartIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_STOCKS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/orders" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_ORDERS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) ?
            <React.Fragment>
              <Link to="/my/albums" className={classes.link}>
                <ListItem>
                     
                          <ListItemIcon><PhotoAlbumIcon /></ListItemIcon>
                          <ListItemText primary={componentConstants.language.LANG_YOUR_ALBUMS}/>
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
            :
            null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) ?
            <React.Fragment>
              <Link to="/rewards" className={classes.link}>
                <ListItem>
                     
                          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                          <ListItemText primary={componentConstants.language.LANG_MONETIZATION}/>
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
            :
            null
          }
         {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
            <React.Fragment>
              <Link to="/payments" className={classes.link}>
                <ListItem>
                     
                          <ListItemIcon><MonetizationOnIcon /></ListItemIcon>
                          <ListItemText primary={componentConstants.language.LANG_PAYMENTS}/>
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
            :
            null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/retours" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_RETOURS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }

          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
            <React.Fragment>
              <Link to="/vouchers" className={classes.link}>
                <ListItem>
                    
                          <ListItemIcon><LocalAtmIcon /></ListItemIcon>
                          <ListItemText primary={componentConstants.language.LANG_VOUCHERS}/>
                </ListItem>
              </Link>
              <Divider />
            </React.Fragment>
            :
            null
            }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/admin/emails" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_EMAILS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_GODLIKE) ?
          <React.Fragment>
            <Link to="/admin/users" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_USERS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_WEBSITE_VISITORS) ?
          <React.Fragment>
            <Link to="/visitors" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><AccessibilityNewIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_WEBSITE_VISITORS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
           {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_AFFILIATE) ?
          <React.Fragment>
            <Link to="/affiliates" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><EuroSymbolIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_AFFILIATES}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
                    {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/admin/affiliates" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><ViewListIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_AFFILIATES_ADMIN}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/admin/variants/dimensions" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><BurstModeIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_VARIANTS_DIMENSIONS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
          {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
          <React.Fragment>
            <Link to="/admin/videos" className={classes.link}>
              <ListItem>
                   
                        <ListItemIcon><VideoLibraryIcon /></ListItemIcon>
                        <ListItemText primary={componentConstants.language.LANG_VIDEOS}/>
              </ListItem>
            </Link>
            <Divider />
          </React.Fragment>
          :
          null
          }
      </List>
    </div>
  );

  return (<React.Fragment>
    {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) || componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) || componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_GODLIKE) || componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_AFFILIATE) || componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_WEBSITE_VISITORS) ?
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
            <Fab color="secondary" aria-label="add" className={classes.menuFab}  onClick={toggleDrawer(anchor, true)}>
              <MenuIcon />
            </Fab>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
        </React.Fragment>
      ))}
    </div>
    : 
      null
    }
    </React.Fragment>
  );
}