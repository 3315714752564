import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/OrderItemsElementStyle'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function StocksItemComponent(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
      const componentProps = {
          ...props.componentProps
      }

return (<TableRow>
            <TableCell>{props.element.websiteVisitorsID}</TableCell>
            <TableCell><a href={props.element.websiteVisitorsRequestUri} target="_blank">{props.element.websiteVisitorsRequestUri}</a></TableCell>
            <TableCell>{props.element.websiteVisitorsReferrer}</TableCell>
            <TableCell><a href={"/visitors/" + props.element.websiteVisitorsIpSha}>{props.element.websiteVisitorsIpSha}</a></TableCell>
            <TableCell>{props.element.websiteVisitorsDateTime}</TableCell>
            <TableCell>{props.element.websiteVisitorsSection}</TableCell>
            <TableCell>{props.element.websiteVisitorsUserAgent}</TableCell>
        </TableRow>
  );
}