import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { subscribe, unsubscribe } from '../../Events/Events';
import LoopIcon from '@material-ui/icons/Loop';
import Paper from '@material-ui/core/Paper';

export default class RandomImagesPage extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()

        this.getRandomImages()
        // subscribe("scroll", () => this.automaticallyLoadOlder())
    }

    getRandomImages(setHash = false) {
        this.setState({ifeelLucky: true})
        let endpoint = Settings.API_ENDPOINT_RANDOM_IMAGES_WITH_VARIANTS
        this.props.setManagerState({randomImagesResultset: [], currentPagination: null, loadingRandomImages: true, oldPagination: this.props.getManagerState().currentPagination}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({randomImagesResultset: data.items, currentPagination: data.pagination, loadingRandomImages: false}, () => {
                    if (setHash)
                    {
                        var hash = 'random-images-header';
                        window.location.hash = '';
                        window.location.hash = hash;    
                    }
                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: 'Random Images'
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
            urlParams: this.props.urlParams
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
           changeAlbumsSearchPage: (page) => {
            that.changeAlbumsSearchPage(page)
           },
           changePhotographersPage: (page) => {
            that.changePhotographersPage(page)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              }
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            }
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('/')
        paginationPattern[0] = '/page/{{page}}'+ paginationPattern[0]
        paginationPattern = paginationPattern.join('/')
        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid item xs={12} lg={12} md={12} id="random-images-header">
                        <Paper style={{padding: 8, marginTop: 8, marginBottom: 8}}>
                            <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_RANDOM_IMAGES}</Typography>
                        </Paper>
                    </Grid>
                    {this.props.getManagerState().loadingRandomImages?
                        null
                    :
                        <React.Fragment>
                            <Grid item xs={1} lg={4} md={1}></Grid>
                            <Grid item xs={10} lg={4} md={10}>
                                <Button fullWidth style={classes.sendCommentButton} onClick={() => { this.getRandomImages(true) }} variant="contained" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                            </Grid>
                            <Grid item xs={1} lg={4} md={1}></Grid>
                        </React.Fragment>
                    }
                    
                        {
                            this.props.getManagerState().loadingRandomImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().randomImagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                         {this.props.getManagerState().loadingRandomImages?
                            null
                        :
                            <React.Fragment>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                    <Button fullWidth style={classes.sendCommentButton} onClick={() => { document.querySelector('#random-images-header').scrollIntoView(); this.getRandomImages(true) }} variant="contained" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                        }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}