import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import SearchAllImages from './MaterialComponents/SearchAllImages'
import Typography from '@material-ui/core/Typography';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { subscribe, unsubscribe } from '../../Events/Events';
import AlbumCoverUserImage from './MaterialComponents/AlbumCoverUserImage';
import ImagesPromoted from '../ImagesPromoted/ImagesPromoted';
export default class HomeComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        let endpoint = Settings.API_ENDPOINT_ALBUMS_IMAGES_WITH_VARIANTS + '/' + this.props.urlParams.albumsID

        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_IMAGES_WITH_VARIANTS + '/' + this.props.urlParams.albumsID + '?q=' + this.props.urlParams.query

            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }

        } else {
            if(this.props.urlParams && this.props.urlParams.page)
                endpoint += '?page=' + this.props.urlParams.page
        }
        this.getAlbumAction()
        this.getSearchImages(endpoint)


        // subscribe("scroll", () => this.automaticallyLoadOlder())
    }


    getProductAtHalfList()
    {
        if (this.props.getManagerState().albumsImagesResultset.length )
        {
            let halfLength = parseInt(this.props.getManagerState().albumsImagesResultset.length / 2)
            return this.props.getManagerState().albumsImagesResultset[halfLength].imagesID
        } else {
            return 0
        }

    }

    automaticallyLoadOlder() 
    {
        if (!this.state.ifeelLucky && !this.props.getManagerState().loadingAlbumsImages)
        {
            if (document.getElementById('product-' + this.getProductAtHalfList()) !== null)
            {
                if (document.getElementById('product-' + this.getProductAtHalfList()).scrollTop < window.innerHeight)
                {
                    this.getOlderImages()
                }
    
            }
        }
    }

    getLastImagesAlbumsImagesID()
    {
        let albumsImagesID = 1;
        for (let i in this.props.getManagerState().albumsImagesResultset)
        {
            if (parseInt(this.props.getManagerState().albumsImagesResultset[i].albumsImagesID) > parseInt(albumsImagesID))
                albumsImagesID = parseInt(this.props.getManagerState().albumsImagesResultset[i].albumsImagesID)
        }

        return albumsImagesID;
    }
    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_ALBUMS_IMAGES  + '/' + this.props.urlParams.albumsID + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_ALBUMS_IMAGES + '/' + this.props.urlParams.albumsID + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getSearchImages(endpoint)
    }
    getSearchImages(endpoint) {
        this.props.setManagerState({albumsImagesResultset: [], currentPagination: null, loadingAlbumsImages: true, noMoreImages: false}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({albumsImagesResultset: data.items, currentPagination: data.pagination, loadingAlbumsImages: false})


                let postObject = {
                    websiteVisitorsReferrer: document.referrer,
                    websiteVisitorsSection: Settings.API_URL,
                    websiteVisitorsRequestUri: window.location.href
                }
                this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
            })
        })
    }

    getOlderImages() {
        if (this.props.getManagerState().noMoreImages)
            return

        let loadedPage = this.props.getManagerState().loadedPage + 1
        this.props.setManagerState({loadingAlbumsImages: true}, () => {
            let endpoint = Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_IMAGES_OLDER_WITH_VARIANTS + this.props.urlParams.albumsID + '/' + this.getLastImagesAlbumsImagesID()
            if(this.props.search === true) {
                endpoint = Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_IMAGES_OLDER_WITH_VARIANTS + this.props.urlParams.albumsID + '/' + this.getLastImagesAlbumsImagesID() + "?q=" + this.props.urlParams.query
            }
            this.ajax(endpoint,{},"GET", null, (data) => {
                let oldAlbumsImagesResults = this.props.getManagerState().albumsImagesResultset
                oldAlbumsImagesResults = oldAlbumsImagesResults.concat(data.items)
                let noMoreImages = false
                if (data.items.length === 0)
                {
                    noMoreImages = true
                }
                this.props.setManagerState({loadingAlbumsImages: false, albumsImagesResultset: oldAlbumsImagesResults, loadedPage: loadedPage, noMoreImages: noMoreImages})

                let postObject = {
                    websiteVisitorsReferrer: document.referrer,
                    websiteVisitorsSection: Settings.API_URL,
                    websiteVisitorsRequestUri: window.location.href + ' Page ' + loadedPage
                }
                this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
            })
        })
    }

    getAlbumAction() {
        this.props.setManagerState({albumsImagesResultset: [], currentPagination: null, loadingAlbumsImages: true}, () => {
            this.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_INFO + '/' + this.props.urlParams.albumsID,{},"GET", null, (data) => {
                if (data.items[0])
                {
                    this.props.setManagerState({albumInfo: data.items[0]})
                }
            })
        })
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            page: "albums",
            urlParams: that.props.urlParams
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                hasExactAccess: (accessLevel) => {
                    return that.hasExactAccess(accessLevel)
                },
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              }
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            nextButton: {
                width: '100%',
                minWidth: 250,
                height: 252,
                padding: 8
            }
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('albums')
        paginationPattern[1] = '/page/{{page}}' + paginationPattern[1]
        paginationPattern = paginationPattern.join('albums')
        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                    {this.props.getManagerState().albumInfo ?
                            <Grid xs={12} md={12} lg={12}>
                                <AlbumCoverUserImage {...this.buildComponentProps('AppbarComponent')} albumInfo={this.props.getManagerState().albumInfo} />
                            </Grid>
                        :
                            null
                        }
                        <ImagesPromoted {...this.buildComponentProps('AppbarComponent')} />
                        <SearchAllImages albumsID={this.props.urlParams.albumsID} {...this.buildComponentProps('SearchAllImages')} />
                        {
                            !this.props.getManagerState().loadingAlbumsImages && this.props.getManagerState().albumsImagesResultset.length === 0?
                            <Grid item xs={12} lg={12} md={12}>
                                <Typography component="p" variant="subtitle1" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_NO_RESULTS}</Typography>
                            </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().albumsImagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                        {
                            this.props.getManagerState().loadingAlbumsImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            <React.Fragment>
                                {this.props.getManagerState().noMoreImages ?
                                    null
                                    :
                                    <React.Fragment>
                                        <Grid item xs={12} lg={4} md={4}></Grid>
                                        <Grid item xs={12} lg={4} md={4}>
                                            <Button color="secondary" variant="contained" style={classes.nextButton} onClick={() => {this.getOlderImages()}}>
                                                <Typography variant="subtitle2" component="p">{this.props.getManagerState().language.LANG_LOAD_MORE_IMAGES}</Typography>
                                                <ExpandMoreIcon />
                                            </Button>  
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4}></Grid>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                    </Grid>
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}