import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/SearchYourImagesStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchAlbums(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const [albumsName, setAlbumsName] = React.useState("")
    const [errorAlbumsName, setErrorAlbumsName] = React.useState(false)
    const addAlbum = (e) => {
        if (!albumsName)
        {
            setErrorAlbumsName(true)
            componentMethods.addError(componentConstants.language.LANG_ERROR_EMPTY_ALBUM_NAME)
            return
        }
        let endpoint = Settings.API_ENDPOINT_ALBUMS

        let postObject = {
            albumsName: albumsName
        }
        componentMethods.ajax(Settings.API_URL + endpoint,postObject,"POST", {}, (data) => {
            componentMethods.addError(componentConstants.language.LANG_SUCCESSFULLY_ADDED_ALBUM)
            componentMethods.refreshSearchYourAlbums()
            setAlbumsName("")
        })
      }

    return   <React.Fragment>
              <Grid item xs={1} lg={4} md={1}></Grid>
              <Grid item xs={10} lg={4} md={10}>
                <Paper component="form" className={classes.root}>
                    <InputBase
                    className={classes.input}
                    placeholder={componentConstants.language.LANG_ALBUM_NAME}
                    inputProps={{ 'aria-label': componentConstants.language.LANG_ALBUM_NAME}}
                    value={albumsName} 
                    onChange={(event)=>{ setAlbumsName(event.target.value)}}
                    />
                    <IconButton type="button" onClick={addAlbum} className={classes.iconButton} aria-label="search">
                        <AddIcon />
                    </IconButton>
                </Paper> 
            </Grid>
            <Grid item xs={1} lg={4} md={1}></Grid>
    </React.Fragment>
}
