import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Settings from '../../Settings/Settings'

export default function DeleteImageDialog(props) {

  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const handleClose = () => {
    props.setOpenDeleteDialog(false);
  };
  const getShoppingCartTotal = () => {
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCART_TOTAL + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, {},'GET','addresses', (data) => {
          componentMethods.setManagerState({shoppingCartTotal: data.items.total})
      })
  }
  const deleteShoppingCartItemAction = () => {
    let url = Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCARTITEMS + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha + '/' + props.element.shoppingCartItemsID

    componentMethods.setManagerState({loadingShoppingCartItems: true, shoppingCartItems: []}, () => {
        componentMethods.ajax(url, 
            {}, 
           "DELETE", 'shoppingCartItems', 
            (data) => {
              window.location.reload()
           })
    })
}
  return (
    <div>
      <Dialog
        open={props.openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{componentConstants.language.LANG_DELETE_SHOPPINGCARTITEM_MESSAGE_TITLE}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {componentConstants.language.LANG_DELETE_SHOPPINGCARTITEM_MESSAGE}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
          {componentConstants.language.LANG_CANCEL}
          </Button>
          <Button onClick={(e) => {deleteShoppingCartItemAction(); handleClose()}} color="secondary" variant="contained" autoFocus>
          {componentConstants.language.LANG_DELETE}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}