import color from '@material-ui/core/colors/teal';
export default function Style(theme) {
    const style = {
        root: {
          maxWidth: 345,
          margin: '8px auto'
        },
        media: {
          paddingTop: '56.25%', // 16:9
          height: 'auto',
          backgroundPosition: 'top',
          backgroundSize: 'contain'
        },
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
        avatar: {
          backgroundColor: color[500],
        },
        buttonSave: {
          marginBottom: 10
        },
        buttonCancel: {
          marginBottom: 10
        },
        buttonDelete: {
          
        },
        userNameLink: {
          textDecoration: "none",
          color: color[500]
        },
        avatarLink: {
          textDecoration: "none"
        },
        rootImage: {
          maxWidth: 350,
          margin: '0 auto',
          padding: 8,
          overflow: "hidden",
        },
        image: {
          width:'100%',
          margin: "0 auto",
          zIndex: 200,
          boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px'
          // boxShadow: 'rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px'
          
        },
        collapse: {
          padding: 8
        },
        addToCartContainer: {
          padding: 8
        },
        imageContainer: {
          minWidth: 250,
          minHeight: 250,
          backgroundColor: "#eceff1"
        },
        commentPager: {
          padding: 8,
          margin: '8px 0 8px 0'
        },
        profilePicture: {
          width: 128,
          height: 128,
          border: '2px solid white'
        },
        coverPhoto: {
          width: '100%',
          height: 315,
          position: "relative",
          marginBottom: 180,
          backgroundSize:     'cover',
          backgroundRepeat:   'no-repeat',
          backgroundPosition: 'center center',
          backgroundColor: '#bdbdbd'
        },
        profilePictureContainer: {
          position: 'absolute',
          top: 250
        },
        avatarContainer: {
          display:"inline-block",
          width:128,
          marginLeft: 8,
          height: 314
        },
        namePlaceholder: {
          display:'inline-block',
          position: "absolute",
          left: 135,
          top: 335
        }
      }
    return style
}