
export default class Helpers {

    hasAccess(accessLevel) {
        if (accessLevel === 99) 
            return true;
        const user = this.getLocalStorage("user")
        if(!user)
            return false;
        for(let i in user.usersAccessLevels) {
            if (accessLevel >= parseInt(user.usersAccessLevels[i].accessLevelsLevel))
                return true
        }
        return false
    }

    hasExactAccess(accessLevel) {
        if (accessLevel === 99) 
            return true;
        const user = this.getLocalStorage("user")
        if(!user)
            return false;
        for(let i in user.usersAccessLevels) {
            if (accessLevel === parseInt(user.usersAccessLevels[i].accessLevelsLevel))
                return true
        }
        return false
    }


    getLocalStorage(storageName = 'state') {
        // We need the try block because user may not permit our accessing localStorage.
        try {
            const serializedState = localStorage.getItem(storageName);
            if (serializedState === null) { // The key 'state' does not exist.
                return undefined;             // Let our reducer initialize the app.
            }

            return JSON.parse(serializedState)

        } catch (error) {
            return undefined // Let our reducer initialize the app.
        }
    }
}