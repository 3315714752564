export default function Style(theme) {
    const style = {
        formControl: {
            width: '100%',
            display: "block"
          },
          ordersObservationsInput: {
            margin: "8px 0"
          }
      }
    return style
}