import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Style from './Styles/ImageContainerStyle';
import Settings from '../../Settings/Settings'
import CreateIcon from '@material-ui/icons/Create';
import Moment from 'react-moment';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import DeleteImageDialog from './DeleteImageDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import AddToCartButton from './AddToCartButton'
import AddIcon from '@material-ui/icons/Add';
import CardActionArea from '@material-ui/core/CardActionArea';
import LinkIcon from '@material-ui/icons/Link';
import Chip from '@material-ui/core/Chip';
import $ from "jquery";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function ImageAlbums(props) {
    const classes = useStyles();
    const [imageAlbums, setImageAlbums] = React.useState([]);
    const [showAddToAlbum, setShowAddToAlbum] = React.useState(false);
    const [addToAlbums, setAddToAlbums]  = React.useState(false)
    useEffect(() => {
        getImageAlbums()
    }, []);

    const getImageAlbums = () => {
        if (props.image.albums)
        {
            setImageAlbums(props.image.albums.items)
        } else {
            $.ajax({
                url: Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_BY_IMAGE+ props.image.imagesID,
                type: 'GET',
                success: function (data) {
                    setImageAlbums(data.items)
                }
            });
    
        }
    }
    const postAlbumsImages = (albumsID) => {
            let postObject = {
                albumsImagesImagesID: props.image.imagesID,
                albumsImagesAlbumsID: addToAlbums
            }
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_IMAGES,postObject,'POST',null, (data) => {componentMethods.addError(componentConstants.language.LANG_IMAGE_ADDED_TO_ALBUM); getImageAlbums() }, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_IMAGE_NOT_ADDED_TO_ALBUM)})
            getImageAlbums()
    }
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const isImageInAlbum = (albumsID) => {
    for (let i in imageAlbums)
    {
        if (imageAlbums[i].albumsID === albumsID)
        {
            return true
        }
    }
  }
  const handleDeleteImageAlbum = (albumsID, imagesID) => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_IMAGES + '/' + albumsID + '/' + imagesID,{},'DELETE',null, (data) => {
        componentMethods.addError(componentConstants.language.LANG_IMAGES_DELETED_FROM_ALBUM); getImageAlbums() 
    }, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_IMAGES_NOT_DELETED_FROM_ALBUM)})
  }

  const putAlbumsImagesPath = (albumsID) => {
    let putObject = {
        albumsImagesPath: props.image.imagesPath
    }
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ALBUMS_IMAGES_PATH + albumsID,putObject,'PUT',null, (data) => {
        componentMethods.addError(componentConstants.language.LANG_ALBUMS_IMAGES_PATH_SET); getImageAlbums() 
    }, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_ALBUMS_IMAGES_PATH_NOT_SET)})
  }
  return (
    <React.Fragment>
        {imageAlbums ?
            <React.Fragment>
                <div style={{display: "inline-block"}}>
                    {props.canAddToAlbum ?
                        imageAlbums.map((el, idx) => {
                            return <React.Fragment key={idx}>
                                        <Chip label={el.albumsName} style={{margin:8}} clickable avatar={<Avatar alt={el.albumsName} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.albumsImagesPath + '?thumbsWidth=375'} />} onDelete={() => {handleDeleteImageAlbum(el.albumsID, props.image.imagesID)}} color="primary" />
                                        <IconButton color="secondary" onClick={() => {putAlbumsImagesPath(el.albumsID)}}>
                                            <CheckIcon />
                                        </IconButton>
                                    </React.Fragment>
                        })
                        :
                        imageAlbums.map((el, idx) => {
                            return <a href={'/albums/'+el.albumsID} ><Chip style={{margin:8}} clickable avatar={<Avatar alt={el.albumsName} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.albumsImagesPath + '?thumbsWidth=375'} />} label={el.albumsName} color="primary" /></a>
                        })  
                    }
                </div>
            </React.Fragment>
            :
            null
        }
        {props.canAddToAlbum ?
            <React.Fragment>
                {showAddToAlbum ?
                    <React.Fragment>
                        <Grid container>
                            <Grid xs={6} md={6} lg={6}>
                                {componentConstants.language.LANG_ALBUM_NAME}
                            </Grid>
                            <Grid xs={6} md={6} lg={6}>
                                <Select
                                style={{width:"100%"}}
                                className={classes.buttonCancel}
                                value={addToAlbums}
                                onChange={(e)=> {setAddToAlbums(e.target.value)}}
                                >
                                    {componentMethods.getManagerState().userAlbums.map((el, index) => {
                                        if (!isImageInAlbum(el.albumsID))
                                        {
                                            return <MenuItem key={index} value={el.albumsID}>{el.albumsName}</MenuItem>
                                        }
                                    })}
                                </Select>
                            </Grid>
                            <Grid xs={12} md={12} lg={12}>
                                <Button endIcon={<AddIcon />} fullWidth={true} className={classes.buttonCancel} variant="outlined" color="secondary" onClick={postAlbumsImages}>
                                    {componentConstants.language.LANG_ADD_TO_ALBUM}
                                </Button>      
                            </Grid>
                        </Grid>
                    </React.Fragment>
                :
                <Button fullWidth={true} className={classes.buttonCancel} variant="outlined" color="secondary" onClick={() => {setShowAddToAlbum(true)}}>
                    {componentConstants.language.LANG_ADD_TO_ALBUM}
                </Button>        
                }
            </React.Fragment>
        :
            null
        }

    </React.Fragment>
  );
}