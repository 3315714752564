import React, { useState, useEffect } from 'react';
import Settings from '../../Settings/Settings'
import $ from "jquery";
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {
    Link
  } from "react-router-dom";
  import color from '@material-ui/core/colors/teal';
import PhotographersAvatar from '../HomeComponent/MaterialComponents/PhotographersAvatar';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import AddToCartButton from '../ImageComponent/AddToCartButton';
import Popover from '@material-ui/core/Popover';
import ShareIcon from '@material-ui/icons/Share';
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
const useStyles = makeStyles((theme) => ({
    imagesContainer: {
      height: 320,
      overflowX: 'scroll',
      display: 'inline-flex',
      marginBottom: 8
    },
    imageElement: {
      height: 250,
      display: 'inline-block',
      width: 'auto',
      position: 'relative',
      borderRadius: 5
    },
    img: {
      height: '100%',
      borderRadius: 5,
      margin: '0 5px',
      borderColor: color[500],
      boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
    },
    avatarsContainer: {
      position: 'absolute',
      left: 8,
      bottom: 0
    },
    nextButton: {
      width: 250,
      minWidth: 250,
      height: 252,
      padding: 8
    },
    addToCartButton: {
    },
    addToCartButtonContained: {
      padding: 8
    }
  }));
  function AddToCartPopover(props) {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
  
    const componentConstants = {
      ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
      <div>
        <div className={classes.addToCartButtonContained}> 
        <Button className={classes.addToCartButton} fullWidth onClick={handleClick} color="secondary" variant="contained"><AddShoppingCartIcon /></Button>
        </div>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <AddToCartButton image={props.image} componentConstants={componentConstants} componentMethods={componentMethods} />
        </Popover>
      </div>
    );
  }

export default function ImagesPromoted(props) {
    const [imagesPromoted, setImagesPromoted] = useState([]);
    const [pagination, setPagination] = useState({});
    const [loadingNextPage, setLoadingNextPage] = React.useState(true);
    const classes = useStyles();

    const componentConstants = {
      ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const getPictureUrl = (imagePath) => {
      if(window.location.pathname === '/') {
        return '/image/' + imagePath
      } else {
        let concatString = '/'
        if (window.location.pathname.slice(-1) === '/') {
          concatString = ''
        }
        return window.location.pathname + concatString + 'image/' + imagePath
      }
    }
    useEffect(() => {
    getImagesPromoted()
    }, []);
    const getImagesPromoted = () => {
        setLoadingNextPage(true)
        $.ajax({
          url: Settings.API_URL + Settings.API_ENDPOINT_IMAGES_PROMOTED,
          type: 'GET',
          success: function (data) {
              setImagesPromoted(data.items)
              setPagination(data.pagination)
              setLoadingNextPage(false)
          }
      });
    }

    const deleteImagesPromoted = (imagesPromotedID, index) => {
        $.ajax({
          url: Settings.API_URL + Settings.API_ENDPOINT_IMAGES_PROMOTED + '/' + imagesPromotedID,
          type: 'DELETE',
          headers:{
              "X-Account-Token": componentMethods.getLocalStorage("user").tokensHash
          },
          success: function (data) {
            let dataItems = imagesPromoted
            dataItems.splice(index, 1)
            setImagesPromoted(dataItems)
          }
      });
    }
    const getNextImagesPromotedPage = () => {
      setLoadingNextPage(true)
      $.ajax({
        url: Settings.API_URL + Settings.API_ENDPOINT_IMAGES_PROMOTED + '?page=' + pagination.next,
        type: 'GET',
        success: function (data) {
          let dataItems = imagesPromoted.concat(data.items)
            setImagesPromoted(dataItems)
            setPagination(data.pagination)
            setLoadingNextPage(false)
        }
    });
  }
  
  return (<React.Fragment>

    <Paper style={{padding: 8, marginBottom: 8}}>
        <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{componentConstants.language.LANG_PROMOTED_IMAGES}</Typography>
    </Paper>
    <div className={classes.imagesContainer} id="imagesContainer">
                {imagesPromoted.map((el, index) => {
                  return <div key={index} className={classes.imageElement}>
                                    <Link to={getPictureUrl(el.imagesPath) }>
                            <img className={classes.img} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.imagesPath+ '?thumbsWidth=375'} alt={el ? componentConstants.language.LANG_WEBSITE_MOTO + componentConstants.language.LANG_IMAGE_PRE_TITLE + " " + el.usersFirstName + " " + el.usersLastName + " " + el.imagesLocationName + " " + el.imagesDescription + '' : null}/>
                            </Link>
                            <Grid container>
                              <Grid item xl={6} xs={6} l={6}>
                                <AddToCartPopover componentConstants={componentConstants} componentMethods={componentMethods} image={el} />
                              </Grid>
                              <Grid item xl={6} xs={6} l={6}>
                                <div className={classes.addToCartButtonContained}> 
                                  <IconButton className={classes.addToCartButtonContained} onClick={() => {
                                  navigator.clipboard.writeText(Settings.LINKS_SITE_URL + 'image/' + el.imagesPath)
                                  componentMethods.addError(componentConstants.language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                                  }}><ShareIcon /></IconButton>
                                </div>
                                </Grid>
                            </Grid>
                            <div className={classes.avatarsContainer}>
                              <Link to={"/user/pictures/" + el.usersID} ><PhotographersAvatar usersID={el.usersID} el={el} componentConstants={componentConstants} componentMethods={componentMethods} /></Link>
                              {componentMethods.getLocalStorage('user') && componentMethods.getLocalStorage('user').tokensUsersID === el.usersID ?
                              <IconButton color="primary" variant="contained" onClick={() => {deleteImagesPromoted(el.imagesPromotedID,index)}}><DeleteForeverIcon/ ></IconButton>
                              :
                              null}
                              {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_ORDER_PROCESSER) ?
                                  <a href={Settings.SETTING_SITE_URL + '/' + Settings.URL_STOCKS + el.imagesPath} target="_blank"><IconButton color="primary" className={classes.addToCartButtonContained}><ImageSearchIcon /></IconButton></a>
                                  :
                                  null
                              }
                            </div>
                        </div>
                })}
                {pagination.current < pagination.last && !loadingNextPage?
                <Button color="secondary" variant="contained" className={classes.nextButton} onClick={getNextImagesPromotedPage}>
                  <Typography variant="subtitle2" component="p">{componentConstants.language.LANG_LOAD_MORE_IMAGES}</Typography>
                  <NavigateNextIcon />
                </Button>  
                :
                null
              }
              {loadingNextPage ?
              <LoadingComponent />
              :
              null}
            </div>
    </React.Fragment>);
}