import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import LoadingComponent from '../../LoadingComponent/LoadingComponent';
import AddressComponent from '../../AddressComponent/AddressComponent';

export default function OrdersDeliveryAddressComponent(props) {
    const [address, setAddress] = React.useState(null)
    useEffect(() => {
        getDeliveryAddress()
        }, []);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
          deleteAddressAction : () => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADDRESS +'/'+ props.element.addressSha,{},'DELETE',null, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_DELETED); componentMethods.getUserAddresses()}, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_NOT_DELETED)})
        },

        getUserAddresses: () => {
            getDeliveryAddress()
            
        },
      }

    const getDeliveryAddress = () => {
        let url = Settings.API_URL + Settings.API_ENDPOINT_ORDERS_DELIVERY_ADDRESS + props.ordersID
        let method = "GET"
        componentMethods.ajax(url, 
            {}, 
            method, 'orderInvoiceAddress', 
            (data) => {
                setAddress(data.items[0])
            })
        
    }
      const componentProps = {
          ...props.componentProps
      }
 
  return (<React.Fragment>
            {
            address ?
                <AddressComponent showAddressPrefixes={true} editAddress={true} componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} element={address} />
                :
                <LoadingComponent />
            }
    </React.Fragment>
  );
}