import color from '@material-ui/core/colors/teal';
export default function Style(theme) {
    const style = {
        root: {
          width: '100%',
          height: '100%',
          margin: '0 auto',
          display: "block",
          position: "fixed",
          top: 0,
          zIndex: 2500,
          backgroundColor: "white",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundColor: color[50],
          overflow: "scroll"
        },

        button: {
            // position: "absolute",
            // left: 10,
            // top: 10
            margin: '0 0 0 8px',
        },
        buttonAddToCart: {
          position: "absolute",
          left: 10,
          bottom: 10
      },
      buttonDownload: {
        // position: "absolute",
        // left: 80,
        // top: 10
        margin: '0 0 0 8px'
    },
        img: {
          margin: "0 auto",
          display: "inline-block",
          maxHeight: '100vh',
          boxShadow: 'rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px',
          border: '5px solid rgba(0, 0, 0, 0.4)',
          margin: '0 0 8px 8px'
        },
        commentPager: {
          padding: 8,
          margin: '8px 0 8px 0'
        }
      }
    return style
}