import React from 'react';
import { Typography } from '@material-ui/core';

export default function CookiePolicyPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Politica cookies
            </Typography>
            <Typography variant="body1">
              Site-ul https://art.lexie.xyz/ utilizeaza cookie-uri.
            </Typography>
            <Typography variant="body1">
            Informatiile prezentate in continuare au scopul de a aduce la cunostinta utilizatorului mai multe detalii despre plasarea, utilizarea si administrarea cookie-urilor utilizate de site-ul https://art.lexie.xyz/
            </Typography>
            <Typography variant="body1">
              In cazul in care aveti nevoie de mai multe informatii, si ele nu se regasesc mai jos, ne puteti contacta la adresa de email <b>lexie.romania@gmail.com</b>.
            </Typography>
            <Typography variant="body1">
              Va rugam sa cititi cu atentie informatiile care urmeaza:
            </Typography>
            <Typography variant="body1">
              Acest website poate folosi cookie-uri atat proprii, cat si provenind de la terti, pentru a furniza vizitatorilor o experienta mult mai buna de navigare si servicii adaptate nevoilor si interesului fiecaruia.
            </Typography>
            <Typography variant="body1">
              Cookie-urile joaca un rol important in facilitarea accesului si livrarii multiplelor servicii de care utilizatorul se bucura pe internet, cum ar fi:
            </Typography>
            <Typography variant="body1">
              Personalizarea anumitor setari precum: limba in care este vizualizat un site, moneda in care se exprima anumite preturi sau tarife, pastrarea optiunilor pentru diverse produse (masuri, alte detalii etc) in cosul de cumparaturi (si memorarea acestor optiuni), salvarea anumitor preferinte in vederea reutilizarii viitoare.
            </Typography>
            <Typography variant="body1">
              Cookie-urile ofera detinatorilor de site-uri un feedback valoros asupra modului cum sunt utilizate site-urile lor de catre utilizatori, astfel incat sa le poata face si mai eficiente si mai usor accesibile.
            </Typography>
            <Typography variant="body1">
              Permit aplicatiilor multimedia sau de alt tip de pe alte site-uri sa fie incluse intr-un anumit site pentru a crea o experienta de navigare mai valoroasa, mai utila si mai placuta.
            </Typography>
            <Typography variant="body1">
              Imbunatatesc eficienta publicitatii online.
            </Typography>
            <Typography variant="h4" component="h4">
              Ce este un Cookie?
            </Typography>
            <Typography variant="body1">
              Un Cookie este un fisier de mici dimensiuni, format din litere si numere, care va fi stocat pe computerul, terminalul mobil sau alte echipamente ale unui utilizator de pe care se acceseaza Internetul.
            </Typography>
            <Typography variant="body1">
              Cookie-ul este instalat prin solicitara emisa de catre un web-server unui browser (ex: Chrome, Firefox) si este complet “pasiv” (nu contine programe software, virusi sau spyware si nu poate accesa informatiile de pe hard drive-ul utilizatorului).
            </Typography>
            <Typography variant="body1">
              Un cookie este format din 2 parti: numele si continutul sau valoarea cookie-ului. Mai mult, durata de existenta a unui cookie este determinata; tehnic, doar webserverul care a trimis cookie-ul il poate accesa din nou in momentul in care un utilizator se intoarce pe website-ul asociat webserverului respectiv.
            </Typography>
            <Typography variant="body1">
              Cookie-urile in sine nu solicita informatii cu caracter personal pentru a putea fi utilizate si, in cele mai multe cazuri, nu identifica personal utilizatorii de internet.
            </Typography>
            <Typography variant="body1">
              Exista 2 categorii mari de cookie-uri:
              <ul>
                <li>Cookie-uri de sesiune - acestea sunt stocate temporar in dosarul de cookie-uri al browserului web pentru ca acesta sa le memoreze pana cand utilizatorul inchide fereastra browserului sau se delogheaza de pe site-ul respectiv.</li>
                <li>Cookie-uri Persistente - Acestea sunt stocate pe hard-drive-ul unui computer sau echipament (si in general depinde de durata de viata prestabilita pentru cookie). Cookie-urile persistente le includ si pe cele plasate de un alt website decat cel pe care il viziteaza utilizatorul la momentul respectiv - cunoscute sub numele de &#39;third party cookies&#39; (cookieuri plasate de terti) - care pot fi folosite in mod anonim pentru a memora preferintele unui utilizator.</li>
              </ul>
            </Typography>
            <Typography variant="h4" component="h4">
              Care sunt avantajele cookie-urilor?
            </Typography>
            <Typography variant="body1" component="p">
              Un cookie contine informatii care fac legatura intre un web-browser (utilizatorul) si un web-server anume (website-ul). Daca un browser acceseaza acel web-server din nou, acesta poate citi informatia deja stocata si reactiona in consecinta. Cookie-urile asigura userilor o experienta placuta de navigare si sustin eforturile multor websiteuri pentru a oferi servicii confortabile utilizatorillor: ex - preferintele in materie de confidentialitate online, optiunile privind limba site-ului, cosuri de cumparaturi sau publicitate relevanta.
            </Typography>
            <Typography variant="h4" component="h4">
              Care este durata de viata a unui cookie?
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile sunt administrate de webservere. Durata de viata a unui cookie poate varia semnificativ, depinzand de scopul pentru care este plasat. Unele cookie-uri sunt folosite exclusive pentru o singura sesiune (cookie-uri de sesiune) si nu mai sunt retinute odata de utilizatorul a parasit website-ul si unele cookie-uri sunt retinute si refolosite de fiecare data cand utilizatorul revine pe acel website (cookie-uri permanente). Cu toate aceste, cookie-urile pot fi sterse de un utilizator in orice moment prin intermediul setarilor browserului.
            </Typography>
            <Typography variant="h4" component="h4">
              Ce sunt cookie-urile plasate de terti?
            </Typography>
            <Typography variant="body1" component="p">
              Anumite sectiuni de continut de pe unele site-uri pot fi furnizate prin intermediul unor terte parti/ furnizori (ex: un video, o reclama, o aplicatie de chat, etc). Aceste terte parti pot plasa de asemenea cookieuri prin intermediul site-ului si ele se numesc “third party cookies” pentru ca nu sunt plasate de proprietarul website-ului respectiv. Furnizorii terti trebuie sa respecte de asemenea legea in vigoare si politicile de confidentialitate ale detinatorului site-ului.
            </Typography>
            <Typography variant="h4" component="h4">
              Cum sunt folosite cookie-urile de catre acest site
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile asigura utilizatorilor o experienta placuta de navigare si sustin eforturile noastre pentru a oferi servicii confortabile utilizatorilor prin functionalitati precum preferintele in materie de confidentialitate online, cosul de cumparaturi sau publicitate relevanta. De asemenea, sunt utilizate in pregatirea unor statistici anonime agregate care ne ajuta sa intelegem cum un utilizator beneficiaza de paginile noastre web, permitandu-ne imbunatatirea structurii si continutului lor, excluzand indentificarea personala a utilizatorului.
            </Typography>
            <Typography variant="body1" component="p">
              O vizita pe acest site poate plasa urmatoarele tipuri de cookie-uri:
            </Typography>
            <Typography variant="body1" component="p">
              <ul>
                <li>Cookie-uri esentiale</li>
                <li>Cookie-uri de performanta</li>
                <li>Cookie-uri de Social media si publicitate</li>
              </ul>
            </Typography>
            <Typography variant="body1" component="p">
              Anumite cookie-uri, precum cele de performanta sau cele de Social media si publicitate, pot proveni de la terti.
            </Typography>
            <Typography variant="h4" component="h4">
              Cookie-uri esentiale
            </Typography>
            <Typography variant="body1" component="p">
              Aceste cookie-uri permit atat salvarea preferintelor cu privire la celelalte tipuri de cookie-uri folosite, cat si accesarea functionalitatilor de baza ale acestui site.
            </Typography>
            <Typography variant="body1" component="p">
                Ele retin preferintele utilizatorului pe acest site, asa incat nu mai este nevoie de setarea lor la fiecare vizitare a site-ului.
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile de inregistrare sunt generate atunci cand un utilizator se inregistreaza pe acest site, cu scopul de a ne informa ulterior daca acesta este inregistrat sau nu. Serverele noastre folosesc aceste cookie-uri pentru a ne arata contul cu care esti inregistrat si pentru a-ti oferi o experienta mai facila de interactiune cu site-ul.
            </Typography>
            <Typography variant="body1" component="p">
              Alte cookie-uri pe care le plasam pentru a-ti permite utilizarea optima a site-ului sunt cookie-urile corespunzatoare cosului de cumparaturi si listelor de Favorite (cu rolul de a salva informatiile aferente produselor pe care le-ai adaugat in cos sau ca Favorite), cookie-urile de localizare (utilizate pentru a stabili locatia ta in functie de IP, cu scopul de a precompleta anumite campuri necesare la inregistrare sau plasarea de comenzi), cookie-urile de sesiune generate la accesare si sterse automat la inchiderea browser-ului (necesare pentru functionarea protocolului HTTP, completarea anumitor formulare, interactiunea cu anumite elemente din site etc.), cookie-urile care recunosc tipul de terminal folosit - desktop sau mobile, cookie-uri ale aplicatiilor de chat care permit discutii in timp real cu serviciul de relatii cu clientii.
            </Typography>
            <Typography variant="h4" component="h4">
              Cookie-uri de performanta
            </Typography>
            <Typography variant="body1" component="p">
              Ele includ atat cookie-urile provenite din partea serviciilor de analiza a traficului, precum si cookie-uri plasate de anumite servicii terte care ofera functionalitati complementare site-ului.
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile de analiza a traficului permit masurarea in mod agregat a traficul site-ului, identificarea surselelor de trafic, ofera informatii despre cele mai vizitate sau cele mai putin accesate pagini, precum si despre modul in care utilizatorii interactioneaza cu site-ul. Informatiile enumerate sunt colectate in mod agregat si implicit complet anonim. Aceste cookie-uri pot proveni din partea unor terti precum servicii de Web Analytics (Ex: Google Analytics).
            </Typography>
            <Typography variant="h4" component="h4">
              Cookie-uri de Social media si publicitate
            </Typography>
            <Typography variant="body1" component="p">
              Acestea pot fi plasate prin intermediul site-ului de catre serviciile de social media sau publicitate pe care le utilizam.
            </Typography>
            <Typography variant="body1" component="p">
              Acest tip de cookie-uri poate fi folosit de catre aceste servicii pentru a determina un anumit profil al vizitatorilor si pentru a-ti afisa mesaje publicitare relevante pe alte site-uri pe care le vizitezi.
            </Typography>
            <Typography variant="body1" component="p">
              Ele pot proveni din partea unor terti precum servicii de publicitate (Ex: AdSense, AdWords), platforme de tip social media (Ex: Facebook, Twitter), etc.
            </Typography>
            <Typography variant="body1" component="p">
              Datorita modului de utilizare, acest site nu poate accesa aceste cookie-uri provenite de la terti, la fel cum tertele parti nu pot accesa cookie-urile detinute de acest site. De exemplu, cand distribuiti un articol folosind butonul pentru retelele sociale aflat pe acest site, acea retea sociala va inregistra activitatea dvs.
            </Typography>
            <Typography variant="h4" component="h4">
              Ce tip de informatii sunt stocate si accesate prin intermediul cookie-urilor?
            </Typography>
            <Typography variant="body1" component="p">
            Cookie-urile pastreaza informatii intr-un fisier text de mici dimensiuni care permit unui website sa recunoasca un browser. Webserverul va recunoaste browserul pana cand cookie-ul expira sau este sters. Cookie-ul stocheaza informatii importante care imbunatatesc experienta de navigare pe Internet ( ex: pastrarea unui user logat in contul sau de utilizator; pastrarea produselor in cosul de cumparaturi; pastrarea produselor in lista de Favorite).
            </Typography>
            <Typography variant="h4" component="h4">
              De ce sunt cookie-urile importante pentru Internet?
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile reprezinta punctul central al functionarii eficiente a Internetului, ajutand la generarea unei experiente de navigare prietenoase si adaptata preferintelor si intereselor fiecarui utilizator. Refuzarea sau dezactivarea cookieurilor poate ingreuna utilizarea unui site.
            </Typography>
            <Typography variant="body1" component="p">
              Refuzarea sau dezactivarea cookie-urilor nu inseamna ca nu veti mai primi publicitate online - ci doar ca aceasta nu va mai putea tine cont de preferintele si interesele dvs, evidentiate prin comportamentul de navigare.
            </Typography>
            <Typography variant="body1" component="p">
              Exemple de intrebuintari importante ale cookieurilor (care nu necesita autentificarea unui utilizator prin intermediul unui cont):
              <ul>
                <li>Continut si servicii adaptate preferintelor utilizatorului - categorii de stiri, vreme, sport, harti, servicii publice si guvernamentale, site-uri de entertainment si servicii de travel.</li>
                <li>Oferte adaptate pe interesele utilizatorilor - retinerea parolelor, preferintele de limba (Ex: afisarea rezultatelor cautarilor in limba Romana).</li>
                <li>Retinerea filtrelor de protectie a copiilor privind continutul pe Internet (optiuni family mode, functii de safe search).</li>
                <li>Limitarea frecventei de difuzare a reclamelor - limitarea numarului de afisari a unei reclame pentru un anumit utilizator pe un site.</li>
                <li>Furnizarea de publicitate mai relevanta pentru utilizator.</li>
                <li>Masurarea, optimizare si caracteristicile de analytics - cum ar fi confirmarea unui anumit nivel de trafic pe un website, ce tip de continut este vizualizat si modul cum un utilizator ajunge pe un website (ex prin motoare de cautare, direct, din alte website-uri etc). Website-urile deruleaza aceste analize a utilizarii lor pentru a imbunatati site-urile in beneficiul userilor.</li>
              </ul>
            </Typography>
            <Typography variant="h4" component="h4">
              Securitate si probleme legate de confidentialitate
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile folosesc formate tip plain text. Nu sunt alcatuite din bucati de cod asa ca nu pot fi executate nici nu pot auto-rula. In consecinta, nu se pot duplica sau replica pe alte retele pentru a se rula sau replica din nou. Cookie-urile pot fi totusi folosite pentru scopuri negative. Deoarece stocheaza informatii despre preferintele si istoricul de navigare al utilizatorilor, atat pe un anume site cat si pe mai multe alte siteuri, cookieurile pot fi folosite ca o forma de Spyware. Multe produse anti-spyware sunt constiente de acest fapt si in mod constant marcheaza cookie-urile pentru a fi sterse in cadrul procedurilor de stergere/scanare anti-virus/anti-spyware.
            </Typography>
            <Typography variant="body1" component="p">
              In general browserele au integrate setari de confidentialitate care furnizeaza diferite nivele de acceptare a cookie-urilor, perioada de valabilitate si stergere automata dupa ce utilizatorul a vizitat un anumit site. Alte aspecte de securitate legate de cookie-uri
            </Typography>
            <Typography variant="body1" component="p">
              Deoarece protectia identitatii este foarte valoroasa si reprezinta dreptul fiecarui utilizator de internet, este indicat sa se stie ce eventuale probleme pot crea cookie-urile. Pentru ca prin intermediul lor se transmit in mod constant in ambele sensuri informatii intre browser si website, daca un atacator sau persoana neautorizata intervine in parcursul de transmitere a datelor, informatiile continute de cookie pot fi interceptate. Desi foarte rar, acest lucru se poate intampla daca browserul se conecteaza la server folosind o retea necriptata (ex: o retea WiFi nesecurizata).
            </Typography>
            <Typography variant="body1" component="p">
              Alte atacuri bazate pe cookie implica setari gresite ale cookieurilor pe servere. Daca un website nu solicita browserului sa foloseasca doar canale criptate, atacatorii pot folosi aceasta vulnerabilitate pentru a pacali browserele in a trimite informatii prin intermediul canalelor nesecurizate. Atacatorii utilizeaza apoi informatiile in scopuri de a accesa neautorizat anumite site-uri. Este foarte important sa fiti atenti in alegerea metodei celei mai potrivite de protectie a informatiilor personale.
            </Typography>
            <Typography variant="h4" component="h4">
              Sfaturi pentru o navigare sigura si responsabila, bazata pe cookies
            </Typography>
            <Typography variant="body1" component="p">
              Datorita flexibilitatii lor si a faptului ca majoritatea dintre cele mai vizitate site-uri si cele mai mari folosesc cookieuri, acestea sunt aproape inevitabile. Dezactivarea cookie-urilor nu va permite accesul utilizatorului pe site-urile cele mai raspandite si utilizate printre care Youtube, Gmail, Facebook, Yahoo si altele. Iata cateva sfaturi care va pot asigura ca nevigati fara griji insa cu ajutorul cookieurilor:
              <ul>
                <li>Particularizati-va setarile browserului in ceea ce priveste cookie-urile pentru a reflecta un nivel confortabil pentru voi al securitatii utilizarii cookie-urilor.</li>
                <li>Daca nu va deranjeaza cookie-urile si sunteti singura persoana care utilizeaza computerul, puteti seta termene lungi de expirare pentru stocarea istoricului de navigare si al datelor personale de acces.</li>
                <li>Daca impartiti accesul la calculator, puteti lua in considerare setarea browserului pentru a sterge datele individuale de navigare de fiecare data cand inchideti browserul. Aceasta este o varianta de a accesa site-urile care plaseaza cookieuri si de a sterge orice informatie de vizitare la inchiderea sesiunii navigare.</li>
                <li>Instalati-va aplicatii antispyware si updatati-le in mod constant.</li>
              </ul>
            </Typography>
            <Typography variant="body1" component="p">
              Multe dintre aplicatiile de detectare si prevenire a spyware-ului includ detectarea atacurilor pe site-uri. Astfel, impiedica browserul de la a accesa website-uri care ar putea sa exploateze vulnerabilitatile browserului sau sa descarce software periculos. Asigurati-va ca aveti browserul mereu updatat. Multe dintre atacurile bazate pe cookies se realizeaza exploatand punctele slabe ale versiunilor vechi ale browserelor.
            </Typography>
            <Typography variant="body1" component="p">
              Cookie-urile sunt pretutindeni si nu pot fi evitate daca doriti sa va bucurati de accesul la cele mai populare site-uri de pe Internet. Cu o intelegere clara a modului lor de operare si a beneficiilor pe care le aduc, puteti lua masurile necesare de securitate astel incat sa puteti naviga cu incredere pe internet.
            </Typography>
            <Typography variant="h4" component="h4">
              Cum pot opri cookie-urile?
            </Typography>
            <Typography variant="body1" component="p">
              Este posibila configurarea browserului pentru ca aceste cookie-uri sa nu mai fie acceptate sau poti configura browserul sa accepte cookie-uri doar de la un site anume.
            </Typography>
            <Typography variant="body1" component="p">
              Toate browserele moderne ofera posibilitatea de a configura preferinte de stocare a cookie-urilor. Aceste setari se gasesc de regula in “optiuni” sau in meniul de “preferinte” al browserului tau. Pentru a intelege aceste setari, puteti folosi optiunea “ajutor” a browserului pentru mai multe detalii.
            </Typography>
            <Typography variant="h4" component="h4">
              Linkuri utile
            </Typography>
            <Typography variant="body1" component="p">
              Daca doriti sa afli mai multe informatii despre cookie-uri si la ce sunt utilizate, recomandam urmatoarele linkuri:
              <ul>
                <li><a href="https://allaboutcookies.org/" target="_blank">All About Cookies</a></li>
                <li><a href="https://www.youronlinechoices.com/ro/" target="_blank">Your Online Choices</a></li>
              </ul>
            </Typography>
      </React.Fragment>);
}