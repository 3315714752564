import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Style from '../../ImageComponent/Styles/ImageContainerStyle';
import Settings from '../../../Settings/Settings';
import 'moment/dist/locale/ro';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import Chip from '@material-ui/core/Chip';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import CheckIcon from '@material-ui/icons/Check';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function AddImageAddToAlbum(props) {
    const classes = useStyles();
    const [showAddToAlbum, setShowAddToAlbum] = React.useState(false);
    const [addToAlbums, setAddToAlbums]  = React.useState(false)
    const [seed, setSeed] = React.useState(1);
  const componentConstants = {
    ...props.componentConstants
  }

  const componentMethods = {
      ...props.componentMethods
  }

  const componentProps = {
      ...props.componentProps
  }
  const addAlbumIDToAlbums = () =>
  {
    componentMethods.addImageToAlbum(addToAlbums,props.fileIndex)
    let seed = Math.random() + Math.random()
    setSeed(seed);
  }
  const isImageInAlbum = (albumsID) => {
    for (let i in props.imageAlbums)
    {
        if (props.imageAlbums[i] === albumsID)
        {
            return true
        }
    }
  }
  const getUserAlbumsByAlbumsID = (albumsID) => {
    for (let i in componentMethods.getManagerState().userAlbums)
    {
        if (componentMethods.getManagerState().userAlbums[i].albumsID === albumsID)
        {
            return componentMethods.getManagerState().userAlbums[i]
        }
    }
  }
  const handleDeleteImageAlbum = (albumIndex) => {
    componentMethods.deleteImageFromAlbum(albumIndex, props.fileIndex)
    setSeed(Math.random() + Math.random());
  }

  return (
    <React.Fragment>
        {props.imageAlbums ?
            <React.Fragment>
                <div style={{display: "inline-block", padding: 8}}>
                    {props.canAddToAlbum ?
                        props.imageAlbums.map((el, idx) => {
                            return <React.Fragment key={idx}>
                                        <Chip label={getUserAlbumsByAlbumsID(el).albumsName} style={{margin:8}} clickable avatar={<Avatar alt={getUserAlbumsByAlbumsID(el).albumsName} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + getUserAlbumsByAlbumsID(el).albumsImagesPath + '?thumbsWidth=375'} />} onDelete={() => {handleDeleteImageAlbum(idx)}} color="primary" />
                                    </React.Fragment>
                        })
                        :
                        props.imageAlbums.map((el, idx) => {
                            return <Chip style={{margin:8}} clickable avatar={<Avatar alt={el.albumsName} src= {Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + el.albumsImagesPath + '?thumbsWidth=375'} />} label={getUserAlbumsByAlbumsID(el).albumsName} color="primary" />
                        })  
                    }
                </div>
            </React.Fragment>
            :
            null
        }
        {props.canAddToAlbum ?
            <React.Fragment>
                {showAddToAlbum ?
                    <React.Fragment>
                        <Grid container>
                            <Grid xs={6} md={6} lg={6}>
                                {componentConstants.language.LANG_ALBUM_NAME}
                            </Grid>
                            <Grid xs={6} md={6} lg={6}>
                                <Select
                                style={{width:"100%"}}
                                className={classes.buttonCancel}
                                value={addToAlbums}
                                onChange={(e)=> {setAddToAlbums(e.target.value)}}
                                >
                                    {componentMethods.getManagerState().userAlbums.map((el, index) => {
                                        if (!isImageInAlbum(el.albumsID))
                                        {
                                            return <MenuItem key={index} value={el.albumsID}>{el.albumsName}</MenuItem>
                                        }
                                    })}
                                </Select>
                            </Grid>
                            <Grid xs={12} md={12} lg={12}>
                                <Button endIcon={<AddIcon />} fullWidth={true} className={classes.buttonCancel} variant="outlined" color="secondary" onClick={() => {addAlbumIDToAlbums()}}>
                                    {componentConstants.language.LANG_ADD_TO_ALBUM}
                                </Button>      
                            </Grid>
                        </Grid>
                    </React.Fragment>
                :
                <Button fullWidth={true} className={classes.buttonCancel} variant="outlined" color="secondary" onClick={() => {setShowAddToAlbum(true)}}>
                    {componentConstants.language.LANG_ADD_TO_ALBUM}
                </Button>        
                }
            </React.Fragment>
        :
            null
        }

    </React.Fragment>
  );
}