import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import LeftMenuDrawer from './LeftMenuDrawer'
import {
    Link
  } from "react-router-dom";
import NotificationsDrawer from './NotificationsDrawer'
import CollectionsIcon from '@material-ui/icons/Collections';
import HomeSearchImages from '../../HomeComponent/MaterialComponents/SearchAllImages'
import AlbumsSearchImages from '../../Albums/MaterialComponents/SearchAllImages';
import UserSearchImages from '../../UserPicturesComponent/MaterialComponents/SearchUserImages'
import SearchIcon from '@material-ui/icons/Search';
import { Button } from '@material-ui/core';
import Footer from '../../FooterComponent/FooterComponent'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
const useStyles = makeStyles((theme) => ({
  text: {
    padding: theme.spacing(2, 2, 0),
  },
  paper: {
    paddingBottom: 50,
  },
  list: {
    marginBottom: theme.spacing(2),
  },
  subheader: {
    backgroundColor: theme.palette.background.paper,
  },
  appBar: {
    top: 'auto',
    bottom: 0,
  },
  grow: {
    flexGrow: 1,
  },
  fabButton: {
    position: 'absolute',
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: '0 auto',
  },
  moreButton: {
    marginLeft: 8
  }
}));

export default function BottomAppBar(props) {
  const classes = useStyles();
  const [showSearch, setShowSearch] = React.useState(props.componentConstants.urlParams && props.componentConstants.urlParams.query?true:false)
  const [showFooter, setShowFooter] = React.useState(false)
  const componentConstants = {
    ...props.componentConstants
}

const componentMethods = {
    ...props.componentMethods
}
const componentProps = {
    ...props.componentProps
}

  return (
    <React.Fragment>
      {/* <CssBaseline /> */}
      <AppBar position="fixed" color="primary" className={classes.appBar}>
        <Toolbar>
        {showSearch ? 
        <React.Fragment>
            {componentConstants.page === 'home'?
            <HomeSearchImages componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            :
            null}
            {componentConstants.page === 'albums'?
            <AlbumsSearchImages albumsID={componentConstants.urlParams.albumsID} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            :
            null}
            {componentConstants.page === 'user-pictures'?
            <UserSearchImages user={componentConstants.urlParams.user} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            :
            null}
            <Button variant="contained" color="secondary" onClick={() => {setShowSearch(false)}} >{componentConstants.language.LANG_CANCEL}</Button>
        </React.Fragment>
        :
        <React.Fragment>
            <LeftMenuDrawer componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            <NotificationsDrawer componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
          <Link to="/my/favorites"aria-label="Imaginile tale" className={classes.yourImagesLink}>
                <IconButton>
                  <FavoriteIcon color="secondary"/>
               </IconButton>
            </Link>
            <Link to="/random"aria-label="Imaginile tale" className={classes.yourImagesLink}>
                <IconButton>
                  <CollectionsIcon color="secondary"/>
               </IconButton>
            </Link>
            {componentConstants.page ?
                <Button color="secondary" variant="contained" onClick={() => {setShowSearch(true)}}  startIcon={<SearchIcon />}>{componentConstants.language.LANG_SEARCH}</Button>
            :
            null}
            {showFooter?
              <React.Fragment>
                <IconButton className={classes.moreButton} color="secondary" onClick={() => {setShowFooter(false)}}><ExpandLessIcon /></IconButton>
              </React.Fragment>
              :
            <React.Fragment>
              <IconButton className={classes.moreButton} color="secondary" onClick={() => {setShowFooter(true)}}><KeyboardArrowDownIcon /></IconButton>
            </React.Fragment>
            }
          </React.Fragment>
        }
        </Toolbar>
        {showFooter?
            <div>
            <Footer getManagerState={componentMethods.getManagerState} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />  
          </div>
        :
        null}
      </AppBar>
    </React.Fragment>
  );
}