import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import OrderItems from './OrderItems'
const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function OrderItemsDialogs(props) {

  const componentConstants = {
      ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const componentProps = {
        ...props.componentProps
    }

  const handleClose = () => {
    props.componentMethods.setManagerState({openOrderItemsDialog: false})
  };
  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="orderItemsTitle" open={props.openOrderItemsDialog}>
        <DialogTitle id="orderItemsTitle" onClose={handleClose}>
          {componentConstants.language.LANG_ORDER_ITEMS_DIALOG_TITLE}{componentMethods.getManagerState().viewOrderItem.ordersID}
        </DialogTitle>
        <DialogContent dividers>
          <OrderItems ordersID={componentMethods.getManagerState().viewOrderItem.ordersID} ordersSha={componentMethods.getManagerState().viewOrderItem.ordersSha} componentProps={componentProps} componentConstants={componentConstants} componentMethods={componentMethods} />
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}