import React from 'react';
import { Typography } from '@material-ui/core';

export default function AffiliatesPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h1" component="h1">
                Program de Afiliere Lexie Art
            </Typography>
            <Typography variant="body1">
                Bine ați venit la programul de afiliere Lexie Art, o oportunitate excelentă pentru a câștiga un venit suplimentar promovând operele noastre de artă deosebite. Prin intermediul acestui program, fiecare afiliat primește un procentaj din vânzările realizate prin intermediul link-urilor de afiliere personalizate.
            </Typography>
            
            <Typography variant="h3" component="h3">
                Cum Funcționează
            </Typography>
            <Typography variant="body1">
                Aplicare și Aprobare: Pentru a deveni afiliat Lexie Art, trebuie să completați <a href="/contact">formularul de contact</a> de pe pagina noastră în care sa mentionati ca doriți să deveniți partener afiliat. Toate aplicările sunt supuse unei evaluări din partea administratorilor noștri pentru a ne asigura că fiecare partener afiliat îndeplinește standardele noastre de calitate.
            </Typography>
            <Typography variant="body1">
                Promovarea Operelor de Artă: Utilizați link-ul de afiliere pentru a promova tablourile noastre pe blogul dvs., pe rețelele sociale sau prin alte canale. Fiecare achiziție realizată prin link-ul dvs. va fi înregistrată și atribuită contului dvs. de afiliat.
            </Typography>

            <Typography variant="h3" component="h3">
                Beneficii pentru Afiliati
            </Typography>
            <Typography variant="body1">
                Procentaj din Vânzări: La fiecare vânzare realizată prin intermediul link-ului dvs. de afiliere, veți primi un procentaj din suma totală a vânzării. Procentajul specific va fi comunicat în momentul aprobării afilierii.
            </Typography>
            <Typography variant="body1">
                Plăți Lunare: Veniturile generate din vânzări vor fi plătite lunar, la începutul fiecărei luni. Acest lucru asigură o tranziție lină și previzibilitate financiară pentru afiliații noștri.
            </Typography>
            <Typography variant="body1">
                Monitorizare și Raportare: Utilizatorii afiliați au acces la o platformă dedicată unde pot vedea în timp real vânzările realizate și comisioanele câștigate. Aceasta oferă o transparență totală și ajută la monitorizarea performanțelor de afiliat.
            </Typography>
        </React.Fragment>);
}