import React from 'react';
import Settings from '../../../Settings/Settings'
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    quantityInput: {
        margin: theme.spacing(1)
    },
    rootTotal: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        display:"inline-block"
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '',
      },
      rootAddress: {
        minWidth: 350
      }
  }));

export default function GDPRSubmissionForm(props) {
    const classes = useStyles();
    const [gdprRequestsFirstName, setGdprRequestsFirstName] = React.useState("")
    const [errorGdprRequestsFirstName, setErrorGdprRequestsFirstName] = React.useState(false)
    const [gdprRequestsLastName, setGdprRequestsLastName] = React.useState("")
    const [errorGdprRequestsLastName, setErrorGdprRequestsLastName] = React.useState(false)
    const [gdprRequestsEmail, setGdprRequestsEmail] = React.useState("")
    const [errorGdprRequestsEmail, setErrorGdprRequestsEmail] = React.useState(false)
    const [gdprRequestsMessage, setGdprRequestsMessage] = React.useState("")

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
    
      const componentProps = {
          ...props.componentProps
      }

      const postGdprRequestsAction = () => {
        setErrorGdprRequestsFirstName(false)
        setErrorGdprRequestsLastName(false)
        setErrorGdprRequestsEmail(false)
        let method = "POST"
        let url = Settings.API_URL + Settings.API_ENDPOINT_GDPR_REQUESTS

        componentMethods.setManagerState({loadingGdprRequest: true}, () => {
            let errors = []
            if(!gdprRequestsFirstName) {
                setErrorGdprRequestsFirstName(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_FIRST_NAME)
            }
            if(!gdprRequestsLastName) {
                setErrorGdprRequestsLastName(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_LAST_NAME)
            }

            if(!gdprRequestsEmail) {
                setErrorGdprRequestsEmail(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_EMAIL)
            }

            if ( errors.length) {
                componentMethods.addError(errors.join(", "));
                return
            }
            let requestObject =  {
                gdprRequestsFirstName: gdprRequestsFirstName,
                gdprRequestsLastName: gdprRequestsLastName,
                gdprRequestsEmail: gdprRequestsEmail,
                gdprRequestsMessage: gdprRequestsMessage
            }

            componentMethods.ajax(url, 
               requestObject, 
               method, 'gdprrequests', 
                (data) => {
                   componentMethods.addError(componentConstants.language.LANG_SUCCESSFULY_SAVED_GDPR_REQUEST);
                   clearForm()
               })
        })
      }
      const clearForm = () => {
        setErrorGdprRequestsFirstName(false)
        setErrorGdprRequestsLastName(false)
        setErrorGdprRequestsEmail(false)
        setGdprRequestsEmail("")
        setGdprRequestsFirstName("")
        setGdprRequestsLastName("")
        setGdprRequestsMessage("")
      }

  return (<div className={classes.rootAddress}><React.Fragment>
            <br/>
            <br/>
            <br/>
            <Typography variant="subtitle2">
                Daca doriti stergerea datelor dumneavoastră personale din baza noastră de date va rugam sa completati formularul de mai jos cu adresa de email a contului pe care il aveti pe platforma noastra. Va vom trimite un email in momentul in care datele au fost scoase din platforma noastră.
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={gdprRequestsFirstName} error={errorGdprRequestsFirstName} onChange={(e)=> {setGdprRequestsFirstName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_FIRST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={gdprRequestsLastName} error={errorGdprRequestsLastName} onChange={(e)=> {setGdprRequestsLastName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_LAST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={gdprRequestsEmail} error={errorGdprRequestsEmail} onChange={(e)=> {setGdprRequestsEmail(e.target.value)}}  label={componentConstants.language.LANG_EMAIL} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField multiline rows={4} fullWidth={true} value={gdprRequestsMessage} onChange={(e)=> {setGdprRequestsMessage(e.target.value)}} label={componentConstants.language.LANG_MESSAGE} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <Button color="secondary" onClick={postGdprRequestsAction} fullWidth component="div" variant="contained">
                        {componentConstants.language.LANG_SEND_REQUEST}
                    </Button>
                </Grid> 
        </Grid>
    </React.Fragment>
    </div>
  );
}