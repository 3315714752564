import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/PaymentMethodsComponentStyle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function CheckoutObservationsComponent(props) {
  const classes = useStyles();
    const setOrderObservations = (e) => {
        componentMethods.setManagerState({orderObservations: e.target.value})
    }
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  return (<div className={classes.ordersObservationsInput}>
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={112}>
            <TextField fullWidth id="standard-basic" label={componentConstants.language.LANG_ORDER_OBSERVATIONS} aria-label="orderObservations" name="orderObservations" value={componentMethods.getManagerState().orderObservations} onChange={setOrderObservations } />
        </Grid>
    </Grid>
    </div>
  );
}