import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import Typography from '@material-ui/core/Typography';
import PaymentsStatusesSelect from './MaterialComponents/PaymentsStatusesSelect'
export default class PaymentsComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getAdminPaymentsAction()
        this.getPaymentsStatusesAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SelectStatus', {
            ...this.basicComponentConstants
        }).setComponentsMethods('SelectStatus', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            saveLocalStorage: (storageObject, storageName) => {
               that.saveLocalStorage(storageObject, storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getAdminPaymentsAction: () => {
                that.getAdminPaymentsAction()
            }
        })
    }

    changePage(page) {
        let endpoint = settings.API_ENDPOINT_ADMIN_PAYMENTS
        endpoint += '?page=' + page
        this.getSearchAdminPayments(endpoint)
    }

    getAdminPaymentsAction() {
        let that = this
        let endpoint = settings.API_ENDPOINT_ADMIN_PAYMENTS
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '?page=' + this.props.urlParams.page
        }
        this.props.setManagerState({payments: [], loadingPayments: true}, () => {
            that.getSearchAdminPayments(endpoint)
        })
    }


    getSearchAdminPayments(endpoint) {
        this.props.setManagerState({payments: [], currentPagination: null, loadingPayments: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({payments: data.items,currentPagination: data.pagination, loadingPayments: false})
            })
        })
    }

    getPaymentsStatusesAction() {
        const that = this;
        that.props.setManagerState({paymentsStatuses: [], loadingPaymentsStatuses: true}, () => {
            that.ajax(settings.API_URL + settings.API_ENDPOINT_PAYMENTS_STATUSES,{},'GET','paymentsStatuses', (data) => {
                that.props.setManagerState({paymentsStatuses: data.items})
            })
        })
    }
    showHideCurrentRewards() {
        this.props.setManagerState({
            showCurrentRewards: !this.props.getManagerState().showCurrentRewards
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '70px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            img: {
                maxWidth: '150px',
                maxHeight: '150px'
            },
          };
        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('payments')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('payments')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Typography variant="h4" component="h4" style={classes.title}>
                        {this.props.getManagerState().language.LANG_PAYMENTS} 
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_FIRST_NAME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_LAST_NAME}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_EMAIL}</TableCell>                                                                                                        
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_DATE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_STATUS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_AMOUNT}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {this.props.getManagerState().payments.map((el, idx) => [
                        <TableRow key={idx}>
                                    <TableCell padding="checkbox">
                                        {el.paymentsID}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        {el.usersFirstName}
                                    </TableCell>
                                    <TableCell padding="checkbox">
                                        {el.usersLastName}
                                    </TableCell>     
                                    <TableCell padding="checkbox">
                                        {el.usersEmail}
                                    </TableCell>                                                   
                                    <TableCell padding="checkbox">
                                        {el.paymentsYearMonth}
                                    </TableCell>
                                    <TableCell style={{color: el.paymentsPaymentsStatusesID === "1" ?"red":"green"}}>
                                        <PaymentsStatusesSelect paymentsID={el.paymentsID} paymentsPaymentsStatusesID={el.paymentsPaymentsStatusesID} {...this.buildComponentProps('SelectStatus')} />
                                        {this.props.getManagerState().language[el.paymentsStatusesName]}
                                    </TableCell>
                                    <TableCell>
                                                {(parseInt(el.paymentsAmount) / 100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}
                                    </TableCell>
                                </TableRow>
                    ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    {
                        this.props.getManagerState().loadingPayments ?
                        <LoadingComponent />
                        :
                        null
                    }
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
