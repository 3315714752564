import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import settings from '../../../Settings/Settings'
import Style from '../Styles/CreateAccountPaperStyle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles((theme) => (Style(theme)));

export default function CreateAccountPaper(props) {
  const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const [createAccountUsersFirstName, setCreateAccountUsersFirstName] = React.useState(null)
    const [createAccountUsersLastName, setCreateAccountUsersLastName] = React.useState(null)
    const [createAccountUsersEmail, setCreateAccountUsersEmail] = React.useState(null)
    const [createAccountUsersPassword, setCreateAccountUsersPassword] = React.useState(null)
    const [createAccountUsersPasswordConfirm,setCreateAccountUsersPasswordConfirm] = React.useState(null)
    const [createAccountUsersDateOfBirthYear,setCreateAccountUsersDateOfBirthYear] = React.useState(null)
    const [createAccountUsersDateOfBirthMonth, setCreateAccountUsersDateOfBirthMonth] = React.useState(null)
    const [createAccountUsersDateOfBirthDay, setCreateAccountUsersDateOfBirthDay] = React.useState(null)
    const [createAccountUsersSexID, setCreateAccountUsersSexID] = React.useState(null)
    const [agreeTermsAndConditions, setAgreeTermsAndConditions] = React.useState(false)

    const handleChangeAgreeTermsAndConditions = (event) => {
        setAgreeTermsAndConditions(event.target.checked);
    };
    const createAccountAction = () => {
        if(!agreeTermsAndConditions) {
            componentMethods.addError(componentMethods.getManagerState().language["LANG_ERROR_TERMS_AND_CONDITIONS"])
            return
        }
        componentMethods.setManagerState({loadingCreateAccount: true})
        if(createAccountUsersPassword !== createAccountUsersPasswordConfirm) {
            componentMethods.addError(componentMethods.getManagerState().language["LANG_ERROR_INVALID_PASSWORD_CONFIRM"], {formInvalidCreateAccountPassword: true, formInvalidCreateAccountPasswordConfirm: true, loadingCreateAccount: false})
        } else {
            let requestCreateAccountObject = {
            }
            if (createAccountUsersEmail) {
                requestCreateAccountObject.usersEmail = createAccountUsersEmail
            }
            if (createAccountUsersPassword) {
                requestCreateAccountObject.usersPassword = createAccountUsersPassword
            }
            if (createAccountUsersDateOfBirthYear && createAccountUsersDateOfBirthMonth && createAccountUsersDateOfBirthDay) {
                requestCreateAccountObject.usersDateOfBirth = createAccountUsersDateOfBirthYear + '-' + createAccountUsersDateOfBirthMonth + '-' + createAccountUsersDateOfBirthDay
            }
            if (createAccountUsersSexID) {
                requestCreateAccountObject.usersUsersSexID = createAccountUsersSexID
            }
            if (createAccountUsersFirstName) {
                requestCreateAccountObject.usersFirstName = createAccountUsersFirstName
            }
            if (createAccountUsersLastName) {
                requestCreateAccountObject.usersLastName = createAccountUsersLastName
            }    
            componentMethods.ajax(settings.API_URL + settings.API_ENDPOINT_REGISTER,requestCreateAccountObject,'POST',null, (data) => {componentMethods.setSuccessfulUsersRegister(data)}, {}, false, (data) => {
                componentMethods.setCreateAccountErrors(data)
                componentMethods.setManagerState({loadingCreateAccount: false})
            })
        }

    }

    if(componentMethods.getManagerState().successfulUsersRegister) {
        return (
            <div className={classes.root}>
                <Typography variant="h5" component="h5" className={classes.title}>
                {componentConstants.language.LANG_SUCCESSFUL_REGISTER}
                </Typography>
                <Button onClick={() => {
                    componentMethods.setManagerState({successfulUsersRegister: false})
                    componentMethods.clearCreateAccountForm()
                    }} variant="contained" disableElevation color="secondary" fullWidth={true} className={classes.closeButton}>
                    {componentConstants.language.LANG_BACK}
                </Button>
                <Button onClick={componentMethods.handleClosePopover} variant="outlined" color="secondary" fullWidth={true} className={classes.closeButton}>
                    {componentConstants.language.LANG_CLOSE}
                </Button>
            </div>
            )
    }
  return (
    <form className={classes.root} noValidate autoComplete="off">
        <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_CREATE_ACCOUNT}
        </Typography>
        <TextField value={createAccountUsersFirstName} error={componentMethods.getManagerState().formInvalidCreateAccountUsersFirstName} className={classes.formFullWidth} onChange={(event) => {setCreateAccountUsersFirstName(event.target.value)}} id="CREATE_ACCOUNT_FIRST_NAME" label={componentConstants.language.LANG_FIRST_NAME} fullWidth={true} />
        <TextField value={createAccountUsersLastName} error={componentMethods.getManagerState().formInvalidCreateAccountUsersLastName} className={classes.formFullWidth} onChange={(event) => {setCreateAccountUsersLastName(event.target.value)}} id="CREATE_ACCOUNT_LAST_NAME" label={componentConstants.language.LANG_LAST_NAME} fullWidth={true} />
        <TextField value={createAccountUsersEmail} error={componentMethods.getManagerState().formInvalidCreateAccountUsersEmail} className={classes.formFullWidth} onChange={(event) => {setCreateAccountUsersEmail(event.target.value)}} id="CREATE_ACCOUNT_EMAIL" label={componentConstants.language.LANG_EMAIL} fullWidth={true} />
        <TextField value={createAccountUsersPassword} type="password" error={componentMethods.getManagerState().formInvalidCreateAccountPassword} className={classes.formFullWidth} onChange={(event) => {setCreateAccountUsersPassword(event.target.value)}} id="CREATE_ACCOUNT_PASSWORD" label={componentConstants.language.LANG_PASSWORD} fullWidth={true} />
        <TextField value={createAccountUsersPasswordConfirm} type="password" error={componentMethods.getManagerState().formInvalidCreateAccountPasswordConfirm} className={classes.formFullWidth} onChange={(event) => {setCreateAccountUsersPasswordConfirm(event.target.value)}} id="CREATE_ACCOUNT_CONFIRM_PASSWORD" label={componentConstants.language.LANG_CONFIRM_PASSWORD} fullWidth={true} />
        <FormControl className={classes.formControlSelect}>
            <InputLabel id="CREATE_ACCOUNT_BIRTH_DAY_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_DAY}</InputLabel>
            <Select
             error={componentMethods.getManagerState().formInvalidCreateAccountUsersDateOfBirth}
            value={createAccountUsersDateOfBirthDay}
             onChange={(event) => {setCreateAccountUsersDateOfBirthDay(event.target.value)}}
            labelId="CREATE_ACCOUNT_BIRTH_DAY_LABEL"
            id="CREATE_ACCOUNT_BIRTH_DAY"
            >
           {['01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31'].map((element, index) => { 
                return <MenuItem key={index} value={element}>{element}</MenuItem>
            })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControlSelect}>
            <InputLabel id="CREATE_ACCOUNT_BIRTH_MONTH_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_MONTH}</InputLabel>
            <Select
            error={componentMethods.getManagerState().formInvalidCreateAccountUsersDateOfBirth}
            value={createAccountUsersDateOfBirthMonth}
            onChange={(event) => {setCreateAccountUsersDateOfBirthMonth(event.target.value)}}
            labelId="CREATE_ACCOUNT_BIRTH_MONTH_LABEL"
            id="CREATE_ACCOUNT_BIRTH_MONTH_LABEL"
            >
            {['01','02','03','04','05','06','07','08','09','10','11','12'].map((element, index) => { 
                return <MenuItem key={index} value={element}>{element}</MenuItem>
            })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl className={classes.formControlSelect}>
            <InputLabel id="CREATE_ACCOUNT_BIRTH_YEAR_INPUT_LABEL">{componentConstants.language.LANG_DATE_OF_BIRTH_YEAR}</InputLabel>
            <Select
            error={componentMethods.getManagerState().formInvalidCreateAccountUsersDateOfBirth}
            value={createAccountUsersDateOfBirthYear}
            labelId="CREATE_ACCOUNT_BIRTH_YEAR_LABEL"
            id="CREATE_ACCOUNT_BIRTH_YEAR"
            onChange={(event) => {setCreateAccountUsersDateOfBirthYear(event.target.value)}}
            >
                {componentMethods.getManagerState().createAccountYears.items.map((element, index) => { 
                        return <MenuItem key={index} value={element.year}>{element.year}</MenuItem>
                     })}
            </Select>
        <FormHelperText>{componentConstants.language.LANG_DATE_OF_BIRTH}</FormHelperText>
      </FormControl>
      <FormControl  error={componentMethods.getManagerState().formInvalidCreateAccountUsersUsersSexID} component="fieldset" className={classes.formFullWidth}>
        <FormLabel  className={classes.formFullWidth} component="legend">{componentConstants.language.LANG_SEX}</FormLabel>
            <RadioGroup aria-label="gender" name="usersUsersSexID"  className={classes.formRadioGroup} value={createAccountUsersSexID} onChange={(event) => {setCreateAccountUsersSexID(event.target.value)}} >
                <FormControlLabel className={classes.formControlRadioGroup} value={"2"} control={<Radio />} label={componentConstants.language.LANG_FEMALE} />
                <FormControlLabel className={classes.formControlRadioGroup} value={"1"} control={<Radio />} label={componentConstants.language.LANG_MALE} />
                <FormControlLabel className={classes.formControlRadioGroup} value={"3"} control={<Radio />} label={componentConstants.language.LANG_OTHER} />
            </RadioGroup>
        </FormControl> 
         <FormControlLabel fullWidth={true} className={classes.formFullWidth}
        control={<Checkbox value={1} checked={agreeTermsAndConditions} onChange={handleChangeAgreeTermsAndConditions} name="imagesPublished" />}
        label={<React.Fragment>{componentConstants.language["LANG_AGREE_TERMS_AND_CONDITIONS"]} <Link className={classes.termsAndConditionsLink} to="/termeni-si-conditii">{componentConstants.language.LANG_AGREE_TERMS_AND_CONDITIONS_NAME}</Link></React.Fragment> }
      />
        {componentMethods.getManagerState().loadingCreateAccount ?
        <CircularProgress />
        :
        <Button onClick={createAccountAction} variant="contained" disableElevation color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CREATE_ACCOUNT}
        </Button>
        }
        <Button disabled={componentMethods.getManagerState().loadingCreateAccount} onClick={componentMethods.handleClosePopover} variant="outlined" color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CLOSE}
        </Button>
    </form>
  );
}