import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/CreateAccountPaperStyle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import RecoverPasswordScreen from './RecoverPasswordScreen';
import settings from '../../../Settings/Settings'
import { publish } from '../../../Events/Events';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function CreateAccountPaper(props) {
  const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }
    const [logInUsersEmail, setLogInUsersEmail] = React.useState()
    const [logInUsersPassword, setLogInUsersPassword] = React.useState()

    const logInAction = () => {
        componentMethods.setManagerState({loadingLogin: true})
        let requestLogInObject = {
            usersEmail: logInUsersEmail,
            usersPassword:logInUsersPassword,
        }
        componentMethods.ajax(settings.API_URL + settings.API_ENDPOINT_LOGIN,requestLogInObject,'POST',null, (data) => {
            componentMethods.setSuccessfulUsersLogIn(data); 
            componentMethods.clearLogInForm(); 
            if(componentMethods.getLocalStorage('shoppingCart')) {
                componentMethods.mergeShoppingCart()
            } else {
                componentMethods.postShoppingCartAction(); 
            }
            componentMethods.getUserAddresses()
            publish('login')
        }, {}, false, (data) => {componentMethods.setLogInErrors(data)}, () => {
            componentMethods.setManagerState({loadingLogin: false})
            componentMethods.addError(componentMethods.getManagerState().language['LANG_ERROR_ON_LOGIN'])
        })
    }

    if(componentMethods.getManagerState().recoverPasswordScreen) {
        return <RecoverPasswordScreen componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
    }
  return (
    <form className={classes.root} noValidate autoComplete="off">
        <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_AUTHENTICATE}
        </Typography>
        <TextField value={logInUsersEmail} error={componentMethods.getManagerState().formInvalidLogInUsersEmail} className={classes.formFullWidth} onChange={(event) => {setLogInUsersEmail(event.target.value)}} id="CREATE_ACCOUNT_EMAIL" label={componentConstants.language.LANG_EMAIL} fullWidth={true} />
        <TextField value={logInUsersPassword} type="password" error={componentMethods.getManagerState().formInvalidLogInPassword} className={classes.formFullWidth} onChange={(event) => {setLogInUsersPassword(event.target.value)}} id="CREATE_ACCOUNT_PASSWORD" label={componentConstants.language.LANG_PASSWORD} fullWidth={true} />
        {componentMethods.getManagerState().loadingLogin ?
            <CircularProgress />
        :
            <React.Fragment>
                <Button onClick={logInAction} variant="contained" disableElevation color="secondary" fullWidth={true} className={classes.closeButton}>
                    {componentConstants.language.LANG_AUTHENTICATE}
                </Button> 
                <Button disabled={componentMethods.getManagerState().loadingLogin} onClick={componentMethods.showRecoverPasswordScreen} variant="contained" color="primary" fullWidth={true} className={classes.closeButton}>
                    {componentConstants.language.LANG_RECOVER_PASSWORD}
                </Button>
            </React.Fragment>
        }
        <Button disabled={componentMethods.getManagerState().loadingLogin} onClick={componentMethods.handleClosePopover} variant="outlined" color="secondary" fullWidth={true} className={classes.closeButton}>
            {componentConstants.language.LANG_CLOSE}
        </Button>
    </form>
  );
}