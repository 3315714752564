import color from '@material-ui/core/colors/teal';
export default function Style(theme) {
    const style = {
        root: {
          maxWidth: 345,
          margin: '8px auto'
        },
        media: {
          paddingTop: '56.25%', // 16:9
          height: 'auto',
          backgroundPosition: 'top',
          backgroundSize: 'contain'
        },
        expand: {
          transform: 'rotate(0deg)',
          marginLeft: 'auto',
          transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
          }),
        },
        expandOpen: {
          transform: 'rotate(180deg)',
        },
        avatar: {
          backgroundColor: color[500],
        },
        buttonSave: {
          marginBottom: 10
        },
        buttonCancel: {
          marginBottom: 10
        },
        buttonDelete: {
          
        },
        userNameLink: {
          textDecoration: "none",
          color: color[500]
        },
        avatarLink: {
          textDecoration: "none"
        }
      }
    return style
}