export default function Style(theme) {
    const style = {
        img: {
            maxWidth: '100px',
            maxHeight: '100px'
        },
        button: {
            margin: '8px'
        },
        image: {
            width:'360px',
            margin: "0 auto",
            zIndex: 200,
            boxShadow: ' 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
          },
    }
    return style
}