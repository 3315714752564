import './App.css';
import RouterComponent from './Sites/art_lexie_xyz/Router/RouterComponent';
// date-fns
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// or for Day.js
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// or for Luxon
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
// or for Moment.js
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

function App() {
  return (    <LocalizationProvider dateAdapter={AdapterDayjs}><RouterComponent /></LocalizationProvider>);
}

export default App;
