import React from 'react';
import { Typography } from '@material-ui/core';
import ContactSubmissionForm from './ContactSubmissionForm '
export default function ContactPageComponent(props) {
        
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Contact
            </Typography>
            <Typography variant="body1">
                Ne puteți contacta la:
            </Typography>
            <Typography variant="body1">
                Email: lexie.romania@gmail.com
            </Typography>
            <Typography variant="body1">
                Telefon: +40752886933
            </Typography>
            <ContactSubmissionForm componentConstants={componentConstants} componentMethods={componentMethods} />
      </React.Fragment>);
}