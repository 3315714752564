import React from 'react';
import { Typography } from '@material-ui/core';

export default function MissionPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h1" component="h1">
                Misiunea noastră
            </Typography>
            <Typography variant="body1">
                La <strong>Lexie Art</strong>, ne dedicăm conectării pasionaților de artă cu creatori de fotografie. Marketplace-ul nostru este conceput pentru a susține fotografii talentați, oferindu-le o platformă unde își pot expune lucrările și pot ajunge la iubitorii de artă.
            </Typography>
            <Typography variant="body1">
                Înțelegem că un mediu armonios este esențial pentru un stil de viață de calitate. Arta care ne înconjoară în viața de zi cu zi – acasă, la birou sau în călătoriile noastre – contribuie la crearea unui design interior de bun gust și aduce unicitate fiecărui spațiu.
            </Typography>
            <Typography variant="body1">
                Fotografii prezenți pe site-ul nostru oferă privitorilor ocazia de a descoperi frumusețea în cele mai surprinzătoare contraste. Iubitorilor de artă le oferim oportunitatea de a comanda tablouri 100% autentice, adevărate reflexii ale mediului înconjurător, surprinse cu măiestrie prin obiectivul aparatului de fotografiat.
            </Typography>
            <Typography variant="h3" component="h3">
                Lexie Art: Platforma pentru Pasionații de Artă și Fotografi
            </Typography>
            <Typography variant="body1">
                <strong>Lexie Art</strong> este locul ideal pentru pasionații de artă și creatori de fotografie să se întâlnească. Prin marketplace-ul nostru, fotografii talentați își pot expune lucrările și pot ajunge la un public larg de iubitori de artă. 
            </Typography>
            <Typography variant="h3" component="h3">
                Arta pentru un Mediu Armonios
            </Typography>
            <Typography variant="body1">
                La Lexie Art, credem că un mediu armonios este esential pentru un stil de viață de calitate. Fie că este acasă, la birou sau în călătorii, arta contribuie la un design interior de bun gust și aduce unicitate fiecărui spațiu.
            </Typography>

            <Typography variant="h3" component="h3">
                Descoperiți Frumusețea prin Fotografie
            </Typography>
            <Typography variant="body1">
                Fotografii de pe Lexie Art oferă privitorilor posibilitatea de a descoperi frumusețea în cele mai surprinzătoare contraste. Comandați tablouri 100% autentice, care sunt reflexii pure ale mediului înconjurător, capturate cu măiestrie prin obiectivul aparatului de fotografiat.
            </Typography>
        </React.Fragment>);
}