import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import SearchUserImages from './MaterialComponents/SearchUserImages'
import Pagination from '../Pagination/PaginationComponent'
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import ShareIcon from '@material-ui/icons/Share';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FullSearchAlbumsContainer from '../HomeComponent/MaterialComponents/FullSearchAlbumsContainer';
import LoopIcon from '@material-ui/icons/Loop';
import Paper from '@material-ui/core/Paper';
import ProfileCoverUserImage from '../ImageComponent/ProfileCoverUserImage';
import ImagesPromoted from '../ImagesPromoted/ImagesPromoted';
export default class UserPicturesComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        let endpoint = Settings.API_ENDPOINT_USER_IMAGES_WITH_VARIANTS + this.props.urlParams.user
        let endpointAlbumsSearch = Settings.API_ENDPOINT_USER_ALBUMS_PAGINATED + this.props.urlParams.user

        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_USER_IMAGES_WITH_VARIANTS + this.props.urlParams.user +  '?q=' + this.props.urlParams.query
            endpointAlbumsSearch = Settings.API_ENDPOINT_USER_ALBUMS_PAGINATED + this.props.urlParams.user + '?q=' + this.props.urlParams.query
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '&page=' + this.props.urlParams.page
            }
        } else {
            if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
                endpoint += '?page=' + this.props.urlParams.page
            }
        }
        this.getSearchYourImages(endpoint)
        this.getRandomImages()
        this.getSearchAlbumsAction(endpointAlbumsSearch, true)
    }


    getRandomImages(setHash = false) {
        this.setState({ifeelLucky: true})
        let endpoint = Settings.API_ENDPOINT_USER_RANDOM_IMAGES_WITH_VARIANTS + this.props.urlParams.user
        this.props.setManagerState({randomImagesResultset: [], currentPagination: null, loadingRandomImages: true, oldPagination: this.props.getManagerState().currentPagination}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({randomImagesResultset: data.items, currentPagination: data.pagination, loadingRandomImages: false}, () => {
                    if (setHash)
                    {
                        var hash = 'random-images-header';
                        window.location.hash = '';
                        window.location.hash = hash;    
                    }
                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: 'Random Images'
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }

    getLastImagesID()
    {
        let imagesID = 0
        if (this.props.getManagerState().imagesResultset.length)
        {
            imagesID = this.props.getManagerState().imagesResultset[0].imagesID;
        }
        for (let i in this.props.getManagerState().imagesResultset)
        {
            if (parseInt(this.props.getManagerState().imagesResultset[i].imagesID) < parseInt(imagesID))
            imagesID = parseInt(this.props.getManagerState().imagesResultset[i].imagesID)
        }

        return imagesID;
    }

    changePage(page) {
        let endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_USER_IMAGES + this.props.urlParams.user
            if(this.props.urlParams.query) {
                endpoint += '?q=' + this.props.urlParams.query + '&page=' + page
            } else {
                endpoint += '?page=' + page
            }
        }
        this.getSearchYourImages(endpoint)
    }

    getSearchYourImages(endpoint) {
        this.props.setManagerState({imagesResultset: [], loadingImages: true, noMoreImages: false}, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({imagesResultset: data.items, loadingImages: false}, () => {
                    var hash = window.location.hash;
                    window.location.hash = '';
                    window.location.hash = hash;


                    let postObject = {
                        websiteVisitorsReferrer: document.referrer,
                        websiteVisitorsSection: Settings.API_URL,
                        websiteVisitorsRequestUri: window.location.href
                    }
                    this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
                })
            })
        })
    }


    getSearchYourImagesOlder() {
        let loadedPage = this.props.getManagerState().loadedPage + 1
        this.props.setManagerState({loadingImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_USER_IMAGES_OLDER_WITH_VARIANTS + this.props.urlParams.user + '/' + this.getLastImagesID()
            if(this.props.search === true) {
                    endpoint += '?q=' + this.props.urlParams.query
            }
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let olderImagesResultset = this.props.getManagerState().imagesResultset
                olderImagesResultset = olderImagesResultset.concat(data.items)


                let noMoreImages = false
                if (data.items.length === 0)
                {
                    noMoreImages = true
                }

                this.props.setManagerState({imagesResultset: olderImagesResultset, loadingImages: false, loadedPage: loadedPage, noMoreImages: noMoreImages}, () => {})

                let postObject = {
                    websiteVisitorsReferrer: document.referrer,
                    websiteVisitorsSection: Settings.API_URL,
                    websiteVisitorsRequestUri: window.location.href + ' Page ' + loadedPage
                }
                this.ajax(Settings.API_URL + Settings.API_ENDPOINT_WEBSITE_VISITORS,postObject,'POST',null)
            })
        })
    }

    getSearchAlbumsAction(endpoint, clear = false) {
        let managerStateObject = {loadingAlbumsSearch: true}
        if (clear === true)
        {
            managerStateObject = {loadingAlbumsSearch: true, albumsSearchResultset: []}
        }
        this.props.setManagerState(managerStateObject, () => {
            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let existingAlbums = this.props.getManagerState().albumsSearchResultset
                existingAlbums = existingAlbums.concat(data.items)
                this.props.setManagerState({albumsSearchResultset: existingAlbums, currentAlbumsSearchPagination: data.pagination, loadingAlbumsSearch: false})
            })
        })
    }

    changeAlbumsSearchPage(page) {
        let endpoint = Settings.API_ENDPOINT_USER_ALBUMS_PAGINATED + this.props.urlParams.user + '?page=' + page
        if(this.props.search === true) {
            endpoint = Settings.API_ENDPOINT_USER_ALBUMS_PAGINATED + this.props.urlParams.user + '?q=' + this.props.urlParams.query + '&page=' + page
        }
        this.getSearchAlbumsAction(endpoint)
    }

    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            page: "user-pictures",
            urlParams: that.props.urlParams
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            getSearchYourImages: (endpoint) => {
                this.getSearchYourImages(endpoint)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
                hasExactAccess: (accessLevel) => {
                    return that.hasExactAccess(accessLevel)
                },
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
            urlParams: this.props.urlParams
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
           changeAlbumsSearchPage: (page) => {
            that.changeAlbumsSearchPage(page)
           },
           changePhotographersPage: (page) => {
            that.changePhotographersPage(page)
           }
        }).setComponentsConstants('SearchUserImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchUserImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },

            getSearchYourImages: (endpoint) => {
                this.getSearchYourImages(endpoint)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            },
            nextButton: {
                width: '100%',
                minWidth: 250,
                height: 252,
                padding: 8
            },
          };
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('pictures')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('pictures')

        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    <Grid container>
                        {this.props.getManagerState().imagesResultset.length > 0 ?
                        <ProfileCoverUserImage {...this.buildComponentProps('AppbarComponent')} usersID={this.props.urlParams.user} usersFirstName={this.props.getManagerState().imagesResultset[0].usersFirstName}  usersLastName={this.props.getManagerState().imagesResultset[0].usersLastName}/>
                        :
                        null}
                        <ImagesPromoted {...this.buildComponentProps('AppbarComponent')} />
                        <SearchUserImages {...this.buildComponentProps('SearchUserImages')} />
                        {/* {this.props.getManagerState().imagesResultset.length > 0
                            ?
                            <Grid xs={12} md={12} lg={12} style={{textAlign:"center"}}>
                                <Typography component="h3" variant="h3" style={{textAlign:"center"}}>{this.props.getManagerState().language.LANG_PHOTOS_OF} {this.props.getManagerState().imagesResultset[0].usersFirstName}  {this.props.getManagerState().imagesResultset[0].usersLastName}
                                <IconButton onClick={() => {
                                    navigator.clipboard.writeText(Settings.LINKS_SITE_URL + 'user/pictures/' + this.props.urlParams.user)
                                    this.addErrorMain(this.props.getManagerState().language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                                }}><ShareIcon /></IconButton>
                                </Typography>
                            </Grid>
                            :
                            null
                        } */}
                                                {
                            !this.props.getManagerState().loadingImages && this.props.getManagerState().imagesResultset.length === 0?
                            <Grid item xs={12} lg={12} md={12}>
                                <Typography component="p" variant="subtitle1" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_NO_RESULTS}</Typography>
                            </Grid>
                            :
                            null
                        }
                        <Grid item xs={12} lg={12} md={12}>
                            <FullSearchAlbumsContainer {...this.buildComponentProps('ImageContainer')} />
                        </Grid>
                        {
                            this.props.getManagerState().imagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                        {
                            this.props.getManagerState().loadingImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            <React.Fragment>
                                {this.props.getManagerState().noMoreImages?
                                null
                                :
                                    <React.Fragment>
                                        <Grid item xs={12} lg={4} md={4}></Grid>
                                        <Grid item xs={12} lg={4} md={4}>
                                            <Button color="secondary" variant="contained" style={classes.nextButton} onClick={() => {this.getSearchYourImagesOlder()}}>
                                                <Typography variant="subtitle2" component="p">{this.props.getManagerState().language.LANG_LOAD_MORE_IMAGES}</Typography>
                                                <ExpandMoreIcon />
                                            </Button>  
                                        </Grid>
                                        <Grid item xs={12} lg={4} md={4}></Grid>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                        }
                        {this.props.getManagerState().imagesResultset.length > 0 ?
                        <Grid item xs={12} lg={12} md={12} id="random-images-header">
                            <Paper style={{padding: 8, marginTop: 8, marginBottom: 8}}>
                                <Typography component="h5" variant="h5" style={{textAlign: "center"}}>{this.props.getManagerState().language.LANG_RANDOM_IMAGES} ({this.props.getManagerState().imagesResultset[0].usersFirstName}  {this.props.getManagerState().imagesResultset[0].usersLastName})</Typography>
                            </Paper>
                        </Grid>
                        :
                        null
                        }
                        {this.props.getManagerState().loadingRandomImages?
                            null
                        :
                            <React.Fragment>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                    <Button fullWidth style={classes.sendCommentButton} onClick={() => { this.getRandomImages(true) }} variant="outlined" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                        }
                        {
                            this.props.getManagerState().loadingRandomImages ?
                                <Grid item xs={12} lg={12} md={12}>
                                    <LoadingComponent />
                                </Grid>
                            :
                            null
                        }
                        {
                            this.props.getManagerState().randomImagesResultset.map((el, idx) => {
                                return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                            })
                        }
                         {this.props.getManagerState().loadingRandomImages?
                            null
                        :
                            <React.Fragment>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                    <Button fullWidth style={classes.sendCommentButton} onClick={() => { document.querySelector('#random-images-header').scrollIntoView(); this.getRandomImages(true) }} variant="outlined" color="secondary" startIcon={<LoopIcon />}>{this.props.getManagerState().language.LANG_I_FEE_LUCKY_SHOW_RANDOM_IMAGES}</Button>
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                        }
                    </Grid>
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}