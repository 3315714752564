import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import UserOrderRetour from './MaterialComponents/UserOrderRetour';

export default class UserOrderComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getOrderAction()
        this.getOrderItemsAction()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            addError: (errorMessage, stateObject) => {
                that.addErrorMain(errorMessage, stateObject)
            },
        })
    }

    getOrderAction() {
        let that = this
        let endpoint = settings.API_ENDPOINT_ORDER + this.props.urlParams.ordersID + '/' + this.props.urlParams.ordersSha
        this.props.setManagerState(
            {userOrder: [], loadedUserOrder: false, emptyUserOrder: true, ordersDeliveryMethodsPrice: 0}, 
            () => {
                this.ajax(settings.API_URL + endpoint,{},"GET", null, 
                    (data) => {
                        if(data.items.length) {
                            this.props.setManagerState(
                                {userOrder: data.items, loadedUserOrder: true, ordersDeliveryMethodsPrice: data.items[0].ordersDeliveryMethodsPrice, emptyUserOrder: false},
                                () => {
                                    that.getInvoiceAddressAction()
                                    that.getDeliveryAddressAction()
                                })
                        } else {
                            that.props.setManagerState({emptyUserOrder: true})
                        }
                    },
                    {},
                    false,
                    () => {
                        that.props.setManagerState({emptyUserOrder: true})
                    },
                    () => {
                        that.props.setManagerState({emptyUserOrder: true})
                    })
            })
      }
      getInvoiceAddressAction() {
        let endpoint = settings.API_ENDPOINT_USER_ORDER_INVOICE_ADDRESS + this.props.getManagerState().userOrder[0].ordersID + '/' + this.props.getManagerState().userOrder[0].ordersSha
        this.props.setManagerState({userOrderInvoiceAddress: [], loadedUserOrderInvoiceAddress: false}, () => {
                this.ajax(settings.API_URL + endpoint,{},"GET", null, 
                    (data) => {
                        if(data.items.length) {
                            this.props.setManagerState({userOrderInvoiceAddress: data.items, loadedUserOrderInvoiceAddress: true})
                        }
                })
        })

      }
      getDeliveryAddressAction() {
        let endpoint = settings.API_ENDPOINT_USER_ORDER_DELIVERY_ADDRESS + this.props.getManagerState().userOrder[0].ordersID + '/' + this.props.getManagerState().userOrder[0].ordersSha
        this.props.setManagerState({userOrderDeliveryAddress: [], loadedUserOrderDeliveryAddress: false}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, 
                (data) => {
                    if(data.items.length) {
                        this.props.setManagerState({userOrderDeliveryAddress: data.items, loadedUserOrderDeliveryAddress: true})
                    }
            })
        })
      }
      getOrderItemsAction() {
        this.props.setManagerState({userOrderItems: [], loadedUserOrderItems: false, userOrderTotal: 0}, () => {
            this.ajax(settings.API_URL + settings.API_ENDPOINT_ORDER_ITEMS + this.props.urlParams.ordersID + '/'+ this.props.urlParams.ordersSha,{},'GET',null, (data) => {
                let userOrderTotal = 0
                for(let i in data.items) {
                    userOrderTotal += data.items[i].ordersItemsQuantity * data.items[i].ordersItemsUnitPrice
                }
                this.props.setManagerState({userOrderItems: data.items, loadedUserOrderItems: true, userOrderTotal: userOrderTotal})
            })
        })
      }
    getRender() {
        let vouchersDiscount = 0
        if (!this.props.getManagerState().emptyUserOrder)
        {
            if (this.props.getManagerState().userOrder[0].ordersVouchersDiscount)
                vouchersDiscount = this.props.getManagerState().userOrder[0].ordersVouchersDiscount
        }
        let classes = {
            root: {
              display: 'block',
              maxWidth: '750px',
              margin: '70px auto',
              padding: '8px',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            img: {
                maxWidth: '150px',
                maxHeight: '150px'
            },
            proceedToCheckout: {
                textDecoration: 'none',
                display: "block",
                margin: "0 5px",
                width: "100%",
                color: 'inherit'
              }
          };
        this.loadComponentsProps()
        let allTotal = (parseInt(this.props.getManagerState().userOrderTotal) + parseInt(this.props.getManagerState().ordersDeliveryMethodsPrice) - parseInt(vouchersDiscount))
        if (allTotal < 0)
        {
            allTotal = 0
        }
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent websiteVisitors={true} {...this.buildComponentProps('AppbarComponent')} />
                    {this.props.getManagerState().loadedUserOrder && !this.props.getManagerState().emptyUserOrder ?
                        <Typography variant="h3" component="h3" style={classes.title}>
                            {this.props.getManagerState().language.LANG_ORDER} 
                            &nbsp;#{this.props.getManagerState().userOrder[0] ? this.props.getManagerState().userOrder[0].ordersID: null} - 
                            &nbsp;{this.props.getManagerState().language[this.props.getManagerState().userOrder[0].ordersStatusesName]}
                        </Typography>
                        :
                        <LoadingComponent />
                    }
                    {this.props.getManagerState().loadedUserOrder && !this.props.getManagerState().emptyUserOrder ?
                        <React.Fragment>
                            <Paper>
                                <Typography variant="h5" component="h5" style={classes.title}>
                                    {this.props.getManagerState().language.LANG_DELIVERY_METHOD} 
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.props.getManagerState().language[this.props.getManagerState().userOrder[0].deliveryMethodsName]} +{(this.props.getManagerState().userOrder[0].ordersDeliveryMethodsPrice/100).toFixed(2)}{this.props.getManagerState().language.LANG_CURRENCY}
                                </Typography>
                            </Paper>
                            <Paper>
                            <Typography variant="h5" component="h5" style={classes.title}>
                                {this.props.getManagerState().language.LANG_ORDERS_PAYMENT_METHOD} 
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {this.props.getManagerState().userOrder[0].ordersPaymentMethod}
                            </Typography>
                            </Paper>                            
                        </React.Fragment>
                        :
                        null
                    }
                    {this.props.getManagerState().loadedUserOrderDeliveryAddress ?
                            <Paper>
                                <Typography variant="h5" component="h5" style={classes.title}>
                                    {this.props.getManagerState().language.LANG_DELIVERY_ADDRESS} 
                                </Typography>
                                <Typography variant="subtitle1" gutterBottom>
                                    {this.props.getManagerState().userOrderDeliveryAddress[0].addressFirstName} {this.props.getManagerState().userOrderDeliveryAddress[0].addressLastName} {this.props.getManagerState().userOrderDeliveryAddress[0].addressStreet} {this.props.getManagerState().userOrderDeliveryAddress[0].addressNumber} {this.props.getManagerState().userOrderDeliveryAddress[0].addressBlock} {this.props.getManagerState().userOrderDeliveryAddress[0].addressEntrance} {this.props.getManagerState().userOrderDeliveryAddress[0].addressFloor} {this.props.getManagerState().userOrderDeliveryAddress[0].addressApartment} {this.props.getManagerState().userOrderDeliveryAddress[0].addressCity} {this.props.getManagerState().userOrderDeliveryAddress[0].countiesName}, {this.props.getManagerState().userOrderDeliveryAddress[0].addressCountry} {this.props.getManagerState().userOrderDeliveryAddress[0].addressPhoneNumber} {this.props.getManagerState().userOrderDeliveryAddress[0].addressObservations}
                                </Typography>
                            </Paper>    
                        :
                        null
                    }
                    {this.props.getManagerState().loadedUserOrderInvoiceAddress && !this.props.getManagerState().emptyUserOrder ?
                            <Paper>
                            <Typography variant="h5" component="h5" style={classes.title}>
                                {this.props.getManagerState().language.LANG_BILLING_ADDRESS} 
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom>
                                {this.props.getManagerState().userOrderInvoiceAddress[0].addressFirstName} {this.props.getManagerState().userOrderInvoiceAddress[0].addressLastName} {this.props.getManagerState().userOrderInvoiceAddress[0].addressStreet} {this.props.getManagerState().userOrderInvoiceAddress[0].addressNumber} {this.props.getManagerState().userOrderInvoiceAddress[0].addressBlock} {this.props.getManagerState().userOrderInvoiceAddress[0].addressEntrance} {this.props.getManagerState().userOrderInvoiceAddress[0].addressFloor} {this.props.getManagerState().userOrderInvoiceAddress[0].addressApartment} {this.props.getManagerState().userOrderInvoiceAddress[0].addressCity} {this.props.getManagerState().userOrderInvoiceAddress[0].countiesName}, {this.props.getManagerState().userOrderInvoiceAddress[0].addressCountry} {this.props.getManagerState().userOrderInvoiceAddress[0].addressPhoneNumber} {this.props.getManagerState().userOrderInvoiceAddress[0].addressObservations}
                            </Typography>
                            </Paper>   
                        :
                        null
                    }
                    {
                        this.props.getManagerState().loadingUserOrders ?
                        <LoadingComponent />
                        :
                        null
                    }
                    {!this.props.getManagerState().emptyUserOrder ?
                    <Typography variant="h5" component="h5" style={classes.title}>
                        {this.props.getManagerState().language.LANG_ORDERS_ITEMS} 
                    </Typography>
                    :
                    null
                    }
                    {
                        this.props.getManagerState().loadedUserOrderItems ?
                        null
                        :
                        <Grid item xl={12} md={12} sm={12}>
                            <LoadingComponent />
                        </Grid>
                    }
                    {this.props.getManagerState().emptyUserOrder ?
                        null :

                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_ORDER_ITEMS_IMAGE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_DETAILS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_RETOUR_ITEM}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    

                      {
                      this.props.getManagerState().userOrderItems.map((el, idx) => {

                            return  <React.Fragment>
                                        <TableRow key={idx}>  
                                            <TableCell>
                                                <img style={classes.img} alt="" src={settings.API_IMAGES_URL + settings.API_ENDPOINT_FILE + el.imagesPath+ '?thumbsWidth=800'} />
                                            </TableCell>
                                            <TableCell>
                                                {this.props.getManagerState().language.LANG_ORDER_ITEMS_VARIANT_ID}: <b>{el.variantsID}</b> <br/>
                                                {this.props.getManagerState().language.LANG_QUANTITY}: <b>{el.ordersItemsQuantity}</b> <br/>
                                                {this.props.getManagerState().language.LANG_PAPER_TYPE}: <b>{this.props.getManagerState().language[el.variantsDimensionsPaperType]}</b> <br/>
                                                {this.props.getManagerState().language.LANG_DIMENSIONS}: <b>{el.variantsWidth}{this.props.getManagerState().language.LANG_UNIT_OF_MEASURE} x {el.variantsHeight}{this.props.getManagerState().language.LANG_UNIT_OF_MEASURE}</b> <br />
                                                {this.props.getManagerState().language.LANG_SUBTOTAL}: <b>{((el.ordersItemsQuantity * el.ordersItemsUnitPrice)/100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</b>
                                            </TableCell>
                                            <TableCell>
                                            {parseInt(el.ordersOrdersStatusesID) ===settings.SETTING_DELIVERED_ORDER_STATUS_ID ?
                                                <UserOrderRetour {...this.buildComponentProps('AppbarComponent')} element={el} />
                                            :
                                                null
                                            }
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                        })
                        }
                        <TableRow>  
                        {!this.props.getManagerState().emptyUserOrder ?
                            <React.Fragment>
                                <TableCell>
                                    {this.props.getManagerState().language.LANG_TOTAL}
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </React.Fragment>
                            :
                            null
                        }
                        {!this.props.getManagerState().emptyUserOrder ?
                        <React.Fragment>
                            <TableCell>
                                <b>{(this.props.getManagerState().userOrderTotal/100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</b>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </React.Fragment>
                         :
                            null
                        }
                        </TableRow>
                        <TableRow>  
                        {!this.props.getManagerState().emptyUserOrder ?
                            <React.Fragment>
                                <TableCell>
                                    {this.props.getManagerState().language.LANG_ORDERS_VOUCHERS_DISCOUNT}
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </React.Fragment>
                            :
                            null
                        }
                        {!this.props.getManagerState().emptyUserOrder ?
                        <React.Fragment>
                            <TableCell>
                                <b>{(vouchersDiscount/100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</b>
                            </TableCell>
                            <TableCell>
                            </TableCell>
                        </React.Fragment>
                         :
                            null
                        }
                        </TableRow>
                        {!this.props.getManagerState().emptyUserOrder ?
                            <TableRow>  
                                <TableCell>
                                    {this.props.getManagerState().language.LANG_TOTAL_ORDER}
                                </TableCell>     
                                <TableCell>
                                </TableCell>                   
                                <TableCell>
                                    <b>{(allTotal/100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}</b>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>  
                            :
                            null
                        }
                            </TableBody>
                        </Table>
                    </TableContainer>
                    }
                        <Grid item xl={12} md={12} sm={12}>
                            <Divider />
                        </Grid>
                    {this.props.getManagerState().emptyUserOrder ?
                         <Typography variant="subtitle1" gutterBottom>
                                {this.props.getManagerState().language.LANG_EMPTY_USER_ORDER}
                            </Typography>      
                        :
                        null}
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
