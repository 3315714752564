import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/OrderItemsElementStyle'
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function EmailsItemComponent(props) {
    const classes = useStyles();
    const [showEditVoucher, setShowEditVoucher]  = React.useState(false)
    const [vouchersCode, setVouchersCode] = React.useState(props.element.vouchersCode)
    const [vouchersValue, setVouchersValue] = React.useState(props.element.vouchersValue)
    const [vouchersType, setVouchersType]  = React.useState(props.element.vouchersType)
    const [vouchersStartDateTime, setVouchersStartDateTime] = React.useState(props.element.vouchersStartDateTime)
    const [vouchersEndDateTime, setVouchersEndDateTime] = React.useState(props.element.vouchersEndDateTime)
    const [vouchersQuantity, setVouchersQuantity] = React.useState(props.element.vouchersQuantity)
    const [vouchersUsages, setVouchersUsages] = React.useState(props.element.vouchersUsages)
    const [errorVouchersCode, setErrorVouchersCode]  = React.useState(false)
    const [errorVouchersValue, setErrorVouchersValue]  = React.useState(false)
    const [errorVouchersType, setErrorVouchersType]  = React.useState(false)
    const [errorVouchersStartDateTime, setErrorVouchersStartDateTime]  = React.useState(false)
    const [errorVouchersEndDateTime, setErrorVouchersEndDateTime]  = React.useState(false)
    const [errorVouchersQuantity, setErrorVouchersQuantity]  = React.useState(false)
    const [errorVouchersUsages, setErrorVouchersUsages]  = React.useState(false)

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }
      const componentProps = {
          ...props.componentProps
      }

      const putVouchersImageAction = () => {

        let errors = []
        if(!vouchersCode) {
            setErrorVouchersCode(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_CODE)
        }
        if(!vouchersValue) {
            setErrorVouchersValue(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_VALUE)
        }

        if(!vouchersType) {
            setErrorVouchersType(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_TYPE)
        }
        let startDateTime = ""
        let endDateTime = ""
        if(!vouchersStartDateTime) {
            setErrorVouchersStartDateTime(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_START_DATE_TIME)
        } else {
            if (vouchersStartDateTime.format) {
                startDateTime = vouchersStartDateTime.format("YYYY-MM-DD HH:mm:ss")
            } else {
                startDateTime = vouchersStartDateTime
            }
        }
        if(!vouchersEndDateTime) {
            setErrorVouchersEndDateTime(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_END_DATE_TIME)
        } else {
            if (vouchersEndDateTime.format) {
                endDateTime = vouchersEndDateTime.format("YYYY-MM-DD HH:mm:ss")
            } else {
                endDateTime = vouchersEndDateTime
            }
        }

        if(!vouchersQuantity) {
            setErrorVouchersQuantity(true)
            errors.push(componentConstants.language.LANG_ERROR_EMPTY_VOUCHERS_QUANTITY)
        }
        if ( errors.length) {
            componentMethods.addError(errors.join(", "));
            return
        }
        
        let putObject = {
            vouchersCode: vouchersCode,
            vouchersValue: vouchersValue,
            vouchersType: vouchersType,
            vouchersStartDateTime: startDateTime,
            vouchersEndDateTime: endDateTime,
            vouchersQuantity: vouchersQuantity,
            vouchersUsages: vouchersUsages
        }
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_VOUCHERS  + '/' + props.element.vouchersID,putObject,'PUT',null, (data) => {
            componentMethods.addError(componentConstants.language.LANG_VOUCHERS_SAVED)
            componentMethods.refreshSearch()
        })
      }
return (<TableRow>
            <TableCell>
                {props.element.emailsID}
            </TableCell>
            <TableCell>
                {showEditVoucher ?
                    <TextField fullWidth={true} value={vouchersCode} error={errorVouchersCode} onChange={(e)=> {setVouchersCode(e.target.value)}}  label={componentConstants.language.LANG_VOUCHERS_CODE} />
                    :
                    <React.Fragment>
                        {props.element.emailsType}
                    </React.Fragment>
                }
            </TableCell>
            <TableCell>
                {showEditVoucher ?
                    <TextField fullWidth={true} value={vouchersValue} error={errorVouchersValue} onChange={(e)=> {setVouchersValue(e.target.value)}}  label={componentConstants.language.LANG_VOUCHERS_VALUE} />
                :
                    <React.Fragment>
                        {props.element.emailsAddedDateTime}
                    </React.Fragment>
                }
            </TableCell>
            <TableCell>
                {props.element.emailsSentDateTime}
            </TableCell>
            <TableCell>
                {props.element.emailsTemplateFile}
            </TableCell>
            <TableCell>
                {showEditVoucher ?
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <InputLabel>{componentConstants.language.LANG_VOUCHERS_TYPE}</InputLabel>
                        <Select
                        error={errorVouchersType}
                        value={vouchersType}
                        onChange={(e)=> {setVouchersType(e.target.value)}}
                        >
                        {Object.keys(Settings.SETTINGS_EMAILS_TYPES).map((el, index) => {
                            return <MenuItem key={index} value={el}>{componentConstants.language[Settings.SETTINGS_EMAILS_TYPES[el]]}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                :
                        <React.Fragment>
                            {componentConstants.language[Settings.SETTINGS_EMAILS_TYPES[props.element.emailsStatus]]}
                        </React.Fragment>
                }

            </TableCell>
            <TableCell>
                {props.element.emailsTitle}
            </TableCell>
            <TableCell>
                {props.element.emailsParams}
            </TableCell>
            <TableCell>
                {props.element.emailsEmailTo}
            </TableCell>
            <TableCell>
                {showEditVoucher ?
                    <IconButton onClick={(e) => {putVouchersImageAction()}}><SaveIcon /></IconButton>                
                :
                    <IconButton onClick={(e) => {setShowEditVoucher(!showEditVoucher)}}><EditIcon /></IconButton>                
                }
            </TableCell>
        </TableRow>
  );
}