import React, { useEffect } from 'react';
import Settings from '../../../Settings/Settings'
import Grid from '@material-ui/core/Grid';
import OrderItemsElement from './OrderItemsElement'
import LoadingComponent from '../../LoadingComponent/LoadingComponent'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export default function OrdersItemsComponent(props) {
    const [orderItems, setOrderItems] = React.useState([])
    const [loadingOrderItems, setLoadingOrderItems] = React.useState(false)
    useEffect(() => {
        getOrderItemsAction()
        }, []);

    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
          getOrderItemsAction: () => {
            getOrderItemsAction()
          }
      }
      const componentProps = {
          ...props.componentProps
      }
      const getOrderItemsAction = () => {
        setOrderItems([])
        setLoadingOrderItems(true)
        componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ORDER_ITEMS + props.ordersID + '/' + props.ordersSha,{},'GET',null, (data) => {
            setLoadingOrderItems(false)
            setOrderItems(data.items)
        })
      }
return (<TableContainer component={Paper}>
        <Table>

                <TableHead>
                    <TableRow>
                        <TableCell>
                        {componentConstants.language.LANG_ORDER_ITEMS_VARIANTS_AND_IMAGE}
                        </TableCell>
                        <TableCell>
                            {componentConstants.language.LANG_QUANTITY}
                        </TableCell>
                        <TableCell>
                            {componentConstants.language.LANG_PAPER_TYPE} <br />
                            {componentConstants.language.LANG_DIMENSION}
                        </TableCell>
                        <TableCell>
                            {componentConstants.language.LANG_ACTIONS}
                        </TableCell>
                    </TableRow>
                </TableHead>
            <TableBody>
            {
          orderItems.map((el, idx) => {
            return <OrderItemsElement key={idx} element={el} componentProps={componentProps} componentConstants={componentConstants} componentMethods={componentMethods} />
                
            })
            }
            </TableBody>
            {
                loadingOrderItems ?
                <Grid item xl={12} md={12} sm={12}>
                    <LoadingComponent />
                </Grid>
                :
                null
            }
        </Table>
        </TableContainer>
  );
}