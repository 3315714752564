import React from 'react';
import { Typography } from '@material-ui/core';
import GDPRSubmissionForm from './GDPRSubmissionForm'

export default function ContactPageComponent(props) {
    
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Politica GDPR pentru Protecția Datelor Personale
            </Typography>
            <Typography variant="body1">
                Această politică descrie modul în care colectăm, utilizăm și protejăm datele personale ale vizitatorilor și utilizatorilor site-ului nostru în conformitate cu Regulamentul General privind Protecția Datelor (GDPR).
            </Typography>
            <Typography variant="h4">
                1. Date colectate
            </Typography>
            <Typography variant="body1">
                Colectăm anumite informații personale de la utilizatori, inclusiv nume, adresă de e-mail, adresă IP și alte date relevante în contextul serviciilor oferite de site-ul nostru.
            </Typography>
            <Typography variant="h4">
                2. Scopul colectării datelor
            </Typography>
            <Typography variant="body1">
                Datele personale sunt colectate în scopul furnizării serviciilor și funcționalităților site-ului nostru, precum și pentru a îmbunătăți experiența utilizatorilor.
            </Typography>
            <Typography variant="h4">
                3. Păstrarea datelor
            </Typography>
            <Typography variant="body1">
                Vom păstra datele personale pe o perioadă strict necesară pentru atingerea scopurilor menționate mai sus sau în conformitate cu cerințele legale.
            </Typography>
            <Typography variant="h4">
                4. Securitatea datelor
            </Typography>
            <Typography variant="body1">
                Luăm măsuri de securitate tehnice și organizatorice pentru a proteja datele personale împotriva accesului neautorizat sau utilizării incorecte.
            </Typography>
            <Typography variant="h4">
                5. Partajarea datelor
            </Typography>
            <Typography variant="body1">
                Nu vom partaja datele personale cu terțe părți fără consimțământul expres al utilizatorilor, cu excepția cazurilor prevăzute de lege.
            </Typography>
            <Typography variant="h4">
                6. Drepturile utilizatorilor
            </Typography>
            <Typography variant="body1">
                Nu vom partaja datele personale cu terțe părți fără consimțământul expres al utilizatorilor, cu excepția cazurilor prevăzute de lege.
            </Typography>
            <Typography variant="h4">
                6. Drepturile utilizatorilor
            </Typography>
            <Typography variant="body1">
                Utilizatorii au dreptul de acces, rectificare, ștergere sau restricționare a prelucrării datelor personale. De asemenea, au dreptul de a se opune prelucrării sau de a solicita portabilitatea datelor.
            </Typography>
            <Typography variant="h4">
                7. Cookie-uri și tehnologii similare
            </Typography>
            <Typography variant="body1">
                Site-ul nostru poate utiliza cookie-uri și alte tehnologii similare pentru a îmbunătăți experiența utilizatorilor și a colecta date statistice anonime.
            </Typography>
            <Typography variant="h4">
                8. Modificări la politică
            </Typography>
            <Typography variant="body1">
                Site-ul nostru poate utiliza cookie-uri și alte tehnologii similare pentru a îmbunătăți experiența utilizatorilor și a colecta date statistice anonime.
            </Typography>
            <Typography variant="body1">
                Această politică poate fi revizuită și actualizată periodic. Orice modificări vor fi publicate pe această pagină.
            </Typography>
            <Typography variant="body1">
                Pentru orice întrebări sau solicitări referitoare la datele personale, vă rugăm să ne contactați la adresa <b>lexie.romania@gmail.com</b> sau folosind formularul de <a href="/contact">contact</a> de pe site.
            </Typography>
            <Typography variant="body1">
                Această politică a fost actualizată la data de 09.08.2023.
            </Typography>
            <GDPRSubmissionForm componentConstants={componentConstants} componentMethods={componentMethods} />
      </React.Fragment>);
}