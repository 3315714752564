import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Style from './Styles/ShoppingCartItemStyle';
import Settings from '../../Settings/Settings'
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteShoppingCartItemDialog from './DeleteShoppingCartItemDialog';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function ShoppingCartItem(props) {
    const [quantity, setQuantity] = React.useState(parseInt(props.element.shoppingCartItemsQuantity));
    const [initialQuantity, setInitialQuantity] = React.useState(parseInt(props.element.shoppingCartItemsQuantity))
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
    const classes = useStyles();
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }

    const handleChangeQuantity = (event) => {
        setQuantity(parseInt(event.target.value))
    }

  const getShoppingCartTotal = () => {
      componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCART_TOTAL + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, {},'GET','addresses', (data) => {
          componentMethods.setManagerState({shoppingCartTotal: data.items.total})
      })
  }

    const putShoppingCartItemAction = () => {
        let url = Settings.API_URL + Settings.API_ENDPOINT_SHOPPINGCARTITEMS + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha + '/' + props.element.shoppingCartItemsID

        componentMethods.setManagerState({loadingShoppingCartItems: true, shoppingCartItems: []}, () => {
            componentMethods.ajax(url, 
                {
                    shoppingCartItemsQuantity: quantity
                }, 
               "PUT", 'shoppingCartItems', 
                (data) => {
                    window.location.reload()
               })
        })
    }

  return (<React.Fragment>
      <DeleteShoppingCartItemDialog setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} element={props.element} componentMethods={componentMethods} componentConstants={componentConstants} componentProps={componentProps} />
      <Grid container spacing={3}>
            <Grid item xs={5} lg={5} md={5}>
                <img alt="" className={classes.shoppingCartItemImg} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.element.imagesPath+ '?thumbsWidth=800' } />
                <Typography component="div" variant="body2" gutterBottom>{props.element.variantsWidth}{componentConstants.language.LANG_UNIT_OF_MEASURE} x {props.element.variantsHeight}{componentConstants.language.LANG_UNIT_OF_MEASURE}</Typography>
                <Typography component="div" variant="subtitle2" gutterBottom>{parseInt(props.element.shoppingCartItemsQuantity) * parseInt(props.element.variantsDimensionsPrice) / 100} {componentConstants.language.LANG_CURRENCY} ({componentConstants.language[props.element.variantsDimensionsPaperType]})</Typography>
                <Typography component="div" variant="subtitle2" gutterBottom>{componentConstants.language.LANG_PRODUCT_CODE}: {props.element.variantsID}</Typography>
            </Grid>
            <Grid item xs={2} lg={2} md={2}>
            <FormControl className={classes.formControl} fullWidth={true}>
                    <InputLabel>{componentConstants.language.LANG_QUANTITY}</InputLabel>
                    <Select
                    value={quantity}
                    onChange={handleChangeQuantity}
                    >
                    {[1,2,3,4,5,6,7,8,9].map((el, index) => {
                    return <MenuItem key={index} value={el}>{el}</MenuItem>
                    })}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={5} lg={5} md={5}>
                <IconButton disabled={initialQuantity === quantity} onClick={putShoppingCartItemAction} color="secondary" component="span">
                    <SaveIcon />
                </IconButton>
                <IconButton color="secondary" component="span" onClick={() => {setOpenDeleteDialog(true) }}>
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
        <Divider variant="middle" className={classes.divider} />
        </React.Fragment>
  );
}