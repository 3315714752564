export default function Style(theme) {
    const style = {
        list: {
          width: 250,
        },
        fullList: {
          width: 'auto',
        },
        link: {
          textDecoration: 'none',
          color: '#000'
        },
        alertsFab: {
          margin: '0 8px'
        },
        menuFab: {
          margin: '0 8px'
        },
        notificationsImg: {
          width: '100%',
          height:'auto',
          display: 'block'
        }
      }
    return style
}