import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/PaymentMethodsComponentStyle';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
const useStyles = makeStyles((theme) => (Style(theme)));

export default function PaymentMethodsComponent(props) {
  const classes = useStyles();
    const setPaymentMethod = (e) => {
        componentMethods.setManagerState({paymentMethod: e.target.value})
    }
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  return (
    <Grid container spacing={3}>
        <Grid item xs={12} lg={12} md={112}>
            <FormControl component="fieldset" className={classes.formControl}>
                <RadioGroup aria-label="paymentMethod" name="paymentMethod" value={componentMethods.getManagerState().paymentMethod} onChange={setPaymentMethod }>
                    <FormControlLabel value={componentConstants.language.PAYMENT_METHOD_RAMBURS_ON_DELIVERY} control={<Radio />} label={componentConstants.language.LANG_PAYMENT_METHOD_RAMBURS} />
                </RadioGroup>
            </FormControl>
        </Grid>
    </Grid>
  );
}