import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from './Styles/SearchOrdersStyle';
import Settings from '../../../Settings/Settings'
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function SearchOrdersComponent(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    
    let yourUrlParamsSearchQuery = ''
    if(componentConstants.urlParams && componentConstants.urlParams.query) {
      yourUrlParamsSearchQuery = componentConstants.urlParams.query
    }
    
    const [ordersQuery, setOrdersQuery] = React.useState(yourUrlParamsSearchQuery)

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
          searchStocks(event)
        }
    }

    const searchStocks = (e) => {
          if(ordersQuery) {
            componentMethods.changeRoute('/stocks/' + ordersQuery)
              componentMethods.getStocks(ordersQuery)
          } else {
            componentMethods.changeRoute('/stocks')
          }
      }

    return   <React.Fragment>
      <Grid item xs={1} lg={4} md={1}></Grid>
    <Grid item xs={10} lg={4} md={10}>
      <Paper component="form" className={classes.root}>
    <InputBase
      className={classes.input}
      placeholder={componentConstants.language.LANG_SEARCH_PRODUCTS + "..."}
      inputProps={{ 'aria-label': componentConstants.language.LANG_SEARCH_PRODUCTS + "..." }}
      value={ordersQuery} 
      onChange={(event)=>{ setOrdersQuery(event.target.value)}}
      onKeyPress={handleKeyPress}
    />
    <IconButton type="button" onClick={searchStocks} className={classes.iconButton} aria-label="search">
      <SearchIcon />
    </IconButton>
    </Paper> </Grid><Grid item xs={1} lg={4} md={1}></Grid>
    </React.Fragment>
}
