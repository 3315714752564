import React, {useEffect} from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Style from '../Styles/LeftMenuDrawerStyle';
import MenuIcon from '@material-ui/icons/Menu';
import ViewListIcon from '@material-ui/icons/ViewList';
import Divider from '@material-ui/core/Divider';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import Settings from '../../../Settings/Settings';
import PhotoAlbumIcon from '@material-ui/icons/PhotoAlbum';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import EuroSymbolIcon from '@material-ui/icons/EuroSymbol';
import BurstModeIcon from '@material-ui/icons/BurstMode';
import Fab from '@material-ui/core/Fab';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Badge from '@material-ui/core/Badge';

import {
    Link
  } from "react-router-dom";

const useStyles = makeStyles((theme) => (Style(theme)));
const AlertItem = (props) => {
  const classes = useStyles();
  if (!props.item)
    return null
  {switch(props.item.alertsJson.alertsType){
    case "like":
      return <div>{
        props.item.alertsJson.fromUsersFirstName ?
          <Link to={'/image/' + props.item.alertsJson.imagesPath} style={{textDecoration: 'none',color: '#000'}}><img className={classes.notificationsImg} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.item.alertsJson.imagesPath + '?thumbsWidth=375'} /> {props.item.alertsJson.fromUsersFirstName + ' ' + props.item.alertsJson.fromUsersLastName} {props.componentMethods.getManagerState().language.LANG_LIKED_YOUR_FOTO}</Link>
          :
          <Link to={'/image/' + props.item.alertsJson.imagesPath} style={{textDecoration: 'none',color: '#000'}}><img className={classes.notificationsImg} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.item.alertsJson.imagesPath + '?thumbsWidth=375'} /> {props.componentMethods.getManagerState().language.LANG_ANONYMOUS} {props.componentMethods.getManagerState().language.LANG_LIKED_YOUR_FOTO}</Link>
      }</div>
      case "comment":
        return <div>{
          props.item.alertsJson.fromUsersFirstName || props.item.alertsJson.fromUsersLastName ?
            <Link to={'/image/' + props.item.alertsJson.imagesPath} style={{textDecoration: 'none',color: '#000'}}><img className={classes.notificationsImg} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.item.alertsJson.imagesPath + '?thumbsWidth=375'} /> {props.item.alertsJson.fromUsersFirstName + ' ' + props.item.alertsJson.fromUsersLastName} {props.componentMethods.getManagerState().language.LANG_COMMENTED_YOUR_FOTO}: {props.item.alertsJson.commentsText}</Link>
            :
            <Link to={'/image/' + props.item.alertsJson.imagesPath} style={{textDecoration: 'none',color: '#000'}}><img className={classes.notificationsImg} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + props.item.alertsJson.imagesPath + '?thumbsWidth=375'} /> {props.componentMethods.getManagerState().language.LANG_ANONYMOUS} {props.componentMethods.getManagerState().language.LANG_COMMENTED_YOUR_FOTO}: {props.item.alertsJson.commentsText}</Link>
        }</div>

      default:
        return null
  }}
}
export default function LeftMenuDrawer(props) {
  const classes = useStyles();
  const [alertsCount, setAlertsCount] = React.useState()
  const [alerts, setAlerts] = React.useState([])
  const [anchor, setAnchor] = React.useState(null)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useEffect(() => {
    getAlertsCountAction()
  }, []);
  useEffect(() => {
    getAlerts()
  }, [state]);
  const getAlertsCountAction = () => {

      let url = Settings.API_URL + Settings.API_ENDPOINT_ALERTS_COUNT
      componentMethods.ajax(url, 
        {}, 
        "GET", null, 
         (data) => {
            setAlertsCount(data.items.count)
            setTimeout(() => {
              getAlertsCountAction()
            }, "10000");
        })  
  }

  const getAlerts = () => {
    if (state.left)
    {
    let url = Settings.API_URL + Settings.API_ENDPOINT_ALERTS
    componentMethods.ajax(url, 
      {}, 
      "GET", null, 
       (data) => {
          setAlerts(data.items)
      })
    }
  }
  const getLastAlertID = () =>
  {
    let lastAlertID = alerts[0]
    for (let i in alerts)
    {
      if (alerts[i].alertsID < lastAlertID)
        lastAlertID = alerts[i].alertsID
    }
    return lastAlertID
  }
  const getOlderAlerts = () => {
    if (state.left)
    {
    let url = Settings.API_URL + Settings.API_ENDPOINT_ALERTS_OLDER  + getLastAlertID()
    componentMethods.ajax(url, 
      {}, 
      "GET", null, 
       (data) => {
          let alertsOld = alerts.concat(data.items)
          setAlerts(alertsOld)
      })
    }
  }
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open)
    {
      getAlerts()
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      {alerts.map((el, index) => {
                        return <React.Fragment><ListItem  key={index}>
                            <AlertItem item={el} componentConstants={props.componentConstants} componentMethods={props.componentMethods}/>
                          </ListItem>
                          <Divider />
                          </React.Fragment>
                        })}
        <ListItem>
          <Button onClick={getOlderAlerts}>{componentConstants.language.LANG_LOAD_MORE_ALERTS}</Button>
        </ListItem>
        <ListItem>
          <Button  onClick={toggleDrawer(anchor, false)}>{componentConstants.language.LANG_CLOSE}</Button>
        </ListItem>
      </List>
    </div>
  );

  return (<React.Fragment>
    {componentMethods.hasExactAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) ?
    <div>

      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
            <Fab color="secondary" aria-label="add" className={classes.alertsFab}  onClick={toggleDrawer(anchor, true)}>
            <Badge badgeContent={alertsCount} color="primary">
              <NotificationsIcon />
            </Badge>

            </Fab>
            <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
              {list(anchor)}
            </Drawer>
        </React.Fragment>
      ))}
    </div>
    : 
      null
    }
    </React.Fragment>
  );
}