import React from 'react';
import { Typography } from '@material-ui/core';

export default function TermsAndConditionsPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
                Termeni și condiții pentru site-ul Lexie Art
            </Typography>
            <Typography variant="h4">
              1. Acceptarea termenilor și condițiilor
            </Typography>
            <Typography variant="body1">
              Vă rugăm să citiți cu atenție acești termeni și condiții înainte de a utiliza site-ul &quot;Lexie Art&quot;. Prin
              accesarea și utilizarea acestui site, sunteți de acord să fiți obligat de termenii și condițiile de
              utilizare. Dacă nu sunteți de acord cu acești termeni, vă rugăm să nu utilizați site-ul.
              <Typography variant="h4">
              2. Drepturile de autor și proprietatea intelectuală
              </Typography>
                Toate drepturile de autor și drepturile de proprietate intelectuală asupra conținutului și imaginilor
                de pe site-ul &quot;LexieArt&quot; aparțin în întregime sitului sau titularilor licențelor respective. Orice
                utilizare, inclusiv copierea, reproducerea sau distribuirea conținutului fără acordul scris al
                &quot;Lexie Art&quot; este strict interzisă.
            </Typography>
            <Typography variant="h4">
              3. Produse și Servicii
            </Typography>
            <Typography variant="body1">
              Site-ul &quot;Lexie Art&quot; oferă produse de artă fotografică și tablouri canvas destinate vânzării. Ne
              străduim să prezentăm imagini cât mai precise și descrieri detaliate ale produselor noastre. Cu
              toate acestea, nu putem garanta că culorile și detaliile prezentate pe site vor fi 100% conforme
              cu produsele reale, datorită diferențelor în afișarea culorilor pe ecranele diferitelor dispozitive.
            </Typography>
            <Typography variant="h4">
              4. Comenzi și Plată
            </Typography>
            <Typography variant="body1">
              Prin plasarea unei comenzi pe site-ul &quot;Lexie Art&quot;, confirmați că sunteți eligibil să efectuați
              această tranzacție și vă angajați să plătiți suma specificată pentru produsul sau produsele
              selectate. Toate plățile se vor efectua în moneda specificată pe site și pot fi efectuate utilizând
              metodele de plată acceptate de &quot;Lexie Art&quot;.
            </Typography>
            <Typography variant="h4">
              5. Livrarea și Retururile
            </Typography>
            <Typography variant="body1">
              Vă rugăm să consultați pagina de Livrare și Returnare de pe site-ul &quot;Lexie Art&quot; pentru informații
              complete cu privire la politica noastră de livrare și procedura de returnare a produselor.
            </Typography>
            <Typography variant="h4">
              6. Confidențialitatea datelor
            </Typography>
            <Typography variant="body1">
                Protejarea datelor dumneavoastră personale este o prioritate pentru noi. Vă rugăm să citiți
                Politica noastră de Confidențialitate pentru a înțelege cum colectăm, utilizăm și protejăm datele
                dumneavoastră personale.
            </Typography>
            <Typography variant="h4">
              7. Comunicări și Feedback
            </Typography>
            <Typography variant="body1">
            Prin utilizarea site-ului &quot;Lexie Art&quot;, sunteți de acord să primiți comunicări de la noi, inclusiv
              buletine informative, oferte speciale și actualizări ale site-ului. Dacă doriți să nu mai primiți astfel
              de comunicări, puteți renunța la acestea prin intermediul link-urilor de dezabonare furnizate în
              comunicările noastre.
            </Typography>
            <Typography variant="h4">
              8. Modificări ale Termenilor și Condițiilor
            </Typography>
            <Typography variant="body1">
              &quot;Lexie Art&quot; își rezervă dreptul de a modifica sau actualiza acești termeni și condiții în orice
              moment, fără notificare prealabilă. Orice modificări vor fi postate pe această pagină și vor intra
              în vigoare imediat după publicare. Vă recomandăm să verificați periodic această pagină pentru
              a fi la curent cu orice modificări.
          
            </Typography>
            <Typography variant="h4">
              9. Litigii și Jurisdicție
            </Typography>
            <Typography variant="body1">
              Orice litigiu legat de utilizarea site-ului &quot;Lexie Art&quot; va fi supus legilor din România și va fi
              soluționat în mod amiabil, iar în caz contrar, competența jurisdicțională va aparține instanțelor
              competente din România.
              </Typography>
            <Typography variant="body1">
              Data intrării în vigoare: 27.07.2023
            </Typography>
      </React.Fragment>);
}