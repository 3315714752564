import React from 'react';
import Settings from '../../Settings/Settings'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteAddressDialog from './DeleteAddressDialog';

const useStyles = makeStyles((theme) => ({
    formControl: {
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    quantityInput: {
        margin: theme.spacing(1)
    },
    rootTotal: {
        ...theme.typography.button,
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(1),
        display:"inline-block"
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '',
      },
      rootAddress: {
        minWidth: 350
      }
  }));

export default function AddressComponent(props) {
    const classes = useStyles();
    const [addressSha, setAddressSha] = React.useState(props.element.addressSha)
    const [addressStreet, setAddressStreet] = React.useState(props.element.addressStreet)
    const [errorAddressStreet, setErrorAddressStreet] = React.useState(false)
    const [addressNumber, setAddressNumber] = React.useState(props.element.addressNumber)
    const [addressBlock, setAddressBlock] = React.useState(props.element.addressBlock)
    const [addressEntrance, setAddressEntrance] = React.useState(props.element.addressEntrance)
    const [addressFloor, setAdressFloor] = React.useState(props.element.addressFloor)
    const [addressApartment, setAddressApartment] = React.useState(props.element.addressApartment)
    const [addressCity, setAddressCity] = React.useState(props.element.addressCity)
    const [errorAddressCity, setErrorAddressCity] = React.useState(false)
    const [addressCountiesID, setAddressCountiesID] = React.useState(props.element.addressCountiesID)
    const [errorAddressCountiesID, setErrorAddressCountiesID] = React.useState(false)
    const [addressCountry, setAddressCountry] = React.useState("RO")
    const [addressObservations, setAddressObservations] = React.useState(props.element.addressObservations)
    const [addressPhoneNumber, setAddressPhoneNumber] = React.useState(props.element.addressPhoneNumber)
    const [errorAddressPhoneNumber, setErrorAddressPhoneNumber] = React.useState(false)
    const [addressFirstName, setAddressFirstName] = React.useState(props.element.addressFirstName)
    const [errorAddressFirstName, setErrorAddressFirstName] = React.useState(false)
    const [addressLastName, setAddressLastName] = React.useState(props.element.addressLastName)
    const [errorAddressLastName, setErrorAddressLastName] = React.useState(false)
    const [errorAddressNumberAndBlock, setErrorAddressNumberAndBlock] = React.useState(false)
    const [showAddForm, setShowAddForm] = React.useState(false)
    const [showEditForm, setShowEditForm] = React.useState(false)
    const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false)
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
          deleteAddressAction : () => {
            componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_ADDRESS +'/'+ props.element.addressSha,{},'DELETE',null, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_DELETED); componentMethods.getUserAddresses()}, {}, false, (data) => {componentMethods.addError(componentConstants.language.LANG_ADDRESS_NOT_DELETED)})
        }
      }
    
      const componentProps = {
          ...props.componentProps
      }

      const saveAddressAction = () => {
        setErrorAddressStreet(false)
        setErrorAddressFirstName(false)
        setErrorAddressLastName(false)
        setErrorAddressCity(false)
        setErrorAddressPhoneNumber(false)
        setErrorAddressCountiesID(false)
        setErrorAddressNumberAndBlock(false)
        let method = "POST"
        let url = Settings.API_URL + Settings.API_ENDPOINT_ADDRESS

        if(addressSha) {
            url = Settings.API_URL + Settings.API_ENDPOINT_ADDRESS + '/' + addressSha
            method = "PUT"
        } 

        componentMethods.setManagerState({loadingAddresses: true, addresses: {items: []}}, () => {
            let errors = []
            if(!addressStreet) {
                setErrorAddressStreet(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_ADDRESS_STREET)
            }
            if(!addressFirstName) {
                setErrorAddressFirstName(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_ADDRESS_FIRST_NAME)
            }

            if(!addressLastName) {
                setErrorAddressLastName(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_ADDRESS_LAST_NAME)
            }

            if(!addressCity) {
                setErrorAddressCity(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_ADDRESS_CITY)
            }

            if(!addressPhoneNumber) {
                setErrorAddressPhoneNumber(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_PHONE_NUMBER)
            }

            if(!addressCountiesID) {
                setErrorAddressCountiesID(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_COUNTIES)
            }
            if(!addressNumber && !addressBlock) {
                setErrorAddressNumberAndBlock(true)
                errors.push(componentConstants.language.LANG_ERROR_EMPTY_BLOCK_AND_NUMBER)
            }
            if ( errors.length) {
                componentMethods.addError(errors.join(", "));
                return
            }
            let requestObject =  {
                addressStreet: addressStreet,
                addressCity: addressCity,
                addressCountiesID: addressCountiesID,
                addressCountry: addressCountry,
                addressPhoneNumber: addressPhoneNumber,
                addressFirstName: addressFirstName,
                addressLastName: addressLastName
            }
            if (addressNumber) {
                requestObject.addressNumber = addressNumber
            }
            if (addressBlock) {
                requestObject.addressBlock = addressBlock
            }
            if(addressEntrance) {
                requestObject.addressEntrance = addressEntrance
            }
            if(addressFloor) {
                requestObject.addressFloor = addressFloor
            }
            if (addressApartment) {
                requestObject.addressApartment = addressApartment
            }
            if(addressObservations) {
                requestObject.addressObservations = addressObservations
            }

            componentMethods.ajax(url, 
               requestObject, 
               method, 'addresses', 
                (data) => {
                   componentMethods.addError(componentConstants.language.LANG_SUCCESSFULY_SAVED_ADDRESS);
                   componentMethods.getUserAddresses()
                   if(!componentMethods.getLocalStorage('user')) {
                       let addresses = componentMethods.getLocalStorage('addresses');
                       if(!addresses) 
                            addresses = []
                       addresses.push(data.items[0].addressSha);
                       componentMethods.saveLocalStorage(addresses, 'addresses')
                       componentMethods.getAnonymousAddresses()
                   }
                   if(props.addAddress) {
                    clearAddress()
                    setShowAddForm(false)
                   }
                   if(componentMethods.postSaveAddress) {
                       componentMethods.postSaveAddress(data)
                   }
               })
        })
      }
      const clearAddress = () => {
        setAddressSha(null)
        setAddressStreet(null)
        setErrorAddressStreet(false)
        setAddressNumber(null)
        setAddressBlock(null)
        setAddressEntrance(null)
        setAdressFloor(null)
        setAddressApartment(null)
        setAddressCity(null)
        setErrorAddressCity(false)
        setAddressCountiesID(null)
        setErrorAddressCountiesID(false)
        setAddressObservations(null)
        setAddressPhoneNumber(null)
        setErrorAddressPhoneNumber(false)
        setAddressFirstName(null)
        setErrorAddressFirstName(false)
        setAddressLastName(null)
        setErrorAddressLastName(false)
        setErrorAddressNumberAndBlock(false)
      }
      if(!showAddForm && props.addAddress) {
          return <Button color="primary" onClick={() => {setShowAddForm(true)}} fullWidth component="div" variant="contained" startIcon={<LocationCityIcon />}>
                        {componentConstants.language.LANG_ADD_ADDRESS}
                    </Button>
      }
      if(!showEditForm && props.editAddress) {
          return <div>
              <Divider style={{margin: '8px 0'}} />
              <Typography variant="subtitle1" gutterBottom>
              {props.showAddressPrefixes?
                    <React.Fragment>
                        {componentConstants.language.LANG_ADDRESS_FIRST_NAME}: <b>{addressFirstName}</b> <br />
                        {componentConstants.language.LANG_ADDRESS_LAST_NAME}: <b>{addressLastName}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_STREET}: <b>{addressStreet}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_NUMBER}: <b>{addressNumber}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_BLOCK}: <b>{addressBlock}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_ENTRANCE}: <b>{addressEntrance}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_FLOOR}: <b>{addressFloor}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_APARTMENT}: <b>{addressApartment}</b> <br /> 
                        {componentConstants.language.LANG_ADDRESS_CITY}: <b>{addressCity}</b>  <br />
                        {componentConstants.language.LANG_COUNTY}: <b>{props.element.countiesName}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_COUNTRY}: <b>{addressCountry}</b>  <br />
                        {componentConstants.language.LANG_ADDRESS_PHONE_NUMBER}: <b>{addressPhoneNumber}</b> <br /> 
                        {componentConstants.language.LANG_ADDRESS_OBSERVATIONS}: <b>{addressObservations}</b> <br />
                    </React.Fragment>
                :
                    <React.Fragment>
                        {addressFirstName} {addressLastName}  {addressStreet}  {addressNumber} {addressBlock} {addressEntrance} {addressFloor} {addressApartment} {addressCity} {props.element.countiesName}, {addressCountry} {addressPhoneNumber} {addressObservations} <br />
                    </React.Fragment>
                }
                </Typography>
              <Button color="secondary" onClick={() => {setShowEditForm(true)}} fullWidth component="div" variant="outlined" startIcon={<LocationCityIcon />}>
                    {componentConstants.language.LANG_EDIT_ADDRESS}
                </Button>
          </div>
      }
  return (<div className={classes.rootAddress}><React.Fragment>
            {
            props.editAddress ?
                <DeleteAddressDialog addressSha={props.element.addressSha} componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} setOpenDeleteDialog={setOpenDeleteDialog} openDeleteDialog={openDeleteDialog} />
            :
                null
            }
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={addressFirstName} error={errorAddressFirstName} onChange={(e)=> {setAddressFirstName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_FIRST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={addressLastName} error={errorAddressLastName} onChange={(e)=> {setAddressLastName(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_LAST_NAME} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={addressStreet} error={errorAddressStreet} onChange={(e)=> {setAddressStreet(e.target.value)}}  label={componentConstants.language.LANG_ADDRESS_STREET} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <TextField fullWidth={true} value={addressNumber} error={errorAddressNumberAndBlock} onChange={(e)=> {setAddressNumber(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_NUMBER} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <TextField fullWidth={true} value={addressBlock} error={errorAddressNumberAndBlock} onChange={(e)=> {setAddressBlock(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_BLOCK} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <TextField fullWidth={true} value={addressEntrance} onChange={(e)=> {setAddressEntrance(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_ENTRANCE} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <TextField fullWidth={true} value={addressFloor} onChange={(e)=> {setAdressFloor(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_FLOOR} />
                </Grid>
                <Grid item xs={3} md={3} lg={3}>
                    <TextField fullWidth={true} value={addressApartment} onChange={(e)=> {setAddressApartment(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_APARTMENT} />
                </Grid>
                <Grid item xs={9} md={9} lg={9}>
                    <TextField fullWidth={true} value={addressCity} error={errorAddressCity} onChange={(e)=> {setAddressCity(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_CITY} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <FormControl className={classes.formControl} fullWidth={true}>
                        <InputLabel>{componentConstants.language.LANG_COUNTY}</InputLabel>
                        <Select
                        error={errorAddressCountiesID}
                        value={addressCountiesID}
                        onChange={(e)=> {setAddressCountiesID(e.target.value)}}
                        >
                        {componentMethods.getManagerState().counties.items.map((el, index) => {
                        return <MenuItem key={index} value={el.countiesID}>{el.countiesName}</MenuItem>
                        })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={addressPhoneNumber} error={errorAddressPhoneNumber} onChange={(e)=> {setAddressPhoneNumber(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_PHONE_NUMBER} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                    <TextField fullWidth={true} value={addressObservations} onChange={(e)=> {setAddressObservations(e.target.value)}} label={componentConstants.language.LANG_ADDRESS_OBSERVATIONS} />
                </Grid>
                {props.cannotEditAddress ?
                    null
                :
                <Grid item xs={12} md={12} lg={12}>
                    <Button color="secondary" onClick={saveAddressAction} fullWidth component="div" variant="contained" startIcon={<LocationCityIcon />}>
                        {componentConstants.language.LANG_SAVE_ADDRESS}
                    </Button>
                </Grid> 
                }
                
                {
                props.addAddress ?
                    <Grid item xs={12} md={12} lg={12}>
                        <Button color="secondary" onClick={() => {setShowAddForm(false)}} fullWidth component="div" variant="outlined" startIcon={<LocationCityIcon />}>
                            {componentConstants.language.LANG_CANCEL_ADD_ADDRESS}
                        </Button>
                    </Grid>
                :
                    null
                }
                {
                props.editAddress ?
                    <React.Fragment>
                        <Grid item xs={12} md={12} lg={12}>
                        <Button fullWidth={true} variant="contained" color="primary" className={classes.buttonDelete} onClick={(e)=>{setOpenDeleteDialog(true)}} startIcon={<DeleteIcon />}>
                            {props.componentConstants.language.LANG_DELETE}
                        </Button>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Button color="secondary" onClick={() => {setShowEditForm(false)}} fullWidth component="div" variant="outlined" startIcon={<LocationCityIcon />}>
                                {componentConstants.language.LANG_CANCEL_EDIT_ADDRESS}
                            </Button>
                        </Grid>
                    </React.Fragment>
                :
                    null
                }
        </Grid>
    </React.Fragment>
    </div>
  );
}