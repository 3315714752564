export default function Style(theme) {
    const style = {
        img: {
            maxWidth: '100px',
            maxHeight: '100px'
        },
        button: {
            margin: '8px'
        }
    }
    return style
}