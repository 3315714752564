import React from 'react';
import { Typography } from '@material-ui/core';

export default function TermsAndConditionsPageComponent(props) {
    
  return (<React.Fragment>
            <Typography variant="h3" component="h3">
              Politica de Retur pentru Tablouri
            </Typography>
            <Typography variant="h4">
              1. Dreptul la Retur
            </Typography>
            <Typography variant="body1">
              1.1. Clientul are dreptul să returneze produsul achiziționat de pe site-ul &quot;Lexie Art&quot; în termen de
              30 de zile de la primirea comenzii, fără a fi nevoie să
              furnizeze motive sau să plătească penalități.
            </Typography>
            <Typography variant="h4">
              2. Condiții pentru Retur
            </Typography>
            <Typography variant="body1">
              2.1. Pentru a fi eligibil pentru retur, produsul trebuie să fie în aceeași stare în care a fost livrat și
              să nu prezinte semne de uzură sau deteriorare. Tablourile canvas personalizate sau create la
              comandă pot avea restricții suplimentare privind returul, dacă nu există defecte sau erori din
              partea producătorului.
            </Typography>
            <Typography variant="h4">
              3. Procedura de Retur
            </Typography>
            <Typography variant="body1">
              3.1. Pentru a solicita un retur, clientul trebuie să contacteze echipa de suport a site-ului
              &quot;Lexie Art&quot; prin intermediul detaliilor de contact furnizate pe site. Clientul va furniza informații
              despre comandă și motivele returnării.
            </Typography>
            <Typography variant="body1">
              3.2. Dacă returul este acceptat, clientul va primi instrucțiuni cu privire la modul de returnare a
              produsului. Cheltuielile de transport pentru retur vor fi suportate de către client, cu excepția
              situațiilor în care produsul este defect sau au apărut erori din partea producătorului.
            </Typography>
            <Typography variant="h4">
              4. Inspectarea Produsului Returnat
            </Typography>
            <Typography variant="body1">
              4.1. După primirea produsului returnat, echipa &quot;Lexie Art&quot; va inspecta starea acestuia pentru a
              verifica îndeplinirea condițiilor de retur menționate mai sus.
            </Typography>
            <Typography variant="body1">
              4.2. În cazul în care produsul returnat este acceptat, &quot;Lexie Art&quot; va efectua rambursarea
              contravalorii produsului, inclusiv costurile de transport inițiale plătite de client la efectuarea
              comenzii. Rambursarea se va efectua folosind aceeași metodă de plată utilizată de client la
              achiziționarea produsului, în termen de 14 zile de la primirea produsului returnat.
            </Typography>
            <Typography variant="body1">
              4.3. În cazul în care produsul returnat nu îndeplinește condițiile de retur, echipa &quot;Lexie Art&quot; își
              rezervă dreptul de a refuza returul și de a returna produsul clientului, cu costurile de transport
              suportate de acesta.
            </Typography>
            <Typography variant="h4">
              5. Excepții
            </Typography>
            <Typography variant="body1">
              5.1. În cazul în care produsul achiziționat de pe site-ul &quot;LexieArt&quot; este defect sau prezintă erori
              din partea producătorului, se aplică politica de garanție și returnare specifică pentru astfel de
              situații. Clientul va fi asistat în obținerea înlocuirii sau rambursării în astfel de cazuri.
            </Typography>
            <Typography variant="h4">
              6. Contact
            </Typography>
            <Typography variant="body1">
              6.1. Pentru orice întrebări, nelămuriri sau asistență suplimentară, vă rugăm să ne contactați prin
              intermediul detaliilor de <a href="/contact">contact</a> furnizate pe site.
            </Typography>
      </React.Fragment>);
}