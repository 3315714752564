import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import Theme from '../../../../Themes/ThemeArtLexie'
import settings from '../../Settings/Settings';
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Button from '@material-ui/core/Button';
import LoadingComponent from '../LoadingComponent/LoadingComponent'
import Pagination from '../Pagination/PaginationComponent'
import Typography from '@material-ui/core/Typography';
export default class RewardsComponent extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.basicComponentConstants = {
            language: props.getManagerState().language,
            history: props.history,
            user: props.getManagerState().user,
        }
    }

    componentDidMount() {
        this.getUserAffiliatesPaymentsAction()
        this.getUserAffiliatesInfo()
        super.componentDidMount()
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            ...this.basicComponentConstants
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        })
    }

    changePage(page) {
        let endpoint = settings.API_ENDPOINT_AFFILIATES_PAYMENTS
        endpoint += '?page=' + page
        this.getSearchAffiliatesPayments(endpoint)
    }
    getUserAffiliatesInfo() 
    {
        let endpoint = settings.API_ENDPOINT_USER_AFFILIATES_INFO
        this.props.setManagerState({affiliatesInfo: [], loadingUsersAffiliatesInfo: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({affiliatesInfo: data.items, loadingUsersAffiliatesInfo: false})
            })
        })
    }
    getUserAffiliatesPaymentsAction() {
        let that = this
        let endpoint = settings.API_ENDPOINT_AFFILIATES_PAYMENTS
        if(this.props.urlParams && parseInt(this.props.urlParams.page) > 1) {
            endpoint += '?page=' + this.props.urlParams.page
        }
        this.props.setManagerState({affiliatesPayments: [], loadingAffiliatesPayments: true}, () => {
            that.getSearchAffiliatesPayments(endpoint)
        })
    }


    getSearchAffiliatesPayments(endpoint) {
        this.props.setManagerState({affiliatesPayments: [], currentPagination: null, loadingAffiliatesPayments: true}, () => {
            this.ajax(settings.API_URL + endpoint,{},"GET", null, (data) => {
                this.props.setManagerState({affiliatesPayments: data.items,currentPagination: data.pagination, loadingAffiliatesPayments: false})
            })
        })
    }

    getRender() {

        let classes = {
            root: {
              display: 'block',
              margin: '70px auto',
              padding: 8,
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              },
            },
            img: {
                maxWidth: '150px',
                maxHeight: '150px'
            },
          };

        this.loadComponentsProps()

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('affiliates')
        paginationPattern[1] = '/page/{{page}}'+ paginationPattern[1]
        paginationPattern = paginationPattern.join('affiliates')
        return <div style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                    {this.props.getManagerState().affiliatesInfo.length ?
                        <Paper>
                            <Typography>{this.props.getManagerState().language.LANG_AFFILIATES_LINK}</Typography>
                            <Typography variant="subtitle2">{settings.SETTING_SITE_URL}?affiliate={this.props.getManagerState().affiliatesInfo[0].affiliatesString}</Typography>
                            <Button onClick={() => {
                                    navigator.clipboard.writeText(settings.SETTING_SITE_URL + '?affiliate=' + this.props.getManagerState().affiliatesInfo[0].affiliatesString)
                                    this.addErrorMain(this.props.getManagerState().language.LANGUAGE_LINK_COPPIED_TO_CLIPBOARD)
                    }}>{this.props.getManagerState().language.LANG_COPY_AFFILIATE_LINK}</Button>
                        </Paper>
                        :
                        null
                    }
                    <Typography variant="h4" component="h4" style={classes.title}>
                        {this.props.getManagerState().language.LANG_PAYMENTS} 
                    </Typography>
                    <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="customized table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_ID}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_DATE}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_STATUS}</TableCell>
                                    <TableCell>{this.props.getManagerState().language.LANG_PAYMENTS_AMOUNT}</TableCell>
                                </TableRow>
                            </TableHead>
                        <TableBody>
                            {this.props.getManagerState().affiliatesPayments.map((el, idx) => [
                        <TableRow key={idx}>
                            <TableCell padding="checkbox">
                                {el.affiliatesPaymentsID}
                            </TableCell>
                            <TableCell padding="checkbox">
                                {el.affiliatesPaymentsYearMonth}
                            </TableCell>
                            <TableCell style={{color: el.affiliatesPaymentsStatusesID === "1" ?"red":"green"}}>
                                {this.props.getManagerState().language[el.paymentsStatusesName]}
                            </TableCell>
                            <TableCell>
                                {(parseInt(el.affiliatesPaymentsAmount) / 100).toFixed(2)} {this.props.getManagerState().language.LANG_CURRENCY}
                            </TableCell>
                        </TableRow>
                    ])}
                    </TableBody>
                    </Table>
                    </TableContainer>
                    {
                        this.props.getManagerState().loadingAffiliatesPayments ?
                        <LoadingComponent />
                        :
                        null
                    }
                    <Pagination  {...this.buildComponentProps('Pagination')} pattern={paginationPattern} pagination={this.props.getManagerState().currentPagination} />
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}
