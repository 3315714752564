import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../Styles/PaymentMethodsComponentStyle';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import settings from '../../../Settings/Settings';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function VoucherComponent(props) {
  const classes = useStyles();
    const [voucherCode, setVoucherCode] = React.useState("")
   
    useEffect(() => {
      getShoppingCartVoucher()
    }, []);

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }
    const postVouchersShoppingCartCode = () => {

      let requestObject = {
        vouchersCode: voucherCode
      }
      componentMethods.ajax(settings.API_URL  + settings.API_ENDPOINT_SHOPPING_CART_VOUCHER + '/' + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, 
        requestObject, 
        "POST", null, 
        (data) => {
          window.location.hash = 'voucher'
          window.location.reload()
        },{}, false, () => {
          componentMethods.addError(componentConstants.language.LANG_VOUCHERS_IS_INVALID)
        })       
    }
    const deleteVoucher = () => {

      componentMethods.ajax(settings.API_URL  + settings.API_ENDPOINT_SHOPPING_CART_VOUCHER + '/' + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, 
      {}, 
      "DELETE", null, 
      (data) => {
        componentMethods.addError(componentConstants.language.LANG_DELETED_VOUCHER)
        componentMethods.setManagerState({voucherShoppingCart: null})
        var hash = window.location.hash;
        window.location.hash = '';
        window.location.hash = hash;
      },{}, false, () => {
        componentMethods.addError(componentConstants.language.LANG_COULD_NOT_DELETE_VOUCHER)
      })   
    }
    const checkVoucherValidity = (voucher) => {
      componentMethods.ajax(settings.API_URL  + settings.API_ENDPOINT_VOUCHER_VALID + '/' + voucher.vouchersID, 
        {}, 
        "GET", null, 
        (data) => {
            componentMethods.setManagerState({voucherShoppingCart: voucher})
        },{}, false, () => {
          deleteVoucher()
        })       
    }
    const getShoppingCartVoucher = () => {

      componentMethods.ajax(settings.API_URL  + settings.API_ENDPOINT_SHOPPING_CART_VOUCHER + '/' + componentMethods.getLocalStorage('shoppingCart').shoppingCartSha, 
        {}, 
        "GET", null, 
        (data) => {
          if (data.items.length > 0)
          {
            checkVoucherValidity(data.items[0])
          }
        })       
    }
  return (
    <Grid container spacing={3}>
        {componentMethods.getManagerState().voucherShoppingCart ?
          <React.Fragment>
            <Grid item xs={12} lg={12} md={112}>
              {componentMethods.getManagerState().voucherShoppingCart.vouchersCode} -{((componentMethods.getManagerState().voucherShoppingCart.shoppingCartVouchersDiscount)/100).toFixed(2)} {componentConstants.language.LANG_CURRENCY}
            </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <Button color="secondary" onClick={() => {deleteVoucher()}} fullWidth component="div" variant="outlined" startIcon={<DeleteForeverIcon />}>
                  {componentConstants.language.LANG_DELETE_VOUCHER}
              </Button>
            </Grid>
          </React.Fragment>
        :
          <React.Fragment>
            <Grid item xs={12} lg={12} md={112}>
              <FormControl component="fieldset" className={classes.formControl}>
                  <TextField fullWidth id="standard-basic" label={componentConstants.language.LANG_VOUCHERS_CODE} aria-label="voucherCode" name="voucherCode" value={voucherCode} onChange={(e) => {setVoucherCode(e.target.value)}} />
              </FormControl>
          </Grid>
            <Grid item xs={12} lg={12} md={12}>
              <Button color="secondary" onClick={() => {postVouchersShoppingCartCode()}} fullWidth component="div" variant="outlined" startIcon={<LocalAtmIcon />}>
                  {componentConstants.language.LANG_ADD_VOUCHER}
              </Button>
            </Grid>          
          </React.Fragment>
        }
        
    </Grid>
  );
}