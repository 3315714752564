import React, {useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Style from '../AppbarComponent/Styles/UploadPaperStyle';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Settings from '../../Settings/Settings';
import $ from "jquery";
import CircularProgress from '@material-ui/core/CircularProgress';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import Badge from '@material-ui/core/Badge';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles((theme) => (Style(theme)));

export default function UsersUploadProfilePicture(props) {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = React.useState(false);
  const [showUploadProfilePictureForm, setShowUploadProfilePictureForm] = React.useState(false)
  useEffect(() => {
    getProfilePicture()
  }, [])
    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const chooseImage = () => {
        document.getElementById('UPLOAD_PROFILE_IMAGE_FILE').click()
    }
    const onChangeImage = () => {
        const [file] = document.getElementById('UPLOAD_PROFILE_IMAGE_FILE').files
        if (file) {
            document.getElementById('UPLOAD_IMAGE_PREVIEW').src = URL.createObjectURL(file)
        }
    }
    const clearForm = () => {
        $('#UPLOAD_PROFILE_IMAGES').trigger("reset");
    }
      
 const getProfilePicture = (force = false) => {
    componentMethods.ajax(Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_SETTINGS + '/' + componentMethods.getLocalStorage("user").tokensUsersID  + '/profilePicture', {},'GET',null, (data) => {
        if (data.items.length > 0)
        {
            setProfilePicture(data.items[0].usersProfileSettingsValue)
        }
      })
}
    const uploadProfileImage = (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);
        $.ajax({
            url: Settings.API_URL + Settings.API_ENDPOINT_USERS_PROFILE_PICTURE,
            headers:{
                "X-Account-Token": componentMethods.getLocalStorage("user").tokensHash
            },
            type: 'POST',
            data: formData,
            success: function (data) {
                componentMethods.setManagerState({uploadingImage: false}, () => {
                clearForm()
                })
            },
            error: function (request, status, error) {
              componentMethods.addError(componentConstants.language["LANG_ERROR_COULD_NOT_LOAD_IMAGE"], {uploadingImage: false})
            },
            cache: false,
            contentType: false,
            processData: false
        });
        componentMethods.setManagerState({uploadingImage: true})
    }
    if(componentMethods.getManagerState().uploadingImage) {
        return <div className={classes.root}>
                    <CircularProgress />{componentConstants.language["LANG_LOADING"]}
                </div>
    }
  return (
    <form className={classes.root} id={'UPLOAD_PROFILE_IMAGES'} noValidate autoComplete="off" onSubmit={(e) => {uploadProfileImage(e)}}>
        <Avatar style={{margin:'0 auto'}} src={Settings.API_IMAGES_URL + Settings.API_ENDPOINT_FILE + profilePicture+ '?thumbsWidth=375'} className={classes.profilePicture} />
        <div style={{position: "absolute", margin: "25% auto", left: "75%"}}>
        <IconButton onClick={() => {setShowUploadProfilePictureForm(!showUploadProfilePictureForm)}}>
            <EditIcon />
        </IconButton>
            </div>
        {showUploadProfilePictureForm?
        <React.Fragment>
            <Typography variant="h5" component="h5" className={classes.title}>
            {componentConstants.language.LANG_MAKE_PROFILE_PICTURE}
            </Typography>
            <input
            onChange={onChangeImage}
            accept="image/jpeg"
            className={classes.input}
            style={{ display: 'none' }}
            name={'imageFile'}
            id="UPLOAD_PROFILE_IMAGE_FILE"
            multiple
            type="file"
            />
            <img alt="" src="" id="UPLOAD_IMAGE_PREVIEW" className={classes.uploadImagePreview} />
            <Button onClick={chooseImage} variant="contained" disableElevation color="primary" fullWidth={true} className={classes.closeButton}>
                {componentConstants.language.LANG_CHOOSE_PROFILE_PICTURE}
            </Button>

            <Button type={"submit"} variant="contained" disableElevation color="secondary" fullWidth={true} className={classes.closeButton}>
                {componentConstants.language.LANG_UPLOAD}
            </Button>

        </React.Fragment>
    :
    null}
        
    </form>
  );
}