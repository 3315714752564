import React from 'react';
import Settings from '../../../Settings/Settings'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
const useStyles = makeStyles((theme) => ({
  }));

export default function OrdersInvoiceAddressComponent(props) {
    const classes = useStyles();
    const [status, setStatus] = React.useState(props.ordersOrdersStatusesID)
    const handleChangeStatus = (event) => {
      setStatus(event.target.value);
    };
    const componentConstants = {
        ...props.componentConstants
      }
    
      const componentMethods = {
          ...props.componentMethods,
      }

    const updateOrdersStatusesAction = () => {
        let url = Settings.API_URL + Settings.API_ENDPOINT_ORDER + props.ordersID
        let method = "PUT"
        componentMethods.ajax(url, 
            {
              ordersOrdersStatusesID: status
            }, 
            method, 'ordersStatuses', 
            (data) => {
              componentMethods.addError(componentConstants.language.LANG_ORDER_UPDATED)
              componentMethods.getOrdersAction()
            })
        
    }
    
    if(!componentMethods.getManagerState().ordersStatuses)
      return null

  return (<React.Fragment>
        <FormControl className={classes.formControl} fullWidth={true}>
          <InputLabel>{componentConstants.language.LANG_ORDERS_STATUS}</InputLabel>
          <Select
          value={status}
          onChange={handleChangeStatus}
          >
          {componentMethods.getManagerState().ordersStatuses.map((el, index) => {
          return <MenuItem key={index} value={el.ordersStatusesID}>{componentConstants.language[el.ordersStatusesName]}</MenuItem>
          })}
          </Select>
      </FormControl>
      {props.ordersOrdersStatusesID !== status ?
          <Button className={classes.button} color="primary" onClick={updateOrdersStatusesAction} fullWidth component="div" variant="contained">
            {componentConstants.language.LANG_UPDATE_ORDERS_STATUS}
        </Button>
        :
        null
      }
      
      </React.Fragment>
  );
}