
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartButton from './ShoppingCartButton';
import YourAccountButton from './YourAccountButton'
import CreateAccountButton from './CreateAccountButton';
import UploadButton from './UploadButton'
import LogInButton from './LogInButton'
import Settings from '../../../Settings/Settings'
import { IconButton } from '@material-ui/core';
import {
  Link
} from "react-router-dom";
import Button from '@material-ui/core/Button';
import ImageIcon from '@material-ui/icons/Image';
import HomeIcon from '@material-ui/icons/Home';
import MediaQuery from '../../../../../CommonComponents/UseMediaQuery/MediaQueryComponent';
import VideocamIcon from '@material-ui/icons/Videocam';
import AddedToCartDialogComponent from '../../AddedToCartDialog/AddedToCartDialogComponent'
import FavoriteIcon from '@material-ui/icons/Favorite';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: '0 10px'
      },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: 'none',
      [theme.breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    inputRoot: {
      color: 'inherit',
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
      width: '100%',
    },
    sectionDesktop: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    yourImagesLink: {
      textDecoration: 'none',
      display: "block",
      margin: "0 5px"
    }
  }));
export default function TitleAppBar(props) {
    const classes = useStyles();

    const componentConstants = {
        ...props.componentConstants
    }

    const componentMethods = {
        ...props.componentMethods
    }

    const componentProps = {
        ...props.componentProps
    }

    return (
        <React.Fragment>
          <AddedToCartDialogComponent componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
          <div className={classes.sectionDesktop}>
          <MediaQuery 
                    lg={<a href="/" aria-label="Acasa Lexie Art" className={classes.yourImagesLink}>
                    <Button variant="outlined" color="secondary" component="span" startIcon={<HomeIcon />}>
                        {componentConstants.language.LANG_HOME}
                    </Button>
                  </a>} 
                    sm={<a href="/" aria-label="Acasa Lexie Art" className={classes.yourImagesLink}>
                    <IconButton>
                      <HomeIcon color="secondary"/>
                    </IconButton>
                  </a>} 
                    xs={<a href="/" aria-label="Acasa Lexie Art" className={classes.yourImagesLink}>
                      <IconButton>
                        <HomeIcon color="secondary"/>
                     </IconButton>
                  </a>} 
                    md={<a href="/" aria-label="Acasa Lexie Art" className={classes.yourImagesLink}>
                    <IconButton>
                      <HomeIcon color="secondary"/>
                    </IconButton>
                  </a>} 
                    xl={<a href="/" aria-label="Acasa Lexie Art" className={classes.yourImagesLink}>
                    <Button variant="outlined" color="secondary" component="span" startIcon={<HomeIcon />}>
                        {componentConstants.language.LANG_HOME}
                    </Button>
                  </a>}  />        
            {
              componentMethods.hasAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) ?
              <UploadButton componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
              :
              null
            }
            {
              componentMethods.getLocalStorage('user') ?
              null :
              <LogInButton componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            }
            {
            componentMethods.getLocalStorage('user') ?
            null :
              <CreateAccountButton componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            }
            {
            componentMethods.getLocalStorage('user') ?
            <YourAccountButton componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            : null
            }
            {
              componentMethods.hasAccess(Settings.SETTING_ACCESS_LEVEL_PHOTOGRAPHER) ?
              <MediaQuery 
              lg={<Link to="/my/pictures" aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <Button variant="outlined" color="secondary" component="span" startIcon={<ImageIcon />}>
                  {componentConstants.language.LANG_YOUR_PICTURES}
              </Button>
            </Link>} 
              sm={<Link to="/my/pictures"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <IconButton>
                <ImageIcon color="secondary"/>
              </IconButton>
            </Link>} 
              xs={<Link to="/my/pictures"aria-label="Imaginile tale" className={classes.yourImagesLink}>
                <IconButton>
                  <ImageIcon color="secondary"/>
               </IconButton>
            </Link>} 
              md={<Link to="/my/pictures"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <IconButton>
                <ImageIcon color="secondary"/>
              </IconButton>
            </Link>} 
              xl={<Link to="/my/pictures"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <Button variant="outlined" color="secondary" component="span" startIcon={<ImageIcon />}>
                  {componentConstants.language.LANG_YOUR_PICTURES}
              </Button>
            </Link>}  />
              :
              null
            }
            {
              componentMethods.hasAccess(Settings.SETTING_ACCESS_LEVEL_GODLIKE) ?
              <MediaQuery 
              lg={<Link to="/my/videos" aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <Button variant="outlined" color="secondary" component="span" startIcon={<VideocamIcon />}>
                  {componentConstants.language.LANG_YOUR_VIDEOS}
              </Button>
            </Link>} 
              sm={<Link to="/my/videos"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <IconButton>
                <VideocamIcon color="secondary"/>
              </IconButton>
            </Link>} 
              xs={<Link to="/my/videos"aria-label="Imaginile tale" className={classes.yourImagesLink}>
                <IconButton>
                  <VideocamIcon color="secondary"/>
               </IconButton>
            </Link>} 
              md={<Link to="/my/videos"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <IconButton>
                <VideocamIcon color="secondary"/>
              </IconButton>
            </Link>} 
              xl={<Link to="/my/videos"aria-label="Imaginile tale" className={classes.yourImagesLink}>
              <Button variant="outlined" color="secondary" component="span" startIcon={<VideocamIcon />}>
                  {componentConstants.language.LANG_YOUR_VIDEOS}
              </Button>
            </Link>}  />
              :
              null
            }
            <ShoppingCartButton componentConstants={componentConstants} componentMethods={componentMethods} componentProps={componentProps} />
            </div>
        </React.Fragment>
    );
  }