import React from 'react';
import CoreComponent from "../../../../Core/CoreComponent";
import AppbarComponent from '../AppbarComponent/AppbarComponent';
import Theme from '../../../../Themes/ThemeArtLexie';
import ErrorSnackbar from '../../../../CommonComponents/ErrorSnackbar/ErrorSnackbar';
import Settings from '../../Settings/Settings'
import ImageContainer from '../ImageComponent/ImageContainer'
import Grid from '@material-ui/core/Grid';
import LoadingComponent from '../LoadingComponent/LoadingComponent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { subscribe, unsubscribe } from '../../Events/Events';

export default class YourFavorites extends CoreComponent {

    constructor(props) {
        super(props);
        this.loadComponentsProps()
        this.useTheme(Theme)
        this.refDiv = React.createRef()
    }

    componentDidMount() {
        super.componentDidMount()
        this.getMyFavoriteImages()

        // subscribe("scroll", () => this.automaticallyLoadOlder())
    }

    getMyFavoriteOlderImages() {
        this.props.setManagerState({loadingFavoriteImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_MY_FAVORITE_IMAGES_OLDER + this.getLastMyFavoriteImagesID()

            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let myFavoriteImages = this.props.getManagerState().myFavoriteImages
                myFavoriteImages = myFavoriteImages.concat(data.items)
                this.props.setManagerState({myFavoriteImages: myFavoriteImages, loadingFavoriteImages: false}, () => {})
            })
        })
    }

    getMyFavoriteImages() {
        this.props.setManagerState({loadingFavoriteImages: true}, () => {
            let endpoint = Settings.API_ENDPOINT_MY_FAVORITE_IMAGES

            this.ajax(Settings.API_URL + endpoint,{},"GET", null, (data) => {
                let myFavoriteImages = this.props.getManagerState().myFavoriteImages
                myFavoriteImages = myFavoriteImages.concat(data.items)
                this.props.setManagerState({myFavoriteImages: myFavoriteImages, loadingFavoriteImages: false}, () => {})
            })
        })
    }

    getLastMyFavoriteImagesID() 
    {
        let imagesID = 0
        if (this.props.getManagerState().myFavoriteImages.length)
        {
            imagesID = this.props.getManagerState().myFavoriteImages[0].imagesID;
        }
        for (let i in this.props.getManagerState().myFavoriteImages)
        {
            if (parseInt(this.props.getManagerState().myFavoriteImages[i].imagesID) < parseInt(imagesID))
            imagesID = parseInt(this.props.getManagerState().myFavoriteImages[i].imagesID)
        }

        return imagesID;
    }

    automaticallyLoadOlder() 
    {
        if (!this.state.ifeelLucky && !this.props.getManagerState().loadingImages)
        {
            if (document.getElementById('product-' + this.getProductAtHalfList()) !== null)
            {
                if (document.getElementById('product-' + this.getProductAtHalfList()).scrollTop < window.innerHeight)
                {
                    this.getOlderImages()
                }
    
            }
        }
    }
    loadComponentsProps() {
        const that = this
        this.setComponentsConstants('AppbarComponent', {
            language: that.props.getManagerState().language,
            history: that.props.history
        }).setComponentsMethods('AppbarComponent', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            refreshMainComponent: () => {
                that.refresh()
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },

            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                }
        }).setComponentsConstants('ImageContainer', {
            language: that.props.getManagerState().language,
            urlParams: this.props.urlParams
        }).setComponentsMethods('ImageContainer', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            ajax: (url, 
                dataObject = {}, 
                method, stateValue = '', 
                callback = () => {}, 
                headers = {}, 
                setState = true, 
                catchCallback = () => {}) => {
                    that.ajax (url, 
                        dataObject, 
                        method, stateValue, 
                        callback, 
                        headers, 
                        setState, 
                        catchCallback);
                },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            },
            addError: (errorMessage, stateObject) => {
               that.addErrorMain(errorMessage, stateObject)
           },
           changeAlbumsSearchPage: (page) => {
            that.changeAlbumsSearchPage(page)
           },
           changePhotographersPage: (page) => {
            that.changePhotographersPage(page)
           }
        }).setComponentsMethods('Pagination', {
            changePage: (page) => {
                that.changePage(page)
            },
        }).setComponentsConstants('SearchAllImages', {
            language: that.props.getManagerState().language,
            history: that.props.history,
            urlParams: this.props.urlParams
        }).setComponentsMethods('SearchAllImages', {
            setManagerState: (object, callback) => {
                that.props.setManagerState(object, callback)
            },
            getSearchImages: (endpoint) => {
                this.getSearchImages(endpoint)
            },
            getManagerState: () => {
                return that.props.getManagerState()
            },
            changeRoute: (route) => {
                that.props.changeRoute(route)
            },
            getLocalStorage: (storageName) => {
                return that.getLocalStorage(storageName)
            }
        })
    }
    getRender() {

        let classes = {
            root: {
              display: 'flex',
              flexWrap: 'wrap',
              margin: '80px auto',
              '& > *': {
                margin: 1,
                width: 16,
                height: 16,
              }
            },
            paper: {
              margin: '10px auto',
              padding: 10,
              width: '100%',
            },
            formContainer: {
              margin: '0 auto'
            }
          };
        
        this.loadComponentsProps()    

        let paginationPattern = window.location.pathname.replace(/page\/[0-9]+[\/]{0,1}/i,'').split('/')
        paginationPattern[0] = '/page/{{page}}'+ paginationPattern[0]
        paginationPattern = paginationPattern.join('/')
        
        return <div ref={this.refDiv} style={classes.root} id="rootComponent">
                    <AppbarComponent {...this.buildComponentProps('AppbarComponent')} />
                        {this.props.getManagerState().loadingFavoriteImages ?
                            <Grid item xs={12} lg={12} md={12}>
                                <LoadingComponent />
                            </Grid>
                        :
                        null}
                        {this.props.getManagerState().myFavoriteImages.length > 0?
                            <React.Fragment>
                                <Grid item xs={12} lg={12} md={12} id="random-images-header">
                                <Typography component="h5" variant="h5" style={{textAlign: "center", marginTop: 16}}>{this.props.getManagerState().language.LANG_YOU_FAVORITE_IMAGES}</Typography>
                                </Grid>
                                {
                                    this.props.getManagerState().myFavoriteImages.map((el, idx) => {
                                        return <ImageContainer {...this.buildComponentProps('ImageContainer')} element={el} key={idx} />
                                    })
                                }
                                <Grid item xs={1} lg={4} md={1}></Grid>
                                <Grid item xs={10} lg={4} md={10}>
                                {
                                    this.props.getManagerState().loadingFavoriteImages ?
                                        <Grid item xs={12} lg={12} md={12}>
                                            <LoadingComponent />
                                        </Grid>
                                    :
                                    <Button className={classes.button} color="secondary" onClick={() => {this.getMyFavoriteOlderImages()}} fullWidth component="div" variant="contained" startIcon={<ExpandMoreIcon />}>
                                        {this.props.getManagerState().language.LANG_LOAD_MORE_FAVORITE_IMAGES}
                                    </Button>
                                }
                                </Grid>
                                <Grid item xs={1} lg={4} md={1}></Grid>
                            </React.Fragment>
                            :
                            null
                        }
                    {this.props.getManagerState().errors.map((element, index) => { 
                        return <ErrorSnackbar message={element} index={index} key={index} clearErrorMessage={this.props.clearErrorMessage}/>
                     })}
                </div>
    }
}